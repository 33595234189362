import axios from "axios";
import config from "../../config/config";

require("dotenv").config();

export const instance = axios.create({
  baseURL: config.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const instance2 = axios.create({
  baseURL: config.REACT_APP_API_BASE_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    "Content-Type": "application/json",
  },
});

instance2.interceptors.request.use(function (config) {
  let token = localStorage.getItem("token");
  config.headers["Authorization"] = "Bearer " + token;
  return config;
});

export const instance3 = axios.create({
  baseURL: config.REACT_APP_API_BASE_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    "Content-Type": "application/json",
  },
});

instance3.interceptors.request.use(function (config) {
  let token = localStorage.getItem("token");
  config.headers["Authorization"] = "Bearer " + token;
  return config;
});

export const EndPoints = {
  /** Auth */
  SignUp: "/auth/signup",
  Login: "/auth/login",
  googleLogin: "/auth/googlelogin",
  emailVerified: "/auth/verify/",
  resendVerifyEmail: "/auth/sendverifyemail",
  /** Admin */
  questionCategoryBysectionCategory:
    "/admin/QuestionCategory/questionCategoryBysectionCategory/",
  getAnswerByQuestionId: "admin/questionAnswer/getAnswerByQuestion/",
  getAllSections: "/admin/sectionCategories",
  /** Api */
  testDate: "/api/studentPrefenence/",

  /** Api quiz result */
  submitAnswer: "/api/quizResult",
  currentWeekMetrics: "/api/quizResult/currentWeekMetrics",
  testHistory: "/api/quizResult/categoryHistoryTable/",
  submitMultiquestionParagragh: "/api/quizResult/multipleQuizResult",
  getParagraphResult: "/api/quizResult/paragraphQuestionResult/",
  sevenWeeksMetricsOfCategory: "/api/quizResult/sevenWeeksMetricsOfCategory/",
  sevenWeeksCorrectedOfCategoryPerWeek:
    "/api/quizResult/sevenWeeksCorrectedOfCategory/",
  sevenWeeksMetricsOfCategoryPerWeek:
    "/api/quizResult/sevenWeeksMetricsOfCategoryPerWeek",

  lastWeekTasks: "/api/quizResult/scorePerCategoryOfUser/",
  /** Api quiz */

  storeQuiz: "/api/quiz",
  getQuizResult: "/api/quiz/",

  feedbackSubmit: "/api/feedBack",
  feedbackList: "api/feedBack/list",
  resetPassword: "/api/resetPassword",
  updatePassword: "api/user/changePassword",
  getPreviousExams: "/api/simuleraSeasion",
  getStudentPreference: "/api/studentPrefenence/byUser/",
  getSimuleraQuiz: "/api/simuleraQuiz/quiz/",
  // submitSimuleraTest using for both Exam/CU-EX
  submitSimuleraTest: "/api/simuleraQuizResult",
  getSimuleraQuizResult: "/api/simuleraQuizResult/",
  questionRating: "/api/questionRating",
  // updatePreviousExam using for both Exam/CU-EX
  updatePreviousExam: "/api/simuleraSeasonResult/seasonResult",
  simuleraQuizHistory: "/api/simuleraQuizResult/HistoryOfUser/",
  simuleraQuizHistorytest: "/api/simuleraQuizResult/HistoryOfUsertest/",
  createNewResultForSeason: "/api/simuleraSeasonResult",
  testSummaryByHistoryPage: "/api/simuleraQuizResult/resultSummary/",
  createOrder: "/api/payments/createOrder",
  getOrder: "/api/payments/order/",
  getUser: "/api/user/",
  updateUserInfo: "api/user/update",
  aiTutorChat: "/api/aitutor/chat",
  /** Custom Exams */
  customExams: "/api/customizedexam",
  customExamsSession: "/api/customizedexamsession/",
  customExamsSessionQuestions:
    "/api/customizedexamsession/getcustomizedExamQuestions/",
  /** Lecture */
  getLectureQuizes: "api/lecture/list",
  getLecture: "api/lecture/questions",
  updateLectureScore: "api/lectureResult",
  /* discount */
  validateCode: "api/discount/validateCode",
  /** Question viewer */
  getQuestionById: "api/questionViewer/",
};
