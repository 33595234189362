/** Libraries */
import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMixpanel } from "../../../hooks";
import Crown from "../../../assets/Icons/Crown.svg";
import Diamond from "../../../assets/Icons/Diamond.png";
/* Avatars */
import Avatar1 from "../../../assets/Icons/Avatar1.png";
import Avatar2 from "../../../assets/Icons/Avatar2.png";
import Avatar3 from "../../../assets/Icons/Avatar3.png";
import Avatar4 from "../../../assets/Icons/Avatar4.png";

/* Style */
import {
  AvatarImage,
  AvatarInnerRow,
  NoPremiumBoxText2,
  PremiumBoxSubtitle2,
  PremiumButton,
  PremiumButton2,
  PremiumCrown,
  PremiumDiamond,
  NoPremiumBoxText3,
  PremiumBoxSubtitle3,
  PremiumBoxTitle3,
} from "./IsPremiumCard.style";

/* Banners */
import DiscountPopUp from "./DiscountPopUp";
import IsPremiumSecondBanner from "../Banners/IsPremiumBanner/IsPremiumSecondBanner";
import IsPremiumBanner from "../Banners/IsPremiumBanner/IsPremiumBanner";

const IsPremiumCard = ({ isPremium }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openPopup = () => {
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  const navigate = useNavigate();
  const mixpanel = useMixpanel();

  const navigationCheck = () => {
    navigate("/checkout");
    mixpanel.bannerClicked("Banner-01");
  };
  const feedbackCheck = () => {
    navigate("/feedback");
  };

  const discountPopup = () => openPopup();

  const RepeatedPremiumButton = (buttonText, onClickAction) => {
    return (
      <PremiumButton
        data-testid="feedback-button-home"
        onClick={onClickAction}
        variant="contained"
        disableFocusRipple
        disableElevation
        disableRipple
      >
        {buttonText}
      </PremiumButton>
    );
  };

  const RepeatedPremiumButton2 = (buttonText, onClickAction) => {
    return (
      <PremiumButton2
        data-testid="feedback-button-home"
        onClick={() => onClickAction()}
        variant="contained"
        disableFocusRipple
        disableElevation
        disableRipple
      >
        {buttonText}
      </PremiumButton2>
    );
  };

  return (
    <>
      {isPremium ? (
        <IsPremiumBanner
          isPremium={isPremium}
          premiumBoxTitle="Studera effektivare!"
          premiumBoxSubtitle={
            <>
              Plugga med andra kan göra lärandet både{" "}
              <PremiumBoxSubtitle2>roligare</PremiumBoxSubtitle2> och{" "}
              <PremiumBoxSubtitle2>effektivare</PremiumBoxSubtitle2>.
            </>
          }
          noPremiumBoxText="Du provar HP-appen"
          button={
            isPremium
              ? RepeatedPremiumButton("Bjud in vänner", discountPopup)
              : RepeatedPremiumButton(
                  "Uppgradera till Premium",
                  navigationCheck
                )
          }
        >
          <AvatarInnerRow>
            <AvatarImage src={Avatar4} alt="Avatar" />
            <AvatarImage src={Avatar1} alt="Avatar" />
          </AvatarInnerRow>
          <AvatarInnerRow>
            <AvatarImage src={Avatar2} alt="Avatar" />
            <AvatarImage src={Avatar3} alt="Avatar" />
          </AvatarInnerRow>
        </IsPremiumBanner>
      ) : (
        <IsPremiumBanner
          isPremium={isPremium}
          premiumBoxTitle2="Har du feedback?"
          premiumBoxSubtitle2="Vi vill gärna veta vad du tycker!"
          image={<PremiumCrown src={Crown} alt="Crown" />}
          button={
            isPremium
              ? RepeatedPremiumButton2("Ge feedback", feedbackCheck)
              : RepeatedPremiumButton2("Skaffa premium", navigationCheck)
          }
        >
          <NoPremiumBoxText2>
            Premium ger <PremiumBoxSubtitle2>11,000+</PremiumBoxSubtitle2>{" "}
            övningar och <PremiumBoxSubtitle2>garanterar</PremiumBoxSubtitle2>{" "}
            förbättring inför provet
          </NoPremiumBoxText2>
        </IsPremiumBanner>
      )}

      <DiscountPopUp isOpen={isOpen} onClose={closePopup} />
    </>
  );
};

export const SecondaryIsPremiumCard = ({ isPremium }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useSelector((state) => state.user);
  const isPartialPlan = useMemo(() => {
    if (user?.areVerbalFeaturesEnabled && !user?.areKvantiativFeaturesEnabled) {
      return true;
    }
    if (!user?.areVerbalFeaturesEnabled && user?.areKvantiativFeaturesEnabled) {
      return true;
    }
    return false;
  }, [user?._id]);

  const openPopup = () => {
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  const navigate = useNavigate();
  const mixpanel = useMixpanel();

  const navigationCheck = () => {
    navigate("/checkout");
    mixpanel.bannerClicked("Banner-01");
  };
  const feedbackCheck = () => {
    navigate("/feedback");
  };

  const discountPopup = () => openPopup();

  const RepeatedPremiumButton = (buttonText, onClickAction) => {
    return (
      <PremiumButton
        data-testid="feedback-button-home"
        onClick={onClickAction}
        variant="contained"
        disableFocusRipple
        disableElevation
        disableRipple
      >
        {buttonText}
      </PremiumButton>
    );
  };

  const RepeatedPremiumButton2 = (buttonText, onClickAction) => {
    return (
      <PremiumButton2
        data-testid="feedback-button-home"
        onClick={() => onClickAction()}
        variant="contained"
        disableFocusRipple
        disableElevation
        disableRipple
      >
        {buttonText}
      </PremiumButton2>
    );
  };

  return (
    <>
      {isPremium ? (
        <IsPremiumSecondBanner
          button={
            isPremium
              ? RepeatedPremiumButton("Bjud in vänner", discountPopup)
              : RepeatedPremiumButton(
                  "Uppgradera till Premium",
                  navigationCheck
                )
          }
          isPremium={isPremium}
          premiumBoxTitle="Bjud in dina vänner!"
          premiumBoxSubtitle="Dela din rabattkod med vänner, för det är roligare att plugga tillsammans!"
          noPremiumBoxText="Du provar HP-appen"
          onClick={isPremium ? discountPopup : navigationCheck}
        >
          <AvatarInnerRow>
            <AvatarImage src={Avatar4} alt="Avatar" />
            <AvatarImage src={Avatar1} alt="Avatar" />
          </AvatarInnerRow>
          <AvatarInnerRow>
            <AvatarImage src={Avatar2} alt="Avatar" />
            <AvatarImage src={Avatar3} alt="Avatar" />
          </AvatarInnerRow>
        </IsPremiumSecondBanner>
      ) : (
        <IsPremiumSecondBanner
          button={
            isPremium
              ? RepeatedPremiumButton2("Ge feedback", feedbackCheck)
              : isPartialPlan
              ? RepeatedPremiumButton2("Läs mer", navigationCheck)
              : RepeatedPremiumButton2("Skaffa premium", navigationCheck)
          }
          isPremium={isPremium}
          premiumImage={<PremiumDiamond src={Diamond} alt="Diamond" />}
        >
          <div>
            {isPremium ? (
              <>
                <PremiumBoxTitle3>Har du feedback?</PremiumBoxTitle3>
                <PremiumBoxSubtitle3>
                  Vi vill gärna veta vad du tycker!
                </PremiumBoxSubtitle3>
              </>
            ) : isPartialPlan ? (
              <NoPremiumBoxText3>
                Lås upp hela appen för att kunna uttnyttja vår{" "}
                <span>resultatgaranti</span>
              </NoPremiumBoxText3>
            ) : (
              <NoPremiumBoxText3>
                Upptäck vår <span>resultatgaranti</span> - nå ditt mål eller få
                pengarna tillbaka.
              </NoPremiumBoxText3>
            )}
          </div>
        </IsPremiumSecondBanner>
      )}
      <DiscountPopUp isOpen={isOpen} onClose={closePopup} />
    </>
  );
};

export default IsPremiumCard;
