/* Libraries */
import { Grid } from "@material-ui/core";
import { Box } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";

/** Molecules */
import { LinearProgressBar } from "../../../../../molecule/Cards/LectureDashboardCard/LectureDashboardCard.styles";

/** Atoms */
import {
  DownArrowIcon,
  UpArrowIcon,
} from "../../../../../atom/CustomIcons/ArrowIcons";

/** Material UI - Custom elements */
export const MiddleContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.up(2400)]: {
    maxWidth: "1322px",
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    alignItems: "start",
  },
  [theme.breakpoints.down(2400)]: {
    width: "99%",
    maxWidth: "1000px",
  },
  [theme.breakpoints.up(1280)]: {
    paddingRight: theme.spacing(3),
  },
}));

export const WelcomeMessageBox = styled("div")(({ theme }) => ({
  minHeight: "175px",
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  borderRadius: "10px",
  margin: "1rem 0rem",
  padding: "1rem",
  [theme.breakpoints.down(2400)]: {
    maxWidth: "680px",
    margin: "1rem 0rem 0rem 0rem",
  },
  [theme.breakpoints.down(1280)]: {
    width: "55%",
    margin: "1rem 1rem 1rem 0rem",
  },
  [theme.breakpoints.down(800)]: {
    width: "100%",
    maxWidth: "680px",
  },
  [theme.breakpoints.down(600)]: {
    minHeight: "75px",
    margin: "0rem",
    padding: "0rem",
    paddingTop: "2rem",
  },
}));

export const TitleOfHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  fontSize: "28px",
  fontWeight: "500",
  color: theme.palette.appcolors.error6,
  [theme.breakpoints.down(1280)]: {
    fontSize: "23px",
  },
  [theme.breakpoints.down(800)]: {
    fontSize: "28px",
  },
  [theme.breakpoints.down(600)]: {
    fontSize: "20px",
  },
}));

export const SubtitleOfTheWelcomeMessage = styled("p")(({ theme }) => ({
  fontSize: "16px",
  marginBottom: "auto",
  lineHeight: "1.625rem",
  color: theme.palette.appcolors.black,
  [theme.breakpoints.down(1280)]: {
    fontSize: "12px",
    lineHeight: "1.25rem",
  },
  [theme.breakpoints.down(600)]: {
    display: "none",
  },
}));

export const PageTitle = styled("h3")(({ theme }) => ({
  fontSize: "28px",
  fontWeight: "600",
  marginTop: "2rem",
  lineHeight: "30px",
  color: theme.palette.appcolors.black,
  [theme.breakpoints.down(600)]: {
    fontSize: "20px",
    lineHeight: "26px",
  },
}));

export const ChoiceText = styled("p")(({ theme }) => ({
  fontFamily: "Figtree",
  paddingLeft: "0.5rem",
  fontSize: "1rem",
  lineHeight: "20px",
  marginBottom: "0.8rem",
  color: theme.palette.appcolors.black,
  [theme.breakpoints.down(600)]: {
    fontSize: "15px",
    letterSpacing: "-0.01em",
    marginBottom: "0.25rem",
  },
  [theme.breakpoints.down(400)]: {
    fontSize: "13px",
  },
}));

export const NumericalOptionsBox = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  rowGap: ".5rem",
  marginTop: "1rem",
  [theme.breakpoints.up(2400)]: {
    justifyContent: "flex-start",
  },
}));

export const MainFilterContainer = styled(Grid)(({ theme }) => ({
  width: "100%",
  marginTop: "1rem",
  paddingRight: "0rem",
  paddingLeft: "0rem",
  [theme.breakpoints.up(2400)]: {
    width: "55%",
  },
}));

export const FilterOptionsContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  width: "100%",
}));

export const FilterDropdownTitleBox = styled("div")(({ theme }) => ({
  borderRadius: "5px",
  border: `1px solid ${theme.palette.appcolors.primaryColor11}`,
  padding: "10px 20px",
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  height: "44px",
  cursor: "pointer",
}));

export const TabsDisplay = styled("div")(({ theme }) => ({
  marginTop: "1rem",
  [theme.breakpoints.down(600)]: {
    marginBottom: "1rem",
    borderRadius: "3px",
  },
  [theme.breakpoints.up(600)]: {
    display: "none",
  },
}));

export const DesktopScreenDisplay = styled("div")(({ theme }) => ({
  [theme.breakpoints.up(2400)]: {
    width: "1164px",
  },
  [theme.breakpoints.down(1280)]: {
    display: "none",
  },
  BodyTextClass: {
    [theme.breakpoints.up("xs")]: {
      fontSize: "12px !important",
      fontFamily: "'Figtree Sans Serif', sans-serif !important",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "20px !important",
      fontFamily: "'Figtree Sans Serif', sans-serif !important",
    },
  },
}));

export const LectureCardContainer = styled(Box)(
  ({ theme, expandlectures }) => ({
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    gap: "5px",
    marginTop: "10px",
    overflow: "hidden",
    paddingLeft: "1px",
    maxHeight: expandlectures ? "auto" : "300px",
    maxWidth: "1164px",
    [theme.breakpoints.down(600)]: {
      maxHeight: expandlectures ? "auto" : "210px",
    },
  })
);

export const BottomLectureExpander = styled(Box)(
  ({ theme, expandlectures, temporarylecturecardarray }) => ({
    position: expandlectures ? "inherit" : "absolute",
    bottom: 0,
    display: temporarylecturecardarray.length >= 7 ? "flex" : "none",
    justifyContent: "center",
    alignItems: "end",
    height: expandlectures ? "" : "50px",
    width: "100%",
    background: expandlectures
      ? ""
      : `linear-gradient(0deg, ${alpha(
          theme.palette.appcolors.secondaryColor,
          1.0
        )} 40%, ${alpha(theme.palette.appcolors.black, 0.0)} 100%)`,
    cursor: "pointer",
    [theme.breakpoints.down(600)]: {
      display: temporarylecturecardarray.length >= 2 ? "flex" : "none",
    },
    [theme.breakpoints.up(601)]: {
      display: temporarylecturecardarray.length >= 5 ? "flex" : "none",
    },
    [theme.breakpoints.up(1280)]: {
      display: temporarylecturecardarray.length >= 5 ? "flex" : "none",
    },
    [theme.breakpoints.up(1468)]: {
      display: temporarylecturecardarray.length >= 7 ? "flex" : "none",
    },
    [theme.breakpoints.up(2400)]: {
      display: temporarylecturecardarray.length >= 9 ? "flex" : "none",
    },
  })
);

export const LectureTitleContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  marginTop: "2rem",
  marginLeft: "12px",
}));

export const LectureCardLinkText = styled("p")(({ theme }) => ({
  fontSize: "14px",
  color: theme.palette.appcolors.black,
}));

export const DownArrow = styled(DownArrowIcon)(({ theme }) => ({
  fontSize: "20px",
}));

export const UpArrow = styled(UpArrowIcon)(({ theme }) => ({
  fontSize: "20px",
}));

export const ProgressBar = styled(LinearProgressBar)(({ theme }) => ({
  width: "100%",
  height: "20px !important",
  backgroundColor: theme.palette.appcolors.primaryColor10,
  "& .MuiLinearProgress-bar": {
    backgroundColor: theme.palette.appcolors.success4,
    borderRadius: "5rem",
  },
}));

export const FilterContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.appcolors.secondaryColor4,
  borderRadius: "10px",
  border: `2px solid ${theme.palette.appcolors.primaryColor6}`,
  borderBottom: `6px solid ${theme.palette.appcolors.primaryColor6}`,
  marginTop: "2rem",
  padding: "0rem 1rem 3rem 1rem",
}));

export const TimeCheckboxContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  marginTop: "0.5rem",
}));

export const AmountSelectionContainer = styled(Box)(() => ({
  display: "flex",
  marginTop: ".5rem",
  flexWrap: "wrap",
  rowGap: ".5rem",
}));

export const CheckboxContainer = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.appcolors.primaryColor11}`,
  borderRadius: "10px",
  padding: "20px",
  width: "100%",
  marginTop: "0.5rem",
  [theme.breakpoints.down(600)]: {
    paddingLeft: "0px",
  },
}));

export const EmojiBox = styled("img")(({ theme }) => ({
  height: "40px",
  marginLeft: "0.5rem",
  [theme.breakpoints.down(1280)]: {
    height: "30px",
  },
  [theme.breakpoints.down(800)]: {
    height: "40px",
  },
  [theme.breakpoints.down(600)]: {
    height: "30px",
  },
}));

export const TitleEmojiBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  marginTop: "auto",
  paddingBottom: "0.5rem",
  [theme.breakpoints.down(600)]: {
    margin: "auto",
  },
}));
