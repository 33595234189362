/** Libraries */
import { alpha, createTheme } from "@mui/material/styles";
import { appColors } from "../utils/commonService";

const lightGlobalTheme = createTheme({
  palette: {
    mode: "light",
    appcolors: {
      //Primary Colors
      primaryColor: "#1B51DA",
      primaryColor2: "#1B51DA",
      primaryColor3: "#1B51DA",
      primaryColor4: "#BBD9EE",
      primaryColor5: "#83A2EF",
      primaryColor6: "#BBCDFA",
      primaryColor7: "#E8EFFF",
      primaryColor8: "#E8EFFF",
      primaryColor9: "#E8EFFF",
      primaryColor10: "#EDF3F8",
      primaryColor11: "#54A4DE",
      primaryColor12: "#C9E7F6",
      primaryColor13: "#1B51DA",
      primaryColor14: "#DFDEFC",
      primaryColor15: "#54A4DE",
      primaryColor16: "#83A2EF",
      primaryColor17: "#EDF3F8",
      primaryColor18: "#E8EFFF",
      primaryColor19: "#D7D6FF",
      primaryColor20: "#517BE7",
      primaryColor21: "#1B51DA",
      primaryColor22: "#517BE7",
      primaryColor23: "#C9E7F6",
      primaryColor24: "#DFDEFC",
      primaryColor25: "#BBD9EE",
      //Secondary Colors
      secondaryColor: "#FFFFFF",
      secondaryColor2: "#FAFAFA",
      secondaryColor3: "#FCFCFC",
      secondaryColor4: "#FFFFFF",
      secondaryColor5: "#FFFFFF",
      secondaryColor6: alpha("#FAFAFA", 0.42),
      secondaryColor7: "#FFFFF2",
      secondaryColor8: "#FDF8EE",
      secondaryColor9: alpha("#FFFFFF", 0.8),
      secondaryColor10: "#FFFFFF",
      secondaryColor11: "#FAFAFA",
      secondaryColor12: "#FAFAFA",
      secondaryColor13: "#FCFCFC",
      secondaryColor14: "#F5F5F5",
      secondaryColor15: "#FFFFF2",
      secondaryColor16: "#FEFEFE",
      //Tertiary Colors
      tertiaryColor: "#FFEFD9",
      tertiaryColor2: "#FFEFD9",
      tertiaryColor3: "#FFE5BF",
      tertiaryColor4: "#FFEFD9",
      tertiaryColor5: "#FFEFD9",
      tertiaryColor6: "#FFCA80",
      tertiaryColor7: "#FFCA80",
      tertiaryColor8: "#FFBA59",
      tertiaryColor9: "#FF8B00",
      tertiaryColor10: "#FFE5BF",
      tertiaryColor11: "#FF8B00",
      tertiaryColor12: "#FFE5BF",
      tertiaryColor13: "#FFAB35",
      tertiaryColor14: "#FFBA59",
      tertiaryColor15: "#FFA840",
      tertiaryColor16: "#FFCA80",
      tertiaryColor17: "#FF8B00",
      tertiaryColor18: "#FFCA80",
      tertiaryColor19: "#FFEFD9",
      //Error Colors
      error: "#FFEAE8",
      error2: "#FFEAE8",
      error3: "#FFEAE8",
      error4: "#FFEAE8",
      error5: "#EB5757",
      error6: "#EB5757",
      error7: "#CC5A51",
      error8: "#FF0000",
      //Success Colors
      success: "#1BCF66",
      success2: "#BCEACF",
      success3: "#BCEACF",
      success4: "#6FCF97",
      success5: "#1BCF66",
      //Warning Colors
      alertMessage: "#FFEFD9",
      //Grayscale Colors
      black: "#252525",
      black2: "#252525",
      black3: alpha("#252525", 0.3),
      black4: "#252525",
      grey: "#525252",
      grey2: "#F5F5F5",
      grey3: "#E1E1E1",
      grey4: "#E1E1E1",
      grey5: "#E1E1E1",
      grey6: "#A6A6A6",
      grey7: "#A6A6A6",
      grey8: "#C2C2C2",
      grey9: "#C2C2C2",
      grey10: "#E1E1E1",
      grey11: "#525252",
      grey12: alpha("#D9D9D9", 0.0),
      grey13: "#8B8B8B",
      grey14: "#6E6E6E",
      grey15: "#F5F5F5",
      //Other Colors
      discord: "#1B51DA",
      popupOverlay: alpha("#D9D9D9", 0.5),
      inviteFriendHeaderText: "#252525",
      purple: "#BBB8F9",
      pink: "#FEDAF6",
    },
    primary: {
      main: appColors.blueColor,
      dark: appColors.blueColor,
      contrastText: appColors.whiteColor,
    },
    secondary: {
      main: appColors.valentine_red,
      contrastText: appColors.blackColor,
    },
    valentine_1: {
      main: appColors.valentine_red,
      dark: appColors.grey10,
      contrastText: appColors.grey25,
    },
  },
  typography: {
    Typography: {
      Poppins_1: {
        color: `${appColors.whiteColor} !important`,
        textAlign: "center !important",
        fontFamily: "Poppins !important",
        fontSize: "14px !important",
        fontStyle: "normal !important",
        fontWeight: "600 !important",
        lineHeight: "normal !important",
        textTransform: "none !important",
      },
    },
  },
});

const lightTheme = createTheme(
  {
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundColor:
              lightGlobalTheme.palette["appcolors"]["secondaryColor3"],
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiButton: {
        styleOverrides: {
          containedPrimary: {
            backgroundColor: lightGlobalTheme.palette["primary"]["main"],
            width: "100%",
            height: "40px",
            borderRadius: "8px",
            "&:hover": {
              boxShadow: `0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px rgba(0, 0, 0, 0.2)`,
            },
            "&:disabled": {
              color: `${alpha(appColors.whiteColor, 0.42)}`,
            },
            ...lightGlobalTheme.typography.Typography["Poppins_1"],
          },
        },
      },
    },
  },
  lightGlobalTheme
);

export default lightTheme;
