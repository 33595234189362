/** Libraries */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

/** Slices */
import {
  setRememberMe,
  setUiDarkmode,
  setLectureStartupDialog,
  setExerciseStartupDialog,
  setExamStartupDialog,
} from "../../redux/slices";

export const usePersistedStore = () => {
  // eslint-disable-next-line no-unused-vars
  const dispatch = useDispatch();
  const {
    rememberMe,
    darkmode,
    lectureStartupDialog,
    exerciseStartupDialog,
    examStartupDialog,
  } = useSelector((state) => state.persisted);

  useEffect(() => {
    if (lectureStartupDialog === undefined) {
      dispatch(setLectureStartupDialog(true));
    }
    if (exerciseStartupDialog === undefined) {
      dispatch(setExerciseStartupDialog(true));
    }
    if (examStartupDialog === undefined) {
      dispatch(setExamStartupDialog(true));
    }
  }, []);

  /** This function updates the goal points to be achived by the student */
  const startHandleRememberMe = (value) => {
    dispatch(setRememberMe(value));
  };

  const startUiDarkmode = () => {
    dispatch(setUiDarkmode());
  };

  const startLectureStartupDialog = () => {
    dispatch(setLectureStartupDialog(false));
  };

  const startExerciseStartupDialog = () => {
    dispatch(setExerciseStartupDialog(false));
  };

  const startExamStartupDialog = () => {
    dispatch(setExamStartupDialog(false));
  };

  return {
    /** Values */
    rememberMe,
    darkmode,
    lectureStartupDialog,
    exerciseStartupDialog,
    examStartupDialog,

    /** Functions */
    startHandleRememberMe,
    startUiDarkmode,
    startLectureStartupDialog,
    startExerciseStartupDialog,
    startExamStartupDialog,
  };
};
