/** Libraries */
import React from "react";
import { Grid, List, ListItem } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";

/** Assets */
import LecturePayIcon1 from "../../../assets/Icons/LecturePayIcon1.svg";
import LecturePayIcon2 from "../../../assets/Icons/LecturePayIcon2.svg";
import LecturePayIcon3 from "../../../assets/Icons/LecturePayIcon3.svg";
import LecturePayIcon4 from "../../../assets/Icons/LecturePayIcon4.svg";
import LecturePayIcon5 from "../../../assets/Icons/LecturePayIcon5.svg";

/** Material UI - Custom elements */
const CustomGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  gap: "0px",
}));

const ListContentContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down(1280)]: {
    marginTop: "-10px",
  },
}));

const ListText = styled("span")(({ theme }) => ({
  fontFamily: "Figtree",
  cursor: "default",
  fontSize: "15px",
  color: theme.palette.appcolors.black,
  [theme.breakpoints.down(1280)]: {
    fontSize: "12px",
  },
}));

const ListImgIcon = styled(CheckCircleOutlineRoundedIcon)(({ theme }) => ({
  fontSize: "1.75rem",
  color: theme.palette.appcolors.primaryColor,
  marginRight: "0.5rem",
}));

const LecturePayIcon = styled("img")(({ theme }) => ({
  marginRight: "0.5rem",
  [theme.breakpoints.down(1280)]: {
    width: "14px",
  },
}));

const ListValues = (props) => {
  return (
    <Grid>
      <List>
        <CustomGrid>
          <ListItem disableGutters>
            <ListContentContainer>
              {props.lecture ? (
                <LecturePayIcon src={LecturePayIcon1} />
              ) : (
                <ListImgIcon />
              )}
              <ListText>12,000+ uppgifter med smarta lösningar</ListText>
            </ListContentContainer>
          </ListItem>
        </CustomGrid>
        <CustomGrid>
          <ListItem disableGutters>
            <ListContentContainer>
              {props.lecture ? (
                <LecturePayIcon src={LecturePayIcon2} />
              ) : (
                <ListImgIcon />
              )}
              <ListText>
                AI studiecoach: hjälp med uppgifter dygnet runt
              </ListText>
            </ListContentContainer>
          </ListItem>
        </CustomGrid>
        <CustomGrid>
          <ListItem disableGutters>
            <ListContentContainer>
              {props.lecture ? (
                <LecturePayIcon src={LecturePayIcon4} />
              ) : (
                <ListImgIcon />
              )}
              <ListText>Normerad poäng på alla provdelar</ListText>
            </ListContentContainer>
          </ListItem>
        </CustomGrid>
        {props.lecture && (
          <CustomGrid>
            <ListItem disableGutters>
              <ListContentContainer>
                <LecturePayIcon src={LecturePayIcon5} />
                <ListText>Teori & strategier för högskoleprovet</ListText>
              </ListContentContainer>
            </ListItem>
          </CustomGrid>
        )}
        <CustomGrid>
          <ListItem disableGutters>
            <ListContentContainer>
              {props.lecture ? (
                <LecturePayIcon src={LecturePayIcon3} />
              ) : (
                <ListImgIcon />
              )}
              <ListText>
                Resultatgaranti: nå ditt mål eller få pengarna tillbaka
              </ListText>
            </ListContentContainer>
          </ListItem>
        </CustomGrid>
      </List>
    </Grid>
  );
};

export default ListValues;
