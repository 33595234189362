/** Libraries */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Collapse from "@mui/material/Collapse";
import { useTheme } from "@emotion/react";
import { Button } from "@mui/material";

/** Atoms */
import {
  DownArrowIcon,
  UpArrowIcon,
} from "../../../../atom/CustomIcons/ArrowIcons";
import {
  StyledCorrectIcon,
  StyledWrongIcon,
} from "../../../../atom/RadioIcons/RadioIcons";

/** Molecules */
import PressEnterContinue from "../../../../molecule/Alerts/PressEnterContinue/PressEnterContinue";
import ResultFooter from "../../../../molecule/ResultFooter/ResultFooter";

/** Organisms */
import Options from "../../Options/Options";
import { Answer } from "../../Answer/Answer";

/** Tools */
import { formatText } from "../../../../../tools";

/** Styles */
import {
  AnswerAndMultiQuestionsContainer,
  StyledAnswersReviewContainer,
  AnswerCard,
  AnswerCardText,
  AnswerAndImageContainer,
  MQQuestionReviewContainer,
  TextAndButtonImageContainer,
  ResultImage,
  TextContainer,
  UppgiftText,
  ButtonImageContainer,
} from "./../../../../organism/HomeOrg/HomePages/QuestionPages/QuestionViewDtkOrg/SharedDtkOrg.styles";
import {
  NavigationButtonsContainer,
  StyledExamButton,
  NavigationButtonText,
} from "./MQQuestionReview.styles";

const MQQuestionReview = ({
  isLoading,
  isMQSection,
  isResultPage,
  isNastaButtonDisabled,
  isNastaExamButtonDisabled,
  isForaendeButtonDisabled,
  sectionTitle,
  questions,
  totalQuestions,
  selectedIndex,
  handleShowAnswer,
  handleOnClickNasta,
  handleResultButton,
  handleLeftButton,
  handleRightButton,
  ...props
}) => {
  const theme = useTheme();

  const handleAnswerBorder = (isCorrect) => {
    if (isCorrect) {
      return `3px solid ${theme.palette.appcolors.success4}`;
    } else {
      return `3px solid ${theme.palette.appcolors.error6}`;
    }
  };

  useEffect(() => {
    const handleEnterClick = (e) => {
      e.preventDefault();
      if (e.keyCode === 13) {
        if (!isResultPage) {
          handleOnClickNasta();
        } else {
          if (selectedIndex < totalQuestions - 1) {
            handleRightButton();
          }
        }
      }
    };
    document.addEventListener("keydown", handleEnterClick);

    return () => {
      document.removeEventListener("keydown", handleEnterClick);
    };
  }, [isResultPage]);
  return (
    <>
      {isLoading ? (
        <MQQuestionReviewContainer>
          <CircularProgress />
        </MQQuestionReviewContainer>
      ) : (
        <MQQuestionReviewContainer>
          <StyledAnswersReviewContainer>
            {questions?.map((item, index) => {
              const isCorrect = item?.optionId === item?.answerObj?.option;
              return (
                <AnswerAndMultiQuestionsContainer key={item._id}>
                  <AnswerCard
                    sx={{
                      border: handleAnswerBorder(isCorrect),
                    }}
                    onClick={() => handleShowAnswer(index)}
                  >
                    <TextAndButtonImageContainer>
                      <AnswerAndImageContainer>
                        {isCorrect ? (
                          <ResultImage>
                            <StyledCorrectIcon />
                          </ResultImage>
                        ) : (
                          <ResultImage>
                            <StyledWrongIcon />
                          </ResultImage>
                        )}

                        <TextContainer>
                          <UppgiftText>
                            {"Uppgift " +
                              `${index + 1}` +
                              " av " +
                              questions?.length}
                          </UppgiftText>
                          <AnswerCardText
                            content={formatText(item.questionStatement)}
                          />
                        </TextContainer>
                      </AnswerAndImageContainer>
                      <ButtonImageContainer>
                        {item?.showResult ? <UpArrowIcon /> : <DownArrowIcon />}
                      </ButtonImageContainer>
                    </TextAndButtonImageContainer>
                  </AnswerCard>

                  <Collapse
                    sx={{ width: "100%" }}
                    orientation="vertical"
                    in={item?.showResult}
                  >
                    <Options
                      options={item.options[0].options}
                      answerExist={true}
                      selectedOption={item.optionId}
                      answerId={item?.answerObj?.option}
                      handleSelectOption={() => {}}
                      areBorderColorsVisible={false}
                    />
                    <Answer
                      isMQ={true}
                      isFeedbackDisabled={true}
                      content={item?.answerObj?.answer}
                      questionId={item.questionId}
                      sectionCategory={sectionTitle}
                      questionCategory={sectionTitle}
                    />
                  </Collapse>
                </AnswerAndMultiQuestionsContainer>
              );
            })}
          </StyledAnswersReviewContainer>

          {!isResultPage ? (
            <>
              {isMQSection ? (
                <NavigationButtonsContainer>
                  <StyledExamButton disabled={isForaendeButtonDisabled}>
                    <NavigationButtonText>Föregående</NavigationButtonText>
                  </StyledExamButton>
                  <StyledExamButton disabled={isNastaExamButtonDisabled}>
                    <NavigationButtonText>Nästa</NavigationButtonText>
                  </StyledExamButton>
                </NavigationButtonsContainer>
              ) : (
                <PressEnterContinue isHidden={isNastaButtonDisabled}>
                  <Button
                    disabled={isNastaButtonDisabled}
                    variant="contained"
                    onClick={handleOnClickNasta}
                  >
                    Nästa
                  </Button>
                </PressEnterContinue>
              )}
            </>
          ) : (
            <ResultFooter
              questionLength={totalQuestions}
              questionIndex={selectedIndex}
              onResultHandler={handleResultButton}
              onLeftClick={handleLeftButton}
              onRightClick={handleRightButton}
            />
          )}
        </MQQuestionReviewContainer>
      )}
    </>
  );
};

const optionShape = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  image: PropTypes.string,
  type: PropTypes.string,
});

const questionShape = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  questionStatement: PropTypes.string.isRequired,
  questionId: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      options: PropTypes.arrayOf(optionShape).isRequired,
    })
  ).isRequired,
});

MQQuestionReview.propTypes = {
  isLoading: PropTypes.bool,
  isMQSection: PropTypes.bool,
  isResultPage: PropTypes.bool,
  isNastaButtonDisabled: PropTypes.bool,
  isNastaExamButtonDisabled: PropTypes.bool,
  isForaendeButtonDisabled: PropTypes.bool,
  sectionTitle: PropTypes.string.isRequired,
  totalQuestions: PropTypes.number.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  questions: PropTypes.arrayOf(questionShape).isRequired,
  handleShowAnswer: PropTypes.func.isRequired,
  handleOnClickNasta: PropTypes.func.isRequired,
  handleResultButton: PropTypes.func.isRequired,
  handleLeftButton: PropTypes.func.isRequired,
  handleRightButton: PropTypes.func.isRequired,
};

MQQuestionReview.defaultProps = {
  isLoading: false,
  isMQSection: false,
  isResultPage: false,
  isNastaButtonDisabled: false,
  isNastaExamButtonDisabled: false,
  isForaendeButtonDisabled: false,
};

export default MQQuestionReview;
