export const config = {
  REACT_APP_SERVER_NAME: "DEV",
  REACT_APP_API_BASE_URL: "https://apidev.hpappen.se",
  // REACT_APP_API_BASE_URL: "http://localhost:2000",
  REACT_APP_BASE_URL: "https://dev.hpappen.se",
  REACT_APP_SENTRY_DSN:
    "https://d3f1d587e912423b91fda0d4d4742861@o4504945522180096.ingest.sentry.io/4504945526964224",
  REACT_APP_GOOGLE_CLIENT_ID:
    "762929285322-facjtf4j1v5g801f59hrk2corsg6t99h.apps.googleusercontent.com",
};
