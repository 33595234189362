/** Libraries */
import React, { useState, useEffect, useMemo } from "react";
import { Grid } from "@mui/material";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";

/** Assets */
import useWindowDimensions from "../../molecule/WindowDimensions/dimension";
import Prices from "../../../assets/Static/Prices";

/** Organisms */
import PayButton from "./PayButton";
import {
  FullListValues,
  GratisListValues,
  PartialListValues,
} from "./AlternativeListValues";

/** Styles */
import {
  PlanBox,
  PopularityTab,
  HorizontalLine,
  DiscountLabel,
  PayButtonContainer,
  PriceContainer,
  PriceText,
  MonthsTitle,
  PriceSubtitle,
  PaySectionContainer,
  ListContainer,
  RadioContainer,
  FullPriceLabel,
} from "../PaymentOrg/PricingPlans.styles";

/*  Hooks */
import { useDiscountStore, useMixpanel } from "../../../hooks";
import { useRef } from "react";

const PricingPlan = ({
  defaultPlan,
  initiatePayment,
  price,
  setHtmlSnippet,
  checkoutRef,
  goPayment,
  disablePaymentButton,
}) => {
  const mixpanel = useMixpanel();
  const plans = Object.values(Prices);
  const { isCodeValid, discount: discountObject } = useDiscountStore();
  const { discountAmount: discount } = discountObject;
  const [selectedPlan, setSelectedPlan] = useState(defaultPlan);
  const [sectionToBuy, setSectionToBuy] = React.useState("kvantitativ");
  const theme = useTheme();
  const { width } = useWindowDimensions();
  const paymentButtonRef = useRef(null);

  const { user } = useSelector((state) => state.user);
  const isPremium = user.isPremium;

  const isPartialPlan = useMemo(() => {
    if (user?.areVerbalFeaturesEnabled && !user?.areKvantiativFeaturesEnabled) {
      return true;
    }
    if (!user?.areVerbalFeaturesEnabled && user?.areKvantiativFeaturesEnabled) {
      return true;
    }
    return false;
  }, [user?._id]);

  useEffect(() => {
    if (isPartialPlan) {
      if (user?.areVerbalFeaturesEnabled) {
        setSectionToBuy("verbal");
      } else {
        setSectionToBuy("kvantitativ");
      }
    }
  }, [isPartialPlan]);

  useEffect(() => {
    if (initiatePayment && selectedPlan === "planThree") {
      paymentButtonRef?.current?.click();
    }
  }, [sectionToBuy, selectedPlan, initiatePayment]);

  const displayedPlans = isPremium ? plans.slice(1) : plans;

  const discountPrices = useMemo(() => {
    // Deep copy Prices and apply discount
    const copiedPrices = JSON.parse(JSON.stringify(Prices));

    Object.keys(copiedPrices).forEach((planKey) => {
      const plan = copiedPrices[planKey];
      if (discount) {
        plan.price -= discount;
        plan.pricePerMonth =
          plan.price % plan.premiumLength < 0.5
            ? Math.floor(plan.price / plan.premiumLength)
            : (plan.pricePerMonth = Math.ceil(plan.price / plan.premiumLength));
      }
    });

    return Object.values(copiedPrices);
  }, [discount]);

  const handleSelectPlan = (planId) => {
    setSelectedPlan(planId);
    mixpanel.premiumPlanClicked(planId);
  };

  const handleChange = (event) => {
    !isPremium && setSectionToBuy(event.target.value);
  };

  return (
    <Grid
      container
      rowSpacing={4}
      columnSpacing={2}
      justifyContent="center"
      alignItems="center"
    >
      {displayedPlans.map((plan, index) => (
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={4}
          key={index}
          sx={{
            display: plan?.id === "payingFullFromPartial" ? "none" : "block",
          }}
        >
          <PlanBox
            key={index}
            planid={plan.id}
            width={width}
            // onClick={() => handleSelectPlan(plan?.id)}
          >
            {plan?.popular && (
              <PopularityTab planid={plan?.id}>Rekommenderas</PopularityTab>
            )}
            <MonthsTitle planid={plan?.id}>{plan?.name}</MonthsTitle>
            <HorizontalLine planid={plan?.id} />
            {plan.discount &&
              (isPremium ? (
                <FullPriceLabel>{plan?.price} SEK</FullPriceLabel>
              ) : (
                <DiscountLabel>Spara {plan?.discount}</DiscountLabel>
              ))}
            <PriceContainer>
              {isPremium && plan?.id === "planThree" ? (
                <PriceText premium>{plan?.price} SEK</PriceText>
              ) : (
                <PriceText>{isPartialPlan ? 420 : plan?.price} SEK</PriceText>
              )}
            </PriceContainer>
            <PaySectionContainer>
              {plan?.id === "planOne" ||
              (isPremium && plan?.id === "planThree") ? (
                <MonthsTitle gratistitle>Nuvarande Plan</MonthsTitle>
              ) : (
                <PayButtonContainer>
                  <PayButton
                    setSelectedPlan={setSelectedPlan}
                    sectionToBuy={sectionToBuy}
                    price={price}
                    setHtmlSnippet={setHtmlSnippet}
                    checkoutRef={checkoutRef}
                    paymentButtonRef={
                      plan?.id === "planThree" ? paymentButtonRef : null
                    }
                    goPayment={(planDetails, sectionToBuy) =>
                      goPayment(planDetails, sectionToBuy)
                    }
                    planDetails={() => {
                      const planWithoutDiscount = isPartialPlan
                        ? Prices["payingFullFromPartial"]
                        : Prices[plan?.id];
                      if (discount) {
                        return discountPrices.find(
                          (discountPlan) => discountPlan.id === plan?.id
                        );
                      } else {
                        return planWithoutDiscount;
                      }
                    }}
                    disablePaymentButton={disablePaymentButton}
                  />
                </PayButtonContainer>
              )}
              {plan?.id === "planThree" && (
                <>
                  <RadioContainer>
                    <FormControl fullWidth>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={sectionToBuy}
                        onChange={handleChange}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          flexWrap: "nowrap",
                          marginLeft: "1rem",
                          gap: "2rem",
                          "& .MuiButtonBase-root": {
                            paddingRight: "6px",
                            color: theme.palette.appcolors.primaryColor,
                          },
                          "& .MuiRadio-root.Mui-checked": {
                            color: theme.palette.appcolors.primaryColor,
                          },
                        }}
                      >
                        <FormControlLabel
                          value="kvantitativ"
                          control={<Radio size="small" />}
                          label="Kvantitativ"
                          disabled={isPartialPlan || disablePaymentButton}
                        />
                        <FormControlLabel
                          value="verbal"
                          disabled={isPartialPlan || disablePaymentButton}
                          control={<Radio size="small" />}
                          label="Verbal"
                        />
                      </RadioGroup>
                    </FormControl>
                  </RadioContainer>
                  <PriceSubtitle toptext>Välj del</PriceSubtitle>
                  {!isPremium && (
                    <PriceSubtitle bottomtext>
                      Du kan uppgradera till hela appen senare
                    </PriceSubtitle>
                  )}
                </>
              )}
            </PaySectionContainer>
            <ListContainer>
              {plan?.id === "planTwo" ? (
                <FullListValues />
              ) : plan?.id === "planOne" ? (
                <GratisListValues />
              ) : (
                <PartialListValues />
              )}
            </ListContainer>
          </PlanBox>
        </Grid>
      ))}
    </Grid>
  );
};

export default PricingPlan;
