import { makeStyles } from "@material-ui/core";

/* Utils */
import { appColors } from "../../../utils/commonService";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  innerForm: {
    gap: "3em",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "30px 0 20px",
    [theme.breakpoints.down(800)]: {
      gap: "1em",
    },
  },
  inputContainer: {
    position: "relative",
  },

  icon: {
    position: "absolute",
    top: "50%",
    left: theme.spacing(2),
    transform: "translateY(-50%)",
    [theme.breakpoints.down(800)]: {
      left: "12px",
      "&.important": {
        left: "12px !important",
      },
    },
  },

  input: {
    padding: "12px 40px",
    borderRadius: "8px",
    height: "40px",
    border: `1px solid ${appColors.grey25}`,
    "&:focus": {
      outline: "none",
    },
    [theme.breakpoints.down(800)]: {
      width: "180px",
    },
  },

  button: {
    fontFamily: "Poppins !important",
    height: "40px",
    border: `1px solid ${appColors.blackColor}`,
    color: `${appColors.blackColor}`,
    backgroundColor: `${appColors.whiteColor}`,
    fontWeight: "600",
    textTransform: "none",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: `${appColors.blueColor} !important`,
      color: `${appColors.whiteColor}`,
    },
    "&:disabled": {
      border: "none",
    },
  },
  info: {
    marginTop: "-12px",
    textAlign: "left",
    fontFamily: "Figtree",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
  },
  errorText: {
    color: `${appColors.valentine_red}`,
  },
  successText: {
    color: `${appColors.darkMintGreen}`,
  },
  errorBorder: {
    borderColor: `${appColors.valentine_red}`,
  },
  successBorder: {
    borderColor: `${appColors.darkMintGreen}`,
  },
}));
