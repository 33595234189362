import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import RadioButton from "../../../atom/RadioButton/RadioButton";
import { useTheme, keyframes } from "@mui/material/styles";
import {
  RowOptionItem,
  RowTextContainer,
  RowOptionText,
} from "./OptionRowCard.styles";

/** Assets */
const audioRef = new Audio(
  "https://hp-appen.s3.eu-north-1.amazonaws.com/sounds/Exercise_correct_answer.wav"
);

const shakeAnimation = keyframes`
  0% { transform: translateX(0); }
  20% { transform: translateX(-5px); }
  40% { transform: translateX(5px); }
  60% { transform: translateX(-5px); }
  80% { transform: translateX(5px); }
  100% { transform: translateX(0); }
`;

const OptionRowCard = ({
  areBorderColorsVisible,
  optionId,
  content,
  optionIndex,
  isCorrect,
  isIncorrect,
  isSelected,
  answerExist,
  handleSelectOption,
  hiddeCorrectChoice,
}) => {
  const [isOnHover, setIsOnHover] = useState(null);
  const [isSoundEnabled, setIsSoundEnabled] = useState(false);
  const theme = useTheme();

  const changeOptionsBorderColor = () => {
    if (answerExist && areBorderColorsVisible) {
      if (isCorrect && !hiddeCorrectChoice) {
        return `3px solid ${theme.palette.appcolors.success4}`;
      } else if (isIncorrect) {
        return `3px solid ${theme.palette.appcolors.error6}`;
      } else {
        return `1px solid ${theme.palette.appcolors.grey3}`;
      }
    }

    return `1px solid ${theme.palette.appcolors.grey3}`;
  };

  const changeOptionsColor = () => {
    if (answerExist) {
      if (isCorrect && !hiddeCorrectChoice) {
        return theme.palette.appcolors.success4;
      } else if (isIncorrect) {
        return theme.palette.appcolors.error6;
      }
    }

    return theme.palette.appcolors.grey;
  };

  const handleOnclick = (optionId, optionIndex) => {
    if (!answerExist) {
      handleSelectOption({ optionId, optionIndex });
      setIsSoundEnabled(true);
    }
  };

  useEffect(() => {
    const playCorrectOptionAudio = () => {
      if (audioRef) {
        audioRef.play();
      }
    };

    if (isCorrect && isSoundEnabled) {
      playCorrectOptionAudio();
    }

    return () => {
      if (audioRef) {
        audioRef.pause();
        audioRef.currentTime = 0;
      }
    };
  }, [isCorrect, isSoundEnabled]);

  return (
    <RowOptionItem
      sx={{
        border: changeOptionsBorderColor,
        color: changeOptionsColor,
        animation: isIncorrect && `${shakeAnimation} 0.5s ease-in-out 1`,
        "&:hover": {
          cursor: !answerExist && "pointer",
          color: !answerExist && theme.palette.appcolors.primaryColor,
          border:
            !answerExist && `1px solid ${theme.palette.appcolors.primaryColor}`,
        },
      }}
      onMouseOver={() => setIsOnHover(optionId)}
      onMouseLeave={() => setIsOnHover(null)}
      onClick={() => handleOnclick(optionId, optionIndex)}
    >
      <RadioButton
        isCorrect={!hiddeCorrectChoice && isCorrect}
        isIncorrect={isIncorrect}
        isSelected={isSelected}
        isHover={optionId === isOnHover}
        indexLetter={optionIndex}
        disabled={answerExist}
      />

      <RowTextContainer>
        <RowOptionText content={content} />
      </RowTextContainer>
    </RowOptionItem>
  );
};

OptionRowCard.propTypes = {
  optionId: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  optionIndex: PropTypes.number.isRequired,
  isCorrect: PropTypes.bool,
  isIncorrect: PropTypes.bool,
  isSelected: PropTypes.bool,
  answerExist: PropTypes.bool,
  handleSelectOption: PropTypes.func.isRequired,
  hiddeCorrectChoice: PropTypes.bool,
  areBorderColorsVisible: PropTypes.bool,
};

OptionRowCard.defaultProps = {
  areBorderColorsVisible: true,
};

export default OptionRowCard;
