/** Libraries */
import React, { useState } from "react";
import { Slide, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import html2canvas from "html2canvas";

/** Utils */
import { EndPoints, instance2 } from "../../service/Route";
import { SendSentryError } from "../../../tools";

/** Atoms */
import { StyledCloseIconRight } from "../../atom/CustomIcons/StyledCloseIcon";

/** Custom hooks */
import { useFeedbackQuestionsStore, useUiStore } from "../../../hooks";

/** Material UI - Custom elements */
import {
  ContentContainer,
  CustomTextareaAutosize,
  ModalContainer,
  StyledDialog,
  SubmitButton,
  TextFeedback,
  TextSubmitButton,
} from "./FeedbackCard.style";

export default function FeedbackCard({ takeScreenshot, questionId }) {
  const {
    isFeedbackPopupOpened,
    anchorEl,
    count,
    startFeedbackQuestionLogout,
  } = useFeedbackQuestionsStore();
  const { startErrorPopup } = useUiStore();
  const [questionFeedback, setQuestionFeedback] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const { user, token } = useSelector((state) => state.user);
  const theme = useTheme();

  const handleClosePopup = () => {
    startFeedbackQuestionLogout();
    setIsClosing(false);
    setQuestionFeedback("");
  };

  const captureScreenshot = async () => {
    const element = document.getElementById("singleQuestionViewOrgContainer");
    if (!element) return null;

    const canvas = await html2canvas(element);
    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => resolve(reader.result);
      });
    });
  };

  const submitFeedback = async (payLoad) => {
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const URL = EndPoints.questionRating;
    return instance2.post(URL, payLoad, { headers });
  };

  const handleSubmit = async () => {
    setIsClosing(true);
    setIsSubmitting(true);

    const payLoad = {
      point: count,
      explanation: questionFeedback,
      questionId,
      user: user._id,
    };

    if (takeScreenshot) {
      const imageEncoded = await captureScreenshot();
      if (imageEncoded) {
        payLoad.imageEncoded = imageEncoded;
      }
    }

    try {
      await submitFeedback(payLoad);
      handleClosePopup();
    } catch (error) {
      SendSentryError(error);
      startErrorPopup("Ett fel uppstod när feedbacken skulle skickas");
      setIsClosing(false);
      setIsSubmitting(false);
    }
  };

  const isSubmitDisabled = !questionFeedback || isSubmitting;

  return (
    <StyledDialog
      onClose={handleClosePopup}
      open={isFeedbackPopupOpened}
      anchorEl={anchorEl}
      hideBackdrop={true}
      TransitionComponent={Slide}
      TransitionProps={{ direction: "up" }}
    >
      <ModalContainer isClosing={isClosing}>
        <StyledCloseIconRight onClick={handleClosePopup} />
        <ContentContainer>
          <TextFeedback>
            Tack, din feedback sparades. <br /> Lägg gärna till något nedan. Det
            hjälper oss att förbättra oss (frivilligt).
          </TextFeedback>
          <CustomTextareaAutosize
            aria-label="feedback textarea"
            onChange={(e) => setQuestionFeedback(e.target.value)}
            value={questionFeedback}
            style={{
              minHeight: "45px",
              height: "90px",
            }}
          />
          <SubmitButton
            onClick={handleSubmit}
            disabled={isSubmitDisabled}
            style={{
              backgroundColor: isSubmitDisabled
                ? theme.palette.appcolors.grey3
                : theme.palette.appcolors.primaryColor,
              cursor: isSubmitDisabled ? "not-allowed" : "pointer",
            }}
          >
            <TextSubmitButton
              style={{
                color: isSubmitDisabled
                  ? theme.palette.appcolors.grey7
                  : theme.palette.appcolors.secondaryColor10,
              }}
            >
              Skicka in
            </TextSubmitButton>
          </SubmitButton>
        </ContentContainer>
      </ModalContainer>
    </StyledDialog>
  );
}
