/** Libraries */
import { useDispatch, useSelector } from "react-redux";

/** Slices */
import {
  loadLessExams,
  loadMoreExams,
  searchExams,
  setExamsData,
  setHistoryExamsData,
  uiCloseExamsProgressBackdrop,
  uiOpenExamsProgressBackdrop,
} from "../../redux/slices";

/** Utils */
import { handleErrors } from "../../utils/Errors";
import { historyOfUser } from "../../utils/Utils";

/** Axios - endpoint */
import { EndPoints, instance2 } from "../../components/service/Route";

export const useExamStore = () => {
  // eslint-disable-next-line no-unused-vars
  const dispatch = useDispatch();
  const {
    previousExams,
    allPreviousExams,
    provpassOrderBySeason,
    provHistoryData,
    provpassSeasons,
  } = useSelector((state) => state.exams);

  const { user, token: storedToken } = useSelector((state) => state.user);

  const startLoadingExamsData = async (userId, token) => {
    dispatch(uiOpenExamsProgressBackdrop());
    const id = user?._id || userId;
    const headers = {
      Authorization: `Bearer ${storedToken || token}`,
      "Content-Type": "application/json",
    };

    const getPreviosExams = EndPoints.getPreviousExams;
    const URL = EndPoints.simuleraQuizHistory + id;

    try {
      const [examsResponse, historyResponse] = await Promise.all([
        instance2.get(getPreviosExams, { headers }),
        instance2.get(URL, { headers }),
      ]);

      dispatch(
        setExamsData({
          allPreviousExams: examsResponse.data.data,
          previousExams: examsResponse.data.data.slice(0, 6),
          provpassOrderBySeason: examsResponse.data.provPassOrder,
        })
      );

      if (historyResponse?.data?.length > 0) {
        const { newArray, provPassArray } = historyOfUser(historyResponse);
        dispatch(
          setHistoryExamsData({
            provHistoryData: newArray,
            provpassSeasons: provPassArray,
          })
        );
      }
    } catch (error) {
      handleErrors(error);
    } finally {
      dispatch(uiCloseExamsProgressBackdrop());
    }
  };

  const startLoadMoreExams = () => {
    dispatch(loadMoreExams());
  };

  const startLoadLessExams = () => {
    dispatch(loadLessExams());
  };

  const startSeachingExams = (query) => {
    if (query === "") return startLoadLessExams();
    dispatch(searchExams(query));
  };

  return {
    /** Values */
    previousExams,
    allPreviousExams,
    provpassOrderBySeason,
    provHistoryData,
    provpassSeasons,

    /** Functions */
    startLoadingExamsData,
    startLoadMoreExams,
    startLoadLessExams,
    startSeachingExams,
  };
};
