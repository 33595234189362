/** Libraries */
import React, { useState, useEffect, lazy, Suspense } from "react";
import {
  Routes,
  Route,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
  Navigate,
} from "react-router-dom";
import { useTheme } from "@mui/material/styles";

/* Utils */
import retry from "./utils/retry";

/** Pages */

const Signup = lazy(() => retry(() => import("./pages/Signup/Signup")));
const Login = lazy(() => retry(() => import("./pages/Login/Login")));
const Home = lazy(() => retry(() => import("./pages/Home/Home")));
import QuestionViewer from "./pages/QuestionViewer";
import Courses from "./pages/Courses/Courses";
import Profile from "./pages/Profile/Profile";
import Message from "./pages/Message/Message";
import Logout from "./pages/Logout/Logout";
import Payment from "./pages/Payment/Payment";
import PayConfirm from "./pages/PayConfirmation/PayConfirnmation";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import ForgottenPassword from "./pages/ForgottenPassword/ForgottenPassword";

/** Organisms */
import TestInformation from "./components/organism/CoursesOrg/CoursePages/TestInformation/TestInformation";
import ProvPassInformationPage from "./components/organism/CoursesOrg/CoursePages/ProvPassInformation/ProvPassInformationPage";
import ResultSummaryOrg from "./components/organism/HomeOrg/HomePages/QuestionPages/ResultSummaryOrg/ResultSummaryOrg";
import StandardViewXyz from "./components/organism/CoursesOrg/CoursePages/StandardViewXyz/StandardViewXyz";
import ProvPassDtk from "./components/organism/CoursesOrg/CoursePages/ProvPassDtk/ProvPassDtk";
import CategoryPagesMain from "../src/components/organism/HomeOrg/HomePages/CategoryPages/CategoryPagesMain/CategoryPagesMain";
import QuestionViewXyzOrg from "./components/organism/HomeOrg/HomePages/QuestionPages/QuestionViewScreen/QuestionViewXyzOrg";
import QuestionViewDtkOrg from "./components/organism/HomeOrg/HomePages/QuestionPages/QuestionViewDtkOrg/QuestionViewDtkOrg";
import ResultQuestionViewDtkOrg from "./components/organism/HomeOrg/HomePages/QuestionPages/QuestionViewDtkOrg/ResultQuestionViewDTKOrg";
import CategoryPagesRightBar from "./components/organism/HomeOrg/HomePages/CategoryPages/CategoryPagesRightBar/CategoryPagesRightBar";
import Provresultat from "./components/organism/CoursesOrg/CoursePages/Provresultat/Provresultat";
import RattadOverblick from "./components/organism/CoursesOrg/CoursePages/RattadOverblick/RattadOverblick";
import CuExResult from "./components/organism/CustomizedExam/CuExResult/CuExResult";

/** Molecules */
import ResultFooter from "./components/molecule/ResultFooter/ResultFooter";
import EmailVerified from "./components/molecule/EmailVerified/EmailVerified";
import AppLoader from "./components/molecule/AppLoader";

/** Atoms */
import HelpPopup from "./components/atom/HelpPopup/HelpPopup";
import { ErrorPopup } from "./components/atom/ErrorPopup/ErrorPopup";

/** Custom hooks */
import { useAuthStore, useMixpanel } from "./hooks";

/** Route middlewares */
import { PrivateRoute, PublicRoute, PublicTestRouteSwitcher } from "./router";

/** Styles */
import "./App.css";

/** ENV */
import * as Sentry from "@sentry/react";
import PublicResultSummary from "./components/organism/PublicTestOrg/PublicResultSummary/PublicResultSummary";

// import { useSelector } from "react-redux";
import config from "./config/config";

/** Lecture */
import Lecture from "./pages/Lecture/Lecture";
import LectureTheory from "./pages/Lecture/LectureTheory";
import LectureCompleted from "./pages/Lecture/LectureCompleted";
import Admin from "./pages/Admin/Admin";
import { CssBaseline } from "@mui/material";
import CustomExams from "./pages/CustomExams/CustomExams";
import StudyPlan from "./pages/StudyPlan/StudyPlan";
import CuExSessionInfo from "./components/organism/CustomizedExam/CuExSessionInfo/CuExSessionInfo";

require("dotenv").config();
console.log("config", config);
/** Sentry configurations */
Sentry.init({
  dsn: config.REACT_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay(),
  ],
  environment: config.REACT_APP_SERVER_NAME === "PROD" ? "production" : "DEV",
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {
  const { user, checking, sessionRefresh } = useAuthStore();
  const mixpanel = useMixpanel();
  const { pathname } = useLocation();
  const [toggleIcon, setToggleIcon] = useState({
    dasboard: true,
    course: false,
    feedback: false,
    profile: false,
  });
  const theme = useTheme();

  const updateStatusbarColor = () => {
    const backgroundColor = theme.palette.appcolors.secondaryColor2;
    const themeColorMetaTag = document.querySelector(
      'meta[name="theme-color"]'
    );

    themeColorMetaTag &&
      themeColorMetaTag.setAttribute("content", backgroundColor);
  };

  useEffect(() => {
    updateStatusbarColor();
  }, [theme]);

  useEffect(() => {
    mixpanel.pageVisited(pathname.split("/")[1]);
  }, [pathname]);

  useEffect(() => {
    sessionRefresh();

    if (user?._id) {
      Sentry.setUser({ id: user._id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (checking) {
    return <AppLoader isLoading={true} />;
  }

  return (
    <div>
      <ErrorPopup />
      <CssBaseline />
      <Suspense fallback={<AppLoader isLoading={true} />}>
        <SentryRoutes>
          <Route
            path="/"
            element={
              <PublicRoute>
                <Signup />
              </PublicRoute>
            }
          />

          <Route
            path="login"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />

          <Route path="login/:token" element={<Login />} />

          <Route
            path="/resetPassword/:userId/:token"
            element={<ResetPassword />}
          />

          <Route path="/forgottenpassword" element={<ForgottenPassword />} />

          <Route
            path="/emailverified/:token"
            /** We may not need this route */
            element={
              <PublicRoute>
                <EmailVerified />
              </PublicRoute>
            }
          />

          <Route
            /** We may not need this route */
            path="/resultquesviewdtkorg"
            element={
              <PrivateRoute>
                <ResultQuestionViewDtkOrg />
              </PrivateRoute>
            }
          />

          <Route
            path="/quesviewdtkorg"
            /** We may not need this route */
            element={
              <PrivateRoute>
                <QuestionViewDtkOrg />
              </PrivateRoute>
            }
          />

          <Route
            path="/quesvieworg"
            /** We may not need this route */
            element={
              <PrivateRoute>
                <QuestionViewXyzOrg />
              </PrivateRoute>
            }
          />

          <Route
            path="resultfooter"
            /** We may not need this route */
            element={
              <PrivateRoute>
                <ResultFooter />
              </PrivateRoute>
            }
          />

          <Route
            path="/categoryrtbar"
            /** We can't access to this route only by the link */
            /** We may not need this route */
            element={
              <PrivateRoute>
                <CategoryPagesRightBar />
              </PrivateRoute>
            }
          />

          <Route
            path="/exercise/:id"
            /** We can't access to this route only by the link */
            /** We may not need this route */
            element={
              <PrivateRoute>
                <QuestionViewer />
              </PrivateRoute>
            }
          />

          <Route
            path="/exam/:id"
            /** We can't access to this route only by the link */
            /** We may not need this route */
            element={
              <PrivateRoute>
                <QuestionViewer />
              </PrivateRoute>
            }
          />

          <Route
            path="/public/:category/:index"
            element={<PublicTestRouteSwitcher />}
          />

          <Route
            path="/public/:category/:index/resultsummary"
            element={<PublicResultSummary />}
          />

          <Route
            path="/helppopup"
            /** We may not need this route */
            element={<HelpPopup />}
          />

          <Route
            path="home"
            element={
              <PrivateRoute>
                <Home toggleIcon={toggleIcon} setToggleIcon={setToggleIcon} />
              </PrivateRoute>
            }
          />

          <Route
            path="admin"
            element={
              <PrivateRoute>
                <Admin toggleIcon={toggleIcon} setToggleIcon={setToggleIcon} />
              </PrivateRoute>
            }
          />

          <Route
            path="studyplan"
            element={
              <PrivateRoute>
                <StudyPlan
                  toggleIcon={toggleIcon}
                  setToggleIcon={setToggleIcon}
                />
              </PrivateRoute>
            }
          />

          <Route
            path="courses"
            toggleIcon={toggleIcon}
            setToggleIcon={setToggleIcon}
            element={
              <PrivateRoute>
                <Courses />
              </PrivateRoute>
            }
          />

          <Route
            path="feedback"
            element={
              <PrivateRoute>
                <Message />
              </PrivateRoute>
            }
          />

          <Route
            path="profile"
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />

          <Route
            path="logout"
            /** We may not need this route */
            element={
              <PrivateRoute>
                <Logout />
              </PrivateRoute>
            }
          />

          <Route
            path="/checkout"
            element={
              <PrivateRoute>
                <Payment />
              </PrivateRoute>
            }
          />

          <Route
            path="/payment-confirmation"
            element={
              <PrivateRoute>
                <PayConfirm />
              </PrivateRoute>
            }
          />

          <Route
            path="/category"
            /** We can't access to this route only by the link */
            element={
              <PrivateRoute>
                <CategoryPagesMain />
              </PrivateRoute>
            }
          />

          <Route
            path="/question"
            /** We can't access to this route only by the link */
            element={
              <PrivateRoute>
                <QuestionViewXyzOrg />
              </PrivateRoute>
            }
          />

          <Route
            path="/resultsummary"
            /** We can't access to this route only by the link */
            element={
              <PrivateRoute>
                <ResultSummaryOrg />
              </PrivateRoute>
            }
          />

          <Route
            path="/provpassinfo"
            element={
              <PrivateRoute>
                <ProvPassInformationPage />
              </PrivateRoute>
            }
          />

          <Route
            path="/testInformation"
            /** We can't access to this route only by the link */
            element={
              <PrivateRoute>
                <TestInformation />
              </PrivateRoute>
            }
          />

          <Route
            path="/cuexsessioninfo"
            element={
              <PrivateRoute>
                <CuExSessionInfo />
              </PrivateRoute>
            }
          />

          <Route
            path="/cuexresult"
            /** We can't access to this route only by the link */
            element={
              <PrivateRoute>
                <CuExResult />
              </PrivateRoute>
            }
          />

          <Route
            path="/simuleraprov"
            /** We can't access to this route only by the link */
            element={
              <PrivateRoute>
                <StandardViewXyz />
              </PrivateRoute>
            }
          />

          <Route
            path="/simuleraanpassatprov"
            /** We can't access to this route only by the link */
            element={
              <PrivateRoute>
                <CustomExams />
              </PrivateRoute>
            }
          />

          <Route
            path="/provpassdtk"
            /** We can't access to this route only by the link */
            element={
              <PrivateRoute>
                <ProvPassDtk />
              </PrivateRoute>
            }
          />

          <Route
            path="/provresultat"
            /** We can't access to this route only by the link */
            element={
              <PrivateRoute>
                <Provresultat />
              </PrivateRoute>
            }
          />

          <Route
            path="/rattadoverblick"
            /** We can't access to this route only by the link */
            element={
              <PrivateRoute>
                <RattadOverblick />
              </PrivateRoute>
            }
          />

          <Route
            path="/lecture/theory"
            element={
              <PrivateRoute>
                <LectureTheory />
              </PrivateRoute>
            }
          />

          <Route
            path="/lecture"
            element={
              <PrivateRoute>
                <Lecture />
              </PrivateRoute>
            }
          />

          <Route
            path="/lecture/completed"
            element={
              <PrivateRoute>
                <LectureCompleted />
              </PrivateRoute>
            }
          />

          <Route path="/*" element={<Navigate to="/home" />} />
        </SentryRoutes>
      </Suspense>
    </div>
  );
}

export default App;
