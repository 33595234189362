/** Libraries */
import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

/** Atoms */
import { StyledQuestionInfo } from "../../../../atom/SharedExamExerciseStyledComponents/MQDoubleColumnText";

/** Molecules */
import QuestionStatement from "../../../../molecule/QuestionStatement/QuestionStatement";
import useWindowDimensions from "../../../../molecule/WindowDimensions/dimension";

/** Styles */
import {
  DescriptionContainer,
  FontUppgift,
  NumberQuestionTitle,
  StyledSparaSmallWrapper,
} from "./MQDescription.styles";

const MQDescription = ({
  description,
  sectionTitle,
  currNumberOfQuestions,
  ...props
}) => {
  const { width } = useWindowDimensions();

  return (
    <DescriptionContainer {...props}>
      <StyledSparaSmallWrapper>
        {currNumberOfQuestions && (
          <NumberQuestionTitle isDTK={sectionTitle === "DTK"}>
            <FontUppgift>
              <Typography variant="subtitle1">{`${
                currNumberOfQuestions || 0
              } uppgift${currNumberOfQuestions > 1 ? "er" : ""}:`}</Typography>
            </FontUppgift>
          </NumberQuestionTitle>
        )}
      </StyledSparaSmallWrapper>
      <StyledQuestionInfo
        description={description}
        width={width}
        dtk={sectionTitle === "DTK"}
      >
        {description && (
          <QuestionStatement
            key={description}
            description={description}
            title={sectionTitle !== "DTK" && sectionTitle}
            width={width}
            isMQ={true}
          />
        )}
      </StyledQuestionInfo>
    </DescriptionContainer>
  );
};

MQDescription.propTypes = {
  description: PropTypes.string.isRequired,
  sectionTitle: PropTypes.string.isRequired,
  currNumberOfQuestions: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
  ]),
};

MQDescription.defaultProps = {
  currNumberOfQuestions: false,
};

export default MQDescription;
