/** Libraries */
import React, { useMemo, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { AppBar, Box, Toolbar } from "@material-ui/core";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

/** Molecules */
import KvantitativProvpassInfo from "../../../molecule/KvantitativProvpasInfo/KvantitativProvpassInfo";
import VerbalProvpassInfo from "../../../molecule/VerbalProvpassInfo/VerbalProvpassInfo";

/** Atoms */
import {
  InfoHeaderTitle,
  InfoHeaderIconContainer,
  InfoButtonsContainer,
  InfoOtherButton,
  InfoStartButton,
} from "../../../atom/SharedExamInfoStyledComponents/SharedExamInfoStyledComponents";
import HelpPopup from "../../../atom/HelpPopup/HelpPopup";
import { TimerIcon } from "../../../atom/Timer/TimerPrimary/TimerPrimary.styles";
import { LeftArrowIcon } from "../../../atom/CustomIcons/ArrowIcons";
import { BarChartIcon } from "../../../atom/BarChart/BarChartIcon";

/** Custom hooks */
import { useExamStore, useMixpanel, useTimerStore } from "../../../../hooks";
import { useAllCustomExamStore } from "../../../../hooks/customExams/AllCustomExams/useAllCustomExamsStore";
import {
  timeToggle,
  transformSecToMinutes,
} from "../../../../utils/timeManipulation";

/** Material UI - Custom elements */
const MainContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  height: "100vh",
  background: theme.palette.appcolors.secondaryColor2,
}));

const InfoMainContainer = styled("div")(({ theme }) => ({
  maxWidth: "862px",
  maxHeight: "90vh",
  padding: "0rem 1rem",
  background: theme.palette.appcolors.secondaryColor4,
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  flexDirection: "column",
  [theme.breakpoints.down(1280)]: {
    alignItems: "flex-start",
  },
}));

const HeaderContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  marginTop: "1rem",
}));

const HeaderSubtitle = styled("div")(({ theme }) => ({
  display: "flex",
  fontSize: "14px",
  paddingTop: "0.5rem",
  [theme.breakpoints.down(600)]: {
    fontSize: "12px",
  },
}));

const CuExSessionInfo = () => {
  const navigate = useNavigate();
  const params = useLocation();
  const mixpanel = useMixpanel();
  const { startLoadingExamsData } = useExamStore();
  const { extendTimer, disabledTimer } = useSelector(
    (state) => state.timeCorrection
  );
  const DEFAULT_TIME_SEK = timeToggle(extendTimer);
  const DEFAULT_TIME_MINUTES = transformSecToMinutes(DEFAULT_TIME_SEK);
  const { customExamSessions, customizedExamSessionQuestions } =
    useAllCustomExamStore();
  const theme = useTheme();

  const currentSession = customExamSessions.find(
    (session) => session.isAttempted === false
  );

  // const DEFAULT_TIME_MINUTES = startTransformSecToMinutes(
  //   params?.state?.totalTime
  // );

  // const provpassNumber = useMemo(() => {
  //   const provpassOrder = params?.state?.provpassOrder;
  //   const simuleraQuizResultLength =
  //     params?.state?.provpass?.simuleraQuizResult?.length || 0;
  //   if (provpassOrder && provpassOrder[simuleraQuizResultLength]) {
  //     const provpass = provpassOrder[simuleraQuizResultLength];
  //     const provpassParts = provpass.split("-");
  //     if (provpassParts.length >= 3) {
  //       return provpassParts[2].replace(/[^0-9]/g, "");
  //     }
  //   }
  //   return null;
  // }, [params]);

  const isTimeDisabled = useMemo(
    () =>
      params?.state?.isTimeDisabled
        ? params?.state?.isTimeDisabled
        : disabledTimer,
    [params, disabledTimer]
  );

  const nextSessionHandler = () => {
    const nextSession = customExamSessions.find((session) => {
      return session.isAttempted === false;
    });
    const examSessionNumber = nextSession?.examSessionName.split(" ")[1];
    if (nextSession) {
      customizedExamSessionQuestions(nextSession._id).then((response) => {
        if (response) {
          navigate("/simuleraanpassatprov", {
            state: {
              totalTime: DEFAULT_TIME_SEK,
              isTimeDisabled: disabledTimer,
              examSessionNumber: examSessionNumber,
            },
          });
        }
      });
    }
  };

  const handleNavigation = () => {
    mixpanel.activityEnded("Exam");
    navigate("/courses");
    startLoadingExamsData();
  };

  const [helpPopup, setHelpPopup] = useState(false);

  return (
    <MainContainer>
      <AppBar
        style={{
          boxShadow: "none",
          color: theme.palette.appcolors.black,
          background: theme.palette.appcolors.secondaryColor2,
          justifyContent: "center",
          position: "relative",
        }}
      >
        {helpPopup && <HelpPopup />}
        <Toolbar
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              height: "5rem",
              maxHeight: "80px",
              width: "2.3rem",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={handleNavigation}
          >
            <LeftArrowIcon />
          </Box>
          <InfoHeaderTitle sx={{ fontWeight: 400 }}>Helt prov</InfoHeaderTitle>
          <Box
            sx={{
              cursor: "pointer",
            }}
            onClick={() => setHelpPopup(!helpPopup)}
          >
            <HelpOutlineIcon />
          </Box>
        </Toolbar>
      </AppBar>

      <InfoMainContainer>
        <HeaderContainer>
          <InfoHeaderTitle>
            {currentSession.sessionType === "Kvantitativt provpass"
              ? "Kvantitativt "
              : "Verbalt "}
          </InfoHeaderTitle>
          <HeaderSubtitle>
            <InfoHeaderIconContainer tabletmargin={"4rem"} phonemargin={"2rem"}>
              <BarChartIcon />
              40 frågor
            </InfoHeaderIconContainer>
            <InfoHeaderIconContainer>
              <TimerIcon
                style={{
                  marginRight: "0.5rem",
                  color: theme.palette.appcolors.grey,
                }}
              />
              {isTimeDisabled ? "Utan tid" : `${DEFAULT_TIME_MINUTES} min`}
            </InfoHeaderIconContainer>
          </HeaderSubtitle>
        </HeaderContainer>

        {currentSession.sessionType === "Kvantitativt provpass" ? (
          <KvantitativProvpassInfo />
        ) : (
          <VerbalProvpassInfo />
        )}

        <InfoButtonsContainer>
          <InfoOtherButton onClick={() => navigate("/courses")}>
            Fortsätt senare
          </InfoOtherButton>
          <InfoStartButton onClick={nextSessionHandler}>
            Starta provpass
          </InfoStartButton>
        </InfoButtonsContainer>
      </InfoMainContainer>
    </MainContainer>
  );
};

export default CuExSessionInfo;
