/** Libraries */
import React, { useEffect, useMemo, useState } from "react";
import { Box, Radio } from "@material-ui/core";
import { styled } from "@mui/material/styles";

/** Atoms */
import {
  StyledCorrectIcon,
  StyledRadiusCheckedIcon,
  StyledRadiusOnHoverIcon,
  StyledRadiusUnCheckedIcon,
  StyledWrongIcon,
} from "../../../../../atom/RadioIcons/RadioIcons";
import { PressEnterIcon } from "../../../../../atom/PressEnter/PressEnter";
import { StyledExamButton } from "../../../../../atom/Button/Button.styles";
import { NogOptionsInfo } from "../../../../../atom/NogInfoContainer/NogInfoContainer";

/** Molecules */
import ExamTextView from "../../../../../molecule/ExamTextView/ExamTextView";
import QuestionStatement from "../../../../../molecule/QuestionStatement/QuestionStatement";
import QuestionWrapper from "../../../../../molecule/QuestionContainers/QuestionWrapper";
import OptionsSQ from "../../../../../molecule/OptionsComponents/OptionsSQ/OptionsSQ";
import useWindowDimensions from "../../../../../molecule/WindowDimensions/dimension";
import NoneAnsweredAlert from "../../../../../molecule/Alerts/NoneAnsweredAlert/NoneAnsweredAlert";

/** Organisms */
import ProvPassDtk from "../../ProvPassDtk/ProvPassDtk";

/** Utils */
import { appColors } from "../../../../../../utils/commonService";

/** Assets */
import { useMixpanel } from "../../../../../../hooks";
import { Answer } from "../../../../../organism/Quiz/Answer/Answer";

/** MUI styled elements */
const NavigationButtonsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "10px",
}));

const NavigationButtonText = styled("p")(({ theme }) => ({
  textAlign: "center",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
}));

const QuestionContainer = styled(Box)(({ theme }) => ({
  marginTop: 30,
  fontFamily: "'Noto Sans', sans-serif",
  height: "fit-content",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  maxWidth: "800px",
  [theme.breakpoints.down(810)]: {
    marginTop: 30,
    fontFamily: "'Noto Sans', sans-serif",
    height: "fit-content",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    paddingLeft: "14px",
    paddingRight: "14px",
  },
}));

const ExamBodyReviewSQ = (props) => {
  const mixpanel = useMixpanel();
  const [onHover, setOnhover] = useState();
  const { width } = useWindowDimensions();

  const sectionTitle = useMemo(
    () => props?.currentQuestion?.sectionCategories?.title,
    [props?.currentQuestion]
  );

  // useEffect(() => {
  //   const handleEnterClick = (e) => {
  //     e.preventDefault();
  //     if (e.keyCode === 13) {
  //       props?.currentIndex + 1 < props?.quiz.question.length &&
  //         props?.setCurrentIndex(props?.currentIndex + 1);
  //     }
  //   };
  //   !feedbackPopup && document.addEventListener("keydown", handleEnterClick);

  //   return () => {
  //     document.removeEventListener("keydown", handleEnterClick);
  //   };
  // }, [props?.quiz, props?.currentIndex, feedbackPopup]);

  // Options and showOptionsNewStyles functions are pretty similar, we should use just one of them
  const Options = (question, option, optionIndex) => {
    if (
      question.questionAnswer &&
      question.questionAnswer.option == option._id
    ) {
      return <StyledCorrectIcon />;
    } else if (question.questionAnswer && option._id === question?.optionId) {
      return <StyledWrongIcon />;
    }
    if (optionIndex === question.selectedOptionIndex) {
      return (
        <Radio
          color="primary"
          checked={true}
          style={{
            marginRight: "0.5rem",
            color: appColors.blueColor,
          }}
        />
      );
    } else {
      return (
        <Radio
          color="primary"
          checked={false}
          style={{
            marginRight: "0.5rem",
            color: option._id == onHover && appColors.blueColor,
          }}
        />
      );
    }
  };

  /** Returns the options radius buttons for SQ*/
  const showOptionsNewStyles = (question, curentOption, optionIndex) => {
    if (question.answer && question.answer.option === curentOption._id) {
      return <Radio disabled checked={false} icon={<StyledCorrectIcon />} />;
    } else if (question.answer && curentOption._id === question?.optionId) {
      return <Radio disabled checked={false} icon={<StyledWrongIcon />} />;
    } else if (question.answer && curentOption._id !== question?.optionId) {
      return (
        <Radio disabled checked={false} icon={<StyledRadiusUnCheckedIcon />} />
      );
    }

    if (
      // optionIndex === question.selectedIndex ||
      optionIndex === question.selectedOptionIndex
    ) {
      return (
        <Radio
          checked={true}
          icon={<StyledRadiusUnCheckedIcon />}
          checkedIcon={<StyledRadiusCheckedIcon />}
        />
      );
    } else {
      return (
        <Radio
          checked={false}
          icon={
            onHover && curentOption._id === onHover ? (
              <StyledRadiusOnHoverIcon />
            ) : (
              <StyledRadiusUnCheckedIcon />
            )
          }
        />
      );
    }
  };

  return (
    <QuestionContainer width="100%" maxWidth="md">
      {/* start of question component */}

      {!props?.currentQuestion?.optionId && <NoneAnsweredAlert />}

      {props?.isReadingComprehension && (
        <ExamTextView
          text={
            props?.quiz?.question[props?.currentIndex]?.multipartQuestion
              ?.description
          }
          title={
            props?.quiz?.question[props?.currentIndex]?.multipartQuestion?.title
          }
          questionLength={
            props?.quiz?.question[props?.currentIndex]?.multipartQuestion
              ?.question.length
          }
        />
      )}

      {props?.currentQuestion.type === "multiple" && (
        <Box key={props?.currentQuestion._id}>
          <ProvPassDtk
            Options={(question, option, optionIndex) =>
              Options((question, option, optionIndex))
            }
            index={questionIndex}
            question={props?.currentQuestion}
            backPressPopup={() => props?.setBackPressPopup(true)}
            SelectOption={(e, optionIndex) => props?.SelectFunc(optionIndex)}
          />
        </Box>
      )}

      {props?.currentQuestion.type !== "multiple" && (
        <Box>
          {/* Qestion wrapper start */}
          <QuestionWrapper
            questionTypeTitle={sectionTitle}
            sparaButtonExists={false}
          >
            <QuestionStatement
              description={props?.currentQuestion?.questionStatement}
              indications={[
                props?.currentQuestion?.information1,
                props?.currentQuestion?.information2,
              ]}
              type={
                props?.quiz?.question[props?.currentIndex].sectionCategories
                  .title
              }
              image={props?.currentQuestion?.questionStatement?.includes(
                "hp-appen.s3.eu-north-1.amazonaws.com"
              )}
            />
          </QuestionWrapper>
          {/* Qestion wrapper end */}
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              margin: "1rem 0rem",
            }}
          >
            {/* if question is NOG */}
            {props?.quiz?.question[props?.currentIndex].sectionCategories
              .title === "NOG" && <NogOptionsInfo />}
            {/* if question is NOG end*/}

            <OptionsSQ
              questionTypeTitle={props?.currentQuestion.sectionCategories.title}
              onHover={onHover}
              onHoverLeave={() => setOnhover(null)}
              onhover={(optionId) => setOnhover(optionId)}
              question={props?.questionNewStructure}
              SelectOption={(item, index) => props?.SelectFunc(item._id, index)}
              showOptions={showOptionsNewStyles}
            />
          </Box>

          {props?.currentQuestion.questionAnswer && (
            <Answer
              isAiTutor={false}
              content={props?.currentQuestion.questionAnswer.answer}
              questionId={props?.currentQuestion?.questionId}
              sectionCategory={props.currentQuestion?.sectionCategories?.title}
              questionCategory={props.currentQuestion?.sectionCategories?.title}
            />
          )}
        </Box>
      )}

      {!props?.open && (
        <NavigationButtonsContainer>
          <StyledExamButton
            mq
            disabled={props?.currentIndex === 0}
            onClick={() =>
              props?.currentIndex > 0 &&
              props?.setCurrentIndex(props?.currentIndex - 1)
            }
          >
            <NavigationButtonText>Föregående</NavigationButtonText>
          </StyledExamButton>

          {props?.isLastQuestion ? (
            <StyledExamButton onClick={props?.handleNavigationResultPage}>
              <NavigationButtonText>Rättad Överblick</NavigationButtonText>
            </StyledExamButton>
          ) : (
            <Box
              style={{
                display: "flex",
                alignItems: "flex-end",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <StyledExamButton
                onClick={() => props?.setCurrentIndex(props?.currentIndex + 1)}
              >
                <NavigationButtonText>Nästa</NavigationButtonText>
              </StyledExamButton>
              <PressEnterIcon />
            </Box>
          )}
        </NavigationButtonsContainer>
      )}
    </QuestionContainer>
  );
};

export default ExamBodyReviewSQ;
