/** Libraries */
import { Box, Container } from "@mui/material";
import { styled } from "@mui/material/styles";

/** MUI styled elements */
export const DescriptionContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  flexDirection: "column",
  width: "100%",
  maxWidth: "900px",
  [theme.breakpoints.down(1200)]: {
    maxWidth: "100%",
  },
}));

export const StyledSparaSmallWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "70px",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: "20px",
  [theme.breakpoints.down(768)]: {
    paddingRight: "15px",
  },
}));

export const NumberQuestionTitle = styled(Container)(({ theme, isDTK }) => ({
  "&&": {
    maxWidth: "100%",
    width: "100%",
    padding: isDTK ? "0px 32px 0px 32px" : 0,
    display: "flex !important",
    flexDirection: "row",
    marginTop: "34px",
    marginBottom: "10px",

    [theme.breakpoints.down(1200)]: {
      padding: isDTK ? "0px 16px 0px 16px" : 0,
      flexDirection: "column",
    },
    [theme.breakpoints.up(1200)]: {
      justifyContent: "center",
      gap: "72px",
    },
  },
}));

export const FontUppgift = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: 900,
  height: "auto",
  position: "relative",
}));
