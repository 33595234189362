/** Libraries */
import { createSlice } from "@reduxjs/toolkit";

export const persistedSlice = createSlice({
  name: "persisted",
  initialState: {
    rememberMe: true,
    darkmode: false,
    lectureStartupDialog: true,
    exerciseStartupDialog: true,
    examStartupDialog: true,
  },
  reducers: {
    /** Remember me */
    setRememberMe: (state, action) => {
      state.rememberMe = action.payload;
    },

    /** Darkmode */
    setUiDarkmode: (state) => {
      state.darkmode = !state.darkmode;
    },

    /** Startup popups */
    setLectureStartupDialog: (state, action) => {
      state.lectureStartupDialog = action.payload;
    },
    setExerciseStartupDialog: (state, action) => {
      state.exerciseStartupDialog = action.payload;
    },
    setExamStartupDialog: (state, action) => {
      state.examStartupDialog = action.payload;
    },
  },
});

export const {
  setRememberMe,
  setUiDarkmode,
  setLectureStartupDialog,
  setExerciseStartupDialog,
  setExamStartupDialog,
} = persistedSlice.actions;
