/** Libraries */
import { Grid, Tab } from "@material-ui/core";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import Backdrop from "@mui/material/Backdrop";
import { useDispatch, useSelector } from "react-redux";

/** Organisms */
import PaymentModal from "../../../../PayWallOrg/PaymentModal";
import CategoryPagesRightBar from "../CategoryPagesRightBar/CategoryPagesRightBar";

/** Molecules */
import LectureDashboardCard from "../../../../../molecule/Cards/LectureDashboardCard/LectureDashboardCard";
import { ProgressPercentageFont } from "../../../../../molecule/Cards/LectureDashboardCard/LectureDashboardCard.styles";
import { CategoryTable } from "../../../../../molecule/CategoryTable/CategoryTable";
import CommonPopup from "../../../../../molecule/CommonPopup/CommonPopup";
import MobileMenu from "../../../../../molecule/MobileMenu/MobileMenu";
import useWindowDimensions from "../../../../../molecule/WindowDimensions/dimension";

/** Atoms */
import OutlineBox from "../../../../../atom/OutlineBox/OutlineBox";
import NewOutlineField from "../../../../../atom/OutlineField/NewOutlineField";
import OutlineField from "../../../../../atom/OutlineField/OutlineField";
import {
  HeaderContainer,
  MainContainer,
  MobileScreenDisplay,
  PremiumCardContainer,
  RightBarDisplay,
  StyledTabs,
  TabBox,
  TabletScreenDisplay,
} from "../../../../../atom/SharedExamExerciseStyledComponents/SharedExamExerciseStyledComponents";

/** Styles */
import {
  AmountSelectionContainer,
  BottomLectureExpander,
  CheckboxContainer,
  ChoiceText,
  DesktopScreenDisplay,
  DownArrow,
  EmojiBox,
  FilterContainer,
  FilterDropdownTitleBox,
  FilterOptionsContainer,
  LectureCardContainer,
  LectureCardLinkText,
  LectureTitleContainer,
  MainFilterContainer,
  MiddleContainer,
  NumericalOptionsBox,
  PageTitle,
  ProgressBar,
  SubtitleOfTheWelcomeMessage,
  TabsDisplay,
  TimeCheckboxContainer,
  TitleEmojiBox,
  TitleOfHeader,
  UpArrow,
  WelcomeMessageBox,
} from "./CategoryPagesContentFeed.styles";

/** Components */
import { EndPoints, instance2 } from "../../../../../service/Route";

/** Assets */
import DtkEmoji from "../../../../../../assets/Imgs/DtkEmoji.png";
import ElfEmoji from "../../../../../../assets/Imgs/ElfEmoji.png";
import KvaEmoji from "../../../../../../assets/Imgs/KvaEmoji.png";
import LasEmoji from "../../../../../../assets/Imgs/LasEmoji.png";
import LecturePayKvant from "../../../../../../assets/Imgs/LecturePayKvant.png";
import LecturePayVerb from "../../../../../../assets/Imgs/LecturePayVerb.png";
import MekEmoji from "../../../../../../assets/Imgs/MekEmoji.png";
import NogEmoji from "../../../../../../assets/Imgs/NogEmoji.png";
import OrdEmoji from "../../../../../../assets/Imgs/OrdEmoji.png";
import XyzEmoji from "../../../../../../assets/Imgs/XyzEmoji.png";
import categoryDescription from "../../../../../../assets/Static/CategoryDescription.json";

/** Tools */
import { SendSentryError } from "../../../../../../tools";

/** Utils */

import { handleErrors } from "../../../../../../utils/Errors";
import { appColors, scrollTop } from "../../../../../../utils/commonService";

/** Custom hooks */
import {
  useAiTutorStore,
  useExercisesSectionCategoriesStore,
  useLectureDashboardStore,
  useLectureStore,
  useMixpanel,
  useTimerStore,
  useUiStore,
  useExercisesMQReviewStore,
  useExercisesMQStore,
  useCategoryDetailsStore,
} from "../../../../../../hooks";

import {
  setCheckboxState,
  setCorrectAnswered,
  setEasyDifficulty,
  setExtendedTime,
  setHardDifficulty,
  setMediumDifficulty,
  setPartialTest,
  setQuestionFormat,
  setQuestionType,
  setSelectAll,
  setTimer,
  setUnAnswered,
  setWrongAnswered,
} from "../../../../../../redux/slices/saveChoices/saveChoicesSlice";
import { SecondaryIsPremiumCard } from "../../../../../molecule/IsPremiumCard/IsPremiumCard";
import { useMemo } from "react";

const CategoryPagesFeedContent = (props) => {
  const navigate = useNavigate();
  const mixpanel = useMixpanel();
  const { item } = props;
  const {
    lectureQuicez,
    lectureGeneralProgress,
    startSettingLectureQuiz,
    startLectureDashboardLogout,
  } = useLectureDashboardStore();
  const { width } = useWindowDimensions();
  const { startAiTutorLogout } = useAiTutorStore();
  const { quiz: lectureQuiz, startLectureLogout } = useLectureStore();
  const { startMQReviewLogout } = useExercisesMQReviewStore();
  const { startExerciseMultiQuestionLogout } = useExercisesMQStore();
  const { startTimerLogout } = useTimerStore();
  const {
    fetchTestHistory,
    fetchLastSevenWeeksData,
    fetchsevenWeeksCorrected,
    fetchHistoryTable,
  } = useCategoryDetailsStore();
  const theme = useTheme();
  const [areLecturesLoading, setAreLecturesLoading] = useState(true);
  const { startErrorPopup, startErrorRedirectionTrue } = useUiStore();

  /** Categories */
  const [questionCategories, setQuestionCategories] = useState([]);
  const [checkedValue, setCheckedValue] = useState();
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [expandLectures, setExpandLectures] = useState(false);
  const [categoryError, setCategoryError] = useState();
  const params = useLocation();
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [categoryTitle, setCategoryTitle] = useState("");
  const [noMoreQuestionsPopup, setNoMoreQuestionsPopup] = useState(false);
  const [paymentModalPopup, setPaymentModalPopup] = useState(false);

  const dispatch = useDispatch();
  /** Filters */
  const [filterCategory, setFilterCategory] = useState(false);
  const [filterDifficulty, setFilterDifficulty] = useState(false);
  const [filterStatus, setFilterStatus] = useState(false);

  /* Difficulty */
  const [selectedDifficultyLevels, setSelectedDifficultyLevels] = useState([]);

  const currentSectionCategory = useExercisesSectionCategoriesStore();
  const categoryName = currentSectionCategory?.title;

  const { user } = useSelector((state) => state.user);
  const {
    partialTest,
    checkboxState,
    timer,
    mediumDifficulty,
    hardDifficulty,
    easyDifficulty,
    wrongAnswered,
    extendedTime,
    unAnswered,
    correctAnswered,
    questionType,
    questionFormat,
    selectAll,
  } = useSelector((state) => state.saveChoices[categoryName]);
  const isVerbalCategoryAndEnabled = useMemo(
    () =>
      ["ELF", "ORD", "LÄS", "MEK"].includes(categoryName) &&
      user?.areVerbalFeaturesEnabled,
    [categoryName, user?._id]
  );

  const { tableHistory } = useSelector((state) => state.categoryDetails);

  const isKvantiativCategoryAndEnabled = useMemo(
    () =>
      ["XYZ", "KVA", "NOG", "DTK"].includes(categoryName) &&
      user?.areKvantiativFeaturesEnabled,
    [categoryName, user?._id]
  );
  const isPremium =
    isVerbalCategoryAndEnabled || isKvantiativCategoryAndEnabled;

  const svaraButtonText = useMemo(() => {
    if (isVerbalCategoryAndEnabled) {
      return "Starta övningar";
    }
    if (isKvantiativCategoryAndEnabled) {
      return "Starta övningar";
    }
    let result = "";
    switch (categoryName) {
      case "XYZ":
        result = "Gör exempelfrågor (24 tillgängliga)";
        break;

      case "ORD":
        result = "Gör exempelfrågor (100 tillgängliga)";
        break;

      case "KVA":
        result = "Gör exempelfrågor (20 tillgängliga)";
        break;

      case "NOG":
        result = "Gör exempelfrågor (12 tillgängliga)";
        break;

      case "MEK":
        result = "Gör exempelfrågor (20 tillgängliga)";
        break;

      case "ELF":
        result = "Gör exempelfrågor (20 tillgängliga)";
        break;

      case "DTK":
        result = "Gör exempelfrågor (24 tillgängliga)";
        break;

      case "LÄS":
        result = "Gör exempelfrågor (20 tillgängliga)";
        break;

      default:
        result = "Starta övningar";
        break;
    }
    return width < 600 ? result.split("(")[0] : result;
  }, [
    categoryName,
    width,
    isVerbalCategoryAndEnabled,
    isKvantiativCategoryAndEnabled,
  ]);

  // #region categoryStats
  useEffect(() => {
    const fetchData = () => {
      try {
        fetchLastSevenWeeksData(
          currentSectionCategory._id,
          currentSectionCategory.title
        );
        fetchsevenWeeksCorrected(currentSectionCategory._id);
        fetchHistoryTable(currentSectionCategory._id);
      } catch (error) {
        if (error.name !== "AbortError") {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchData = async () => {
      try {
        await fetchTestHistory(
          currentSectionCategory,
          setQuestionCategories,
          questionType,
          setQuestionType,
          questionFormat,
          setQuestionFormat,
          categoryName,
          setCategoryError,
          selectAll,
          signal
        );

        if (isMounted) {
          switch (categoryName) {
            case "KVA":
              setCategoryTitle("Kvantitativa jämförelser");
              break;
            case "MEK":
              setCategoryTitle("Meningskomplettering");
              break;
            case "NOG":
              setCategoryTitle("Kvantitativa resonemang");
              break;
            case "XYZ":
              setCategoryTitle("Matematisk problemlösning");
              break;
            case "LÄS":
              setCategoryTitle("Svensk läsförståelse");
              break;
            case "DTK":
              setCategoryTitle("Diagram, tabeller och kartor");
              break;
            case "ORD":
              setCategoryTitle("Ordförståelse");
              break;
            case "ELF":
              setCategoryTitle("Engelsk läsförståelse");
              break;
            default:
              break;
          }

          if (currentSectionCategory?._id) {
            startSettingLectureQuiz(
              currentSectionCategory,
              setAreLecturesLoading
            );
          }
          startLectureLogout();
          startLectureDashboardLogout();
          startMQReviewLogout();
          startExerciseMultiQuestionLogout();
          startTimerLogout();
          startAiTutorLogout();
          scrollTop();
        }
      } catch (error) {
        console.error("Error fetching test history:", error);
        handleErrors(error);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  useEffect(() => {
    const categoryFilters =
      questionType.length > 0 || questionFormat.length > 0;
    const difficultyFilters =
      mediumDifficulty || hardDifficulty || easyDifficulty;
    const statusFilters = wrongAnswered || unAnswered || correctAnswered;
    const isConditionsMet =
      categoryFilters && difficultyFilters && statusFilters;

    setIsSubmitEnabled(isConditionsMet);
  }, [
    selectAll,
    questionType,
    questionFormat,
    mediumDifficulty,
    hardDifficulty,
    easyDifficulty,
    wrongAnswered,
    unAnswered,
    correctAnswered,
  ]);

  /** Difficulty filters */
  useEffect(() => {
    const updatedSelectedDifficultyLevels = [];
    if (easyDifficulty) updatedSelectedDifficultyLevels.push("EASY");
    if (mediumDifficulty) updatedSelectedDifficultyLevels.push("MEDIUM");
    if (hardDifficulty) updatedSelectedDifficultyLevels.push("HARD");
    setSelectedDifficultyLevels(updatedSelectedDifficultyLevels);
  }, [easyDifficulty, mediumDifficulty, hardDifficulty]);

  const [tabValue, setTabValue] = useState(0);
  const handelChange = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

  const TabPanel = (props) => {
    const { children, value, index } = props;
    return <div>{value === index && <div>{children}</div>}</div>;
  };

  const setCheckedFunc = (value) => {
    if (value) {
      dispatch(setPartialTest({ categoryName, value: value }));
      setError(false);
    }
  };

  const isChecked = (id) => {
    return (
      questionType.some((obj) => obj === id) ||
      questionFormat.some((obj) => obj === id)
    );
  };

  const handleCheckboxClick = (item) => {
    dispatch(setSelectAll({ categoryName, value: false }));
    const { _id, isLabel } = item;
    const isInQuestionType = questionType.includes(_id);
    const isInQuestionFormat = questionFormat.includes(_id);

    setCategoryError(false);

    if (isInQuestionType) {
      const updatedQuestionType = questionType.filter((id) => id !== _id);
      dispatch(setQuestionType({ categoryName, value: updatedQuestionType }));
    } else if (isInQuestionFormat) {
      const updatedQuestionFormat = questionFormat.filter((id) => id !== _id);
      dispatch(
        setQuestionFormat({ categoryName, value: updatedQuestionFormat })
      );
    } else {
      if (isLabel) {
        dispatch(
          setQuestionFormat({ categoryName, value: [...questionFormat, _id] })
        );
      } else {
        dispatch(
          setQuestionType({ categoryName, value: [...questionType, _id] })
        );
      }
    }
  };

  const toggleExpandLectures = () => {
    expandLectures ? setExpandLectures(false) : setExpandLectures(true);
  };

  const onChangeAllFilter = () => {
    if (!selectAll) {
      dispatch(setSelectAll({ categoryName, value: true }));
      setCategoryError(false);
      let questionType = [];
      let questionFormat = [];
      questionCategories?.map((item) => {
        if (item.isLabel === true) {
          questionFormat.push(item._id);
        } else {
          questionType.push(item._id);
        }
      });
      dispatch(setQuestionType({ categoryName, value: questionType }));
      dispatch(setQuestionFormat({ categoryName, value: questionFormat }));
    } else {
      dispatch(setSelectAll({ categoryName, value: false }));
      dispatch(setQuestionType({ categoryName, value: [] }));
      dispatch(setQuestionFormat({ categoryName, value: [] }));
    }
  };

  const setDefaultNumberOfQuestions = () => {
    dispatch(
      setCheckboxState({
        categoryName,
        value: {
          selectedFive: false,
          selectedTen: false,
          selectedFifteen: false,
          selectedTwenty: false,
          selectedDefault: true,
        },
      })
    );
    let defaultNumberOfQuestion = 10;

    switch (categoryName) {
      case "XYZ":
        defaultNumberOfQuestion = 12;
        break;
      case "KVA":
        defaultNumberOfQuestion = 10;
        break;
      case "NOG":
        defaultNumberOfQuestion = 6;
        break;
      case "DTK":
        defaultNumberOfQuestion = 12;
        break;
      default:
        defaultNumberOfQuestion = 10;
        break;
    }
    setCheckedFunc(defaultNumberOfQuestion);
  };

  // #region Submittion

  const onSubmit = () => {
    if (partialTest == undefined) {
      setError(true);
    } else if (questionType && questionType.length < 1) {
      setNoMoreQuestionsPopup(true);
      return;
    } else {
      if (isSubmitEnabled) {
        localStorage.removeItem("time");
        localStorage.removeItem("quiz");
        setOpen(true);
        const data = {
          questionCategory: questionType,
          sectionCategory: currentSectionCategory._id,
          totalQuestion: parseInt(partialTest),
          value: timer,
          user: user._id,
          labels: questionFormat,
          multipartQuestion: null,
          isTimeRestricted: timer ? true : false,
          failedQuestion: wrongAnswered,
          unansweredQuestions: unAnswered,
          correctlyAnsweredQuestions: correctAnswered,
          selectedDifficultyLevels: selectedDifficultyLevels,
        };

        const URL = EndPoints.storeQuiz;
        instance2
          .post(URL, data)
          .then((response) => {
            if (response.data == "" || response.data.quiz.length < 1) {
              if (isPremium || response.data.isFreeUser) {
                setNoMoreQuestionsPopup(true);
              } else {
                setPaymentModalPopup(true);
              }
              setOpen(false);
            } else {
              setOpen(false);
              const quizobj = response.data;
              const { quiz: quistions } = quizobj;
              const questionswithSuffeldOptions = quistions.map((question) => {
                if (question.type == "multiple") {
                  const options0Array = question.question.map((item) => {
                    const options = item.options[0].options
                      .map((value) => ({ value, sort: Math.random() }))
                      .sort((a, b) => a.sort - b.sort)
                      .map(({ value }) => value);

                    const optionsShuffeld = [
                      {
                        options,
                      },
                    ];

                    return {
                      ...item,
                      options: optionsShuffeld,
                    };
                  });
                  return {
                    ...question,
                    question: options0Array,
                  };
                } else if (categoryName === "NOG" || categoryName === "KVA") {
                  return question;
                } else {
                  const options = question?.options[0]?.options
                    .map((value) => ({ value, sort: Math.random() }))
                    .sort((a, b) => a.sort - b.sort)
                    .map(({ value }) => value)
                    .filter((option) => option.value);
                  const optionsShuffeld = [
                    {
                      options,
                    },
                  ];
                  return {
                    ...question,
                    options: optionsShuffeld,
                  };
                }
              });
              quizobj.quiz = questionswithSuffeldOptions;
              mixpanel.startTest(currentSectionCategory.images, [
                quizobj.quiz.length,
                quizobj.isTimeRestricted,
              ]);

              /** Mixpanel */
              const mode = extendedTime
                ? "extended"
                : timer
                ? "normal - Tid & Prognos"
                : "normal";

              let filters = [];
              if (easyDifficulty) filters.push("Lätta");
              if (mediumDifficulty) filters.push("Medelsvåra");
              if (hardDifficulty) filters.push("Svåra");
              if (wrongAnswered) filters.push("Repetera fel");
              if (unAnswered) filters.push("Obesvarade");
              if (correctAnswered) filters.push("Korrekt besvarade");
              const allCategories = [...questionType, ...questionFormat];
              allCategories.map((e) => {
                questionCategories.map((item) => {
                  if (item._id === e) {
                    filters.push(item.title);
                  }
                });
              });
              mixpanel.activityStarted(
                "Exercise",
                currentSectionCategory?.title,
                null,
                mode,
                filters,
                parseInt(checkedValue)
              );
              /** Mixpanel */

              console.log({ quizobj, timer, extendedTime });

              navigate("/question", {
                state: {
                  data: quizobj,
                  quizId: quizobj._id,
                  time: timer,
                  isDyslexiaTime: extendedTime,
                },
              });
            }
          })
          .catch((error) => {
            console.error("Error storing quiz:", error);
            SendSentryError("QUIZ", "Error storing quiz", {
              details: "Failed to store quiz",
              error,
            });
            startErrorPopup();
            startErrorRedirectionTrue();
          });
      } else {
        setCategoryError(true);
      }
    }
  };

  const RepeatedExerciseSelectionBox = () => {
    return (
      <>
        <FilterContainer>
          <PageTitle>
            Övningsuppgifter för {categoryName}
            <Backdrop
              sx={{
                color: appColors.whiteColor,
                zIndex: 4000,
              }}
              open={open}
            >
              <CircularProgress color="inherit" size="5rem" />
            </Backdrop>
          </PageTitle>
          <NumericalOptionsBox>
            <Box>
              <ChoiceText>Välj Övningsläge</ChoiceText>
              <TimeCheckboxContainer>
                <NewOutlineField
                  disabled={extendedTime ? true : false}
                  title={"Tid & Prognos"}
                  type="checkbox"
                  checked={timer}
                  onClickCheck={(e) => {
                    if (!extendedTime) {
                      dispatch(setTimer({ categoryName, value: !timer }));
                    }
                  }}
                />
              </TimeCheckboxContainer>
            </Box>
            <Box style={{ marginLeft: width >= 2400 && "1rem" }}>
              <ChoiceText>Välj Antal Uppgifter</ChoiceText>
              <AmountSelectionContainer>
                <OutlineBox
                  mobile={width < 600 ? true : false}
                  checked={checkboxState.selectedFive}
                  title="5"
                  onChangeCheck={(value) => {
                    dispatch(
                      setCheckboxState({
                        categoryName,
                        value: {
                          selectedFive: true,
                          selectedTen: false,
                          selectedFifteen: false,
                          selectedTwenty: false,
                          selectedDefault: false,
                        },
                      })
                    );
                    setCheckedFunc(value);
                  }}
                />
                <OutlineBox
                  mobile={width < 600 ? true : false}
                  checked={checkboxState.selectedTen}
                  title="10"
                  onChangeCheck={(value) => {
                    dispatch(
                      setCheckboxState({
                        categoryName,
                        value: {
                          selectedFive: false,
                          selectedTen: true,
                          selectedFifteen: false,
                          selectedTwenty: false,
                          selectedDefault: false,
                        },
                      })
                    );
                    setCheckedFunc(value);
                  }}
                />
                <OutlineBox
                  mobile={width < 600 ? true : false}
                  title="15"
                  checked={checkboxState.selectedFifteen}
                  onChangeCheck={(value) => {
                    dispatch(
                      setCheckboxState({
                        categoryName,
                        value: {
                          selectedFive: false,
                          selectedTen: false,
                          selectedFifteen: true,
                          selectedTwenty: false,
                          selectedDefault: false,
                        },
                      })
                    );
                    setCheckedFunc(value);
                  }}
                />
                <OutlineBox
                  mobile={width < 600 ? true : false}
                  title="20"
                  checked={checkboxState.selectedTwenty}
                  onChangeCheck={(value) => {
                    dispatch(
                      setCheckboxState({
                        categoryName,
                        value: {
                          selectedFive: false,
                          selectedTen: false,
                          selectedFifteen: false,
                          selectedTwenty: true,
                          selectedDefault: false,
                        },
                      })
                    );
                    setCheckedFunc(value);
                  }}
                />
                <OutlineBox
                  mobile={width < 600 ? true : false}
                  size="large"
                  checked={checkboxState.selectedDefault}
                  title={"Ett delprov"}
                  onChangeCheck={() => setDefaultNumberOfQuestions()}
                />
              </AmountSelectionContainer>
            </Box>
          </NumericalOptionsBox>
          <NumericalOptionsBox>
            <Box>
              <ChoiceText>Anpassat för dyslexi</ChoiceText>
              <TimeCheckboxContainer>
                <NewOutlineField
                  disabled={timer ? false : true}
                  title={"Förlängd tid"}
                  type="checkbox"
                  checked={extendedTime}
                  onClickCheck={(e) => {
                    if (timer) {
                      dispatch(
                        setExtendedTime({
                          categoryName,
                          value: !extendedTime,
                        })
                      );
                    }
                  }}
                />
              </TimeCheckboxContainer>
            </Box>
            {/* Rätta direkt option will be placed here when it's ready! */}
          </NumericalOptionsBox>

          <MainFilterContainer container spacing={2}>
            <FilterOptionsContainer
              item
              xs={6}
              sm={6}
              md={4}
              lg={width >= 1280 && width <= 1430 ? 6 : 4}
            >
              <ChoiceText>Filtrera efter kategori</ChoiceText>
              <FilterDropdownTitleBox
                onClick={() => setFilterCategory(!filterCategory)}
              >
                <ChoiceText>Välj filter</ChoiceText>
                {filterCategory ? <UpArrow /> : <DownArrow />}
              </FilterDropdownTitleBox>
              {filterCategory && (
                <CheckboxContainer>
                  {questionCategories.length > 0 && (
                    <OutlineField
                      title="Alla"
                      onClickCheck={() => onChangeAllFilter()}
                      checked={selectAll}
                    />
                  )}
                  {questionCategories &&
                    questionCategories?.map((item, index) => {
                      return (
                        <div key={index}>
                          {item?.title !== "others" && (
                            <OutlineField
                              title={item.title}
                              onClickCheck={() => {
                                handleCheckboxClick(item);
                              }}
                              checked={isChecked(item._id)}
                            />
                          )}
                        </div>
                      );
                    })}
                </CheckboxContainer>
              )}
            </FilterOptionsContainer>

            <FilterOptionsContainer
              item
              xs={6}
              sm={6}
              md={4}
              lg={width >= 1280 && width <= 1430 ? 6 : 4}
            >
              <ChoiceText>Filtrera efter svårighet</ChoiceText>
              <FilterDropdownTitleBox
                onClick={() => setFilterDifficulty(!filterDifficulty)}
              >
                <ChoiceText>Välj filter</ChoiceText>
                {filterDifficulty ? <UpArrow /> : <DownArrow />}
              </FilterDropdownTitleBox>
              {filterDifficulty && (
                <CheckboxContainer>
                  <OutlineField
                    title="Medelsvåra"
                    onClickCheck={() =>
                      dispatch(
                        setMediumDifficulty({
                          categoryName,
                          value: !mediumDifficulty,
                        })
                      )
                    }
                    checked={mediumDifficulty}
                  />
                  <OutlineField
                    title="Svåra"
                    onClickCheck={() =>
                      dispatch(
                        setHardDifficulty({
                          categoryName,
                          value: !hardDifficulty,
                        })
                      )
                    }
                    checked={hardDifficulty}
                  />
                  <OutlineField
                    title="Lätta"
                    onClickCheck={() =>
                      dispatch(
                        setEasyDifficulty({
                          categoryName,
                          value: !easyDifficulty,
                        })
                      )
                    }
                    checked={easyDifficulty}
                  />
                </CheckboxContainer>
              )}
            </FilterOptionsContainer>

            <FilterOptionsContainer
              item
              xs={6}
              sm={6}
              md={4}
              lg={width >= 1280 && width <= 1430 ? 6 : 4}
            >
              <ChoiceText>Filtrera efter status</ChoiceText>
              <FilterDropdownTitleBox
                onClick={() => setFilterStatus(!filterStatus)}
              >
                <ChoiceText>Välj filter</ChoiceText>
                {filterStatus ? <UpArrow /> : <DownArrow />}
              </FilterDropdownTitleBox>
              {filterStatus && (
                <CheckboxContainer>
                  <OutlineField
                    title="Nya frågor"
                    onClickCheck={() =>
                      dispatch(
                        setUnAnswered({ categoryName, value: !unAnswered })
                      )
                    }
                    checked={unAnswered}
                  />
                  <OutlineField
                    title="Repetera fel"
                    onClickCheck={() =>
                      dispatch(
                        setWrongAnswered({
                          categoryName,
                          value: !wrongAnswered,
                        })
                      )
                    }
                    checked={wrongAnswered}
                  />
                  <OutlineField
                    title="Repetera rätt"
                    onClickCheck={() =>
                      dispatch(
                        setCorrectAnswered({
                          categoryName,
                          value: !correctAnswered,
                        })
                      )
                    }
                    checked={correctAnswered}
                  />
                </CheckboxContainer>
              )}
            </FilterOptionsContainer>
          </MainFilterContainer>
        </FilterContainer>
        <Box
          sx={{
            fontSize: "14px",
            fontWeight: "500",
            marginTop: "1rem",
            marginBottom: width < 600 ? "4rem" : "2rem",
            backgroundColor: isSubmitEnabled
              ? theme.palette.appcolors.primaryColor
              : theme.palette.appcolors.grey3,
            color: isSubmitEnabled
              ? theme.palette.appcolors.secondaryColor10
              : theme.palette.appcolors.grey7,
            borderRadius: "6px",
            height: "2.5rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderBottom: isSubmitEnabled
              ? `3px solid ${theme.palette.appcolors.black3}`
              : `3px solid ${theme.palette.appcolors.grey8}`,
            cursor: isSubmitEnabled ? "pointer" : "default",
            width: "99.35%",
            "&:hover": {
              transform: isSubmitEnabled && "scale(1.03)",
              transition: "all 0.3s ease-in-out",
            },
          }}
          onClick={() => isSubmitEnabled && onSubmit()}
        >
          {svaraButtonText}
        </Box>
      </>
    );
  };

  const RepeatedHistoryBox = () => {
    return (
      <>
        <PageTitle style={{ paddingBottom: "0.5rem" }}>Historik</PageTitle>
        <CategoryTable
          sectionCategory={currentSectionCategory}
          tableHistory={tableHistory}
          quiz={params?.state?.quiz}
        />
      </>
    );
  };

  const RepeatedRightBar = () => {
    return (
      <CategoryPagesRightBar
        item={currentSectionCategory}
        progress={params?.state?.progress}
        categoryId={currentSectionCategory._id}
      />
    );
  };

  const RepeatedLectureBox = () => {
    return (
      ["MEK", "XYZ", "LÄS", "ORD"].includes(currentSectionCategory?.title) && (
        <>
          <LectureTitleContainer>
            <PageTitle sx={{ minWidth: "fit-content", margin: "0px" }}>
              Studiematerial för {categoryName}
            </PageTitle>

            <Box
              style={{
                display: "flex",
                width: "100%",
                maxWidth: "400px",
                alignItems: "center",
                paddingLeft: "0.5rem",
              }}
            >
              <ProgressBar
                variant="determinate"
                value={Number(lectureGeneralProgress)}
              />

              <ProgressPercentageFont
                sx={{
                  justifyContent: "flex-start",
                  padding: "8px",
                  width: "2.5rem",
                }}
              >
                {lectureGeneralProgress.toFixed(0)}%
              </ProgressPercentageFont>
            </Box>
          </LectureTitleContainer>

          <LectureCardContainer
            expandlectures={expandLectures ? expandLectures : undefined}
          >
            <Grid container spacing={2} style={{ padding: "5px" }}>
              {lectureQuicez[currentSectionCategory?.title].length !== 0 &&
              !areLecturesLoading
                ? lectureQuicez[currentSectionCategory?.title].map((e, i) => (
                    <Grid
                      item
                      xs={width <= 600 && width >= 400 ? 6 : 12}
                      sm={6}
                      md={4}
                      lg={width > 1280 && width < 1430 ? 6 : 4}
                      xl={width <= 2400 ? 4 : 3}
                      key={i}
                    >
                      <LectureDashboardCard
                        key={i}
                        isLoading={lectureQuiz.length !== 0}
                        percentage={e.score.toFixed(0)}
                        title={e.lectureName}
                        isPremium={isPremium}
                        sectionCategory={item}
                        lecture={{ ...e, lectureIndex: i }}
                        image={categoryName}
                        popup={setPaymentModalPopup}
                      />
                    </Grid>
                  ))
                : [{}, {}, {}, {}, {}, {}].map((e, i) => (
                    <Grid
                      item
                      xs={width <= 600 && width >= 400 ? 6 : 12}
                      sm={6}
                      md={4}
                      lg={width > 1281 && width < 1429 ? 6 : 4}
                      xl={width <= 2400 ? 4 : 3}
                      key={i}
                    >
                      <LectureDashboardCard
                        key={i}
                        isLoading={true}
                        percentage={0}
                        title={""}
                        isPremium={isPremium}
                        sectionCategory={item}
                        lecture={{ ...e, lectureIndex: i }}
                        image={categoryName}
                        popup={setPaymentModalPopup}
                      />
                    </Grid>
                  ))}
            </Grid>
            <BottomLectureExpander
              expandlectures={expandLectures ? expandLectures : undefined}
              temporarylecturecardarray={
                lectureQuicez[currentSectionCategory?.title]
              }
              onClick={() => toggleExpandLectures()}
            >
              <LectureCardLinkText>
                {expandLectures ? "Visa mindre" : "Visa alla"}
              </LectureCardLinkText>

              {expandLectures ? <UpArrow /> : <DownArrow />}
            </BottomLectureExpander>
          </LectureCardContainer>
        </>
      )
    );
  };

  const emojiImage = (categoryName) => {
    switch (categoryName) {
      case "XYZ":
        return XyzEmoji;
      case "KVA":
        return KvaEmoji;
      case "NOG":
        return NogEmoji;
      case "DTK":
        return DtkEmoji;
      case "ORD":
        return OrdEmoji;
      case "ELF":
        return ElfEmoji;
      case "LÄS":
        return LasEmoji;
      case "MEK":
        return MekEmoji;
      default:
        return XyzEmoji;
    }
  };

  const lecturePaywallImage = (categoryName) => {
    switch (categoryName) {
      case "XYZ":
      case "KVA":
      case "NOG":
      case "DTK":
        return LecturePayKvant;
      case "ORD":
      case "ELF":
      case "LÄS":
      case "MEK":
        return LecturePayVerb;
      default:
        return null;
    }
  };

  return (
    <MainContainer>
      <CommonPopup
        status={noMoreQuestionsPopup}
        closePopup={() => setNoMoreQuestionsPopup(false)}
        title="Det finns inga frågor som matchar dessa villkor."
        description="Ta en extra titt på dina val och säkerställ att rätt filter är aktiverade."
        agreeBtnName="OK"
        redirect={() => navigate("/category")}
      />
      <PaymentModal
        open={paymentModalPopup}
        handleClose={() => setPaymentModalPopup(false)}
        img={lecturePaywallImage(categoryName)}
        lecture
      />
      <MiddleContainer>
        <MobileMenu />
        <HeaderContainer>
          <WelcomeMessageBox sx={{ maxWidth: "1164px" }}>
            <TitleEmojiBox>
              <TitleOfHeader>
                {categoryTitle}
                <EmojiBox src={emojiImage(categoryName)} />
              </TitleOfHeader>
            </TitleEmojiBox>
            <SubtitleOfTheWelcomeMessage>
              {categoryDescription[categoryName]}
            </SubtitleOfTheWelcomeMessage>
          </WelcomeMessageBox>
          <PremiumCardContainer>
            <SecondaryIsPremiumCard isPremium={isPremium} />
          </PremiumCardContainer>
        </HeaderContainer>

        <TabsDisplay>
          <TabBox>
            <StyledTabs
              value={tabValue}
              onChange={handelChange}
              variant="scrollable"
              scrollButtons={"off"}
              aria-label="scrollable prevent tabs example"
            >
              <Tab
                label="Övningar"
                style={{
                  fontSize: "1rem",
                  marginRight: "2rem",
                  minWidth: "5rem",
                  textTransform: "initial",
                  color:
                    tabValue == 0
                      ? theme.palette.appcolors.primaryColor
                      : theme.palette.appcolors.primaryColor2,
                  opacity: 1,
                }}
                onClick={() => {
                  setTabValue(0);
                }}
              />
              <Tab
                label="Historik"
                style={{
                  fontSize: "1rem",
                  marginRight: "2rem",
                  minWidth: "5rem",
                  textTransform: "initial",
                  color:
                    tabValue == 1
                      ? theme.palette.appcolors.primaryColor
                      : theme.palette.appcolors.primaryColor2,
                  opacity: 1,
                }}
                onClick={() => {
                  setTabValue(1);
                }}
              />
              <Tab
                label="Analys"
                style={{
                  fontSize: "1rem",
                  minWidth: "5rem",
                  textTransform: "initial",
                  color:
                    tabValue == 2
                      ? theme.palette.appcolors.primaryColor
                      : theme.palette.appcolors.primaryColor2,
                  opacity: 1,
                }}
                onClick={() => {
                  setTabValue(2);
                }}
              />
            </StyledTabs>
          </TabBox>
        </TabsDisplay>

        <DesktopScreenDisplay>
          {RepeatedLectureBox()}
          {RepeatedExerciseSelectionBox()}
          {RepeatedHistoryBox()}
        </DesktopScreenDisplay>

        <TabletScreenDisplay>
          {RepeatedLectureBox()}
          {RepeatedExerciseSelectionBox()}
          {RepeatedHistoryBox()}
          {RepeatedRightBar()}
        </TabletScreenDisplay>

        <MobileScreenDisplay>
          <TabPanel value={tabValue} index={0}>
            {RepeatedLectureBox()}
            {RepeatedExerciseSelectionBox()}
          </TabPanel>

          <TabPanel value={tabValue} index={1}>
            {RepeatedHistoryBox()}
          </TabPanel>

          <TabPanel value={tabValue} index={2}>
            {RepeatedRightBar()}
          </TabPanel>
        </MobileScreenDisplay>
      </MiddleContainer>
      <RightBarDisplay>{RepeatedRightBar()}</RightBarDisplay>
    </MainContainer>
  );
};

export default CategoryPagesFeedContent;
