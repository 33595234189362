/** Libraries */
import { alpha, styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { appColors } from "../../../utils/commonService";

/** Material UI custom elements */
export const MainButtonContainer = styled("button")(({ theme }) => ({
  cursor: "pointer",
  width: "100%",
  "&:disabled": {
    display: "inline-flex",
    padding: "8px 16px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "5px",
    background: appColors.grey10,
    border: "none",
    color: appColors.grey35,
    textAlign: "center",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    cursor: "default",
  },
}));

export const StyledButton = styled(Button)(
  ({ theme, lecture, lecturecomplete }) => ({
    marginTop: "1rem",
    "&.MuiButtonBase-root": {
      ":hover": {
        backgroundColor: theme.palette.appcolors.primaryColor,
        transform: "scale(1.02)",
      },
      backgroundColor: theme.palette.appcolors.primaryColor,
      border: `2px solid ${theme.palette.appcolors.primaryColor}`,
      color: theme.palette.appcolors.secondaryColor10,
      textTransform: "capitalize",
      fontWeight: "regular",
      padding: lecturecomplete ? ".60rem" : ".60rem 3rem",
      borderRadius: "8px",
      height: "40px",
      width: "100%",
      maxWidth: !lecture && "238px",
    },
    [theme.breakpoints.down(700)]: {
      "&.MuiButtonBase-root": {
        width: "100%",
        marginBottom: 0,
      },
    },
    [theme.breakpoints.down(500)]: {
      "&.MuiButtonBase-root": {
        padding: ".60rem 0",
      },
    },
  })
);

export const StyledLectureButton = styled(Button)(
  ({ theme, explain, disabled, mq }) => ({
    "&.MuiButtonBase-root": {
      ":hover": {
        transform: "scale(1.02)",
      },
      backgroundColor: disabled
        ? !explain && theme.palette.appcolors.grey4
        : !explain && theme.palette.appcolors.primaryColor,
      border: `1px solid ${
        disabled
          ? theme.palette.appcolors.grey3
          : theme.palette.appcolors.primaryColor
      }`,
      borderBottom: `3px solid ${
        disabled
          ? theme.palette.appcolors.grey3
          : theme.palette.appcolors.primaryColor
      }`,
      color: disabled
        ? theme.palette.appcolors.grey9
        : explain
        ? theme.palette.appcolors.primaryColor
        : theme.palette.appcolors.secondaryColor10,
      textTransform: "capitalize",
      fontWeight: 600,
      borderRadius: "8px",
      width: !explain ? "155px" : "273px",
      minHeight: "66px",
      marginBottom: mq && "20px",
      [theme.breakpoints.down(1200)]: {
        marginBottom: mq && "0rem",
      },
    },
  })
);

export const StyledExamButton = styled(Button)(({ theme, disabled, mq }) => ({
  "&.MuiButtonBase-root": {
    border: `1px solid ${
      disabled
        ? theme.palette.appcolors.grey8
        : theme.palette.appcolors.primaryColor
    }`,
    backgroundColor: disabled ? theme.palette.appcolors.grey3 : "transparent",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "none",
    height: "46px",
    width: "100%",
    color: disabled
      ? theme.palette.appcolors.grey7
      : theme.palette.appcolors.primaryColor,
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    marginBottom: mq && "20px",
    [theme.breakpoints.down(1200)]: {
      marginBottom: mq && "0rem",
    },
    "&:hover": {
      backgroundColor: "transparent",
      transform: "scale(1.02)",
      transition: "all 0.3s ease-in-out",
    },
  },
}));

export const useStyles = makeStyles((theme) => ({
  secondary: {
    border: `1px solid ${appColors.blueColor}`,
    backgroundColor: appColors.whiteColor,
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "none",
    height: "46px",
    color: `${appColors.blueColor}`,
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    "&:hover": {
      backgroundColor: `${alpha(appColors.blackColor, 0.04)}`,
    },
  },
  blue: {
    border: `1px solid ${appColors.blueColor}`,
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    textTransform: "none",
    width: "50%",
    height: "46px",
    color: `${appColors.whiteColor}`,
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    backgroundColor: `${appColors.blueColor}`,
    "&:hover": {
      backgroundColor: `${alpha(appColors.blueColor, 0.9)}`,
    },
  },
  white: {
    border: `none`,
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    textTransform: "none",
    width: "50%",
    height: "46px",
    color: `${appColors.blueColor}`,
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    backgroundColor: `${appColors.whiteColor}`,
    "&:hover": {
      backgroundColor: `${alpha(appColors.blackColor, 0.04)}`,
    },
  },
  black: {
    border: `1px solid ${appColors.blackColor}`,
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    textTransform: "none",
    width: "50%",
    height: "46px",
    color: appColors.blackColor,
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    backgroundColor: `${appColors.whiteColor}`,
    "&:hover": {
      backgroundColor: `${alpha(appColors.blackColor, 0.04)}`,
    },
  },
}));
