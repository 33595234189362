import { EndPoints, instance2 } from "../components/service/Route";
import { datesGroupByComponent, compareVersions } from "./commonService";
import { handleErrors } from "./Errors";
import { MEKNormeringValueFor } from "./normringCalculations/NormringCalculator";
import { NOGNormeringValueFor } from "./normringCalculations/NormringCalculator";
import { ORDNormeringValueFor } from "./normringCalculations/NormringCalculator";
import { XYZNormeringValueFor } from "./normringCalculations/NormringCalculator";

import { DTKNormeringValueFor } from "./normringCalculations/NormringCalculator";
import { ELFNormeringValueFor } from "./normringCalculations/NormringCalculator";
import { KVANormeringValueFor } from "./normringCalculations/NormringCalculator";
import { LASNormeringValueFor } from "./normringCalculations/NormringCalculator";
import { quantitativePercentageCalculator } from "./normringCalculations/Quantitative";
import { verbalPercentageCalculator } from "./normringCalculations/Verbal";

export const valueFor = (percentage, rageArray) => {
  let rageValue = 0;
  for (let i = 0; i < rageArray.length; i++) {
    const isInRange = inRange(percentage, rageArray[i].min, rageArray[i].max);
    if (isInRange === true) {
      rageValue = rageArray[i].value;
      break;
    }
  }
  return rageValue;
};

function inRange(x, min, max) {
  return min <= x && x <= max;
}
// not used yet
export const calculateWeekWiseNorming = (weekWiseData, testTypes) => {
  const weeklyProgressArr = [];
  let weekWiseProgress = {};
  let totalCorrecteted = 0;
  let totalAttempted = 0;
  let calculationForTerminate = 0;

  // calculate percentage of week wise data and got normring from table
  weekWiseData &&
    Object.keys(weekWiseData).map((weekKeyName, index) => {
      const week = "V." + weekKeyName;

      //reverse loop to add privious weeks data to achive privious hundred questions progress
      for (let iterations = index; iterations >= 0; iterations--) {
        const weekData = Object.values(weekWiseData)[iterations];
        for (
          let indexQuizResolved = 0;
          indexQuizResolved < weekData.length;
          indexQuizResolved++
        ) {
          const solvedQuizOfWeek = weekData[indexQuizResolved];
          calculationForTerminate += solvedQuizOfWeek.attemptedQuestion;
          if (calculationForTerminate <= 60) {
            totalCorrecteted += solvedQuizOfWeek.correctAnswer;
            totalAttempted += solvedQuizOfWeek.attemptedQuestion;
          } else {
            let answers = solvedQuizOfWeek.answer;
            const loopterminater = 60 - totalAttempted;
            let remainingCorrected = 0;
            for (
              let answersIndex = 0;
              answersIndex <= loopterminater;
              answersIndex++
            ) {
              const answer = answers[answersIndex];
              remainingCorrected += answer.questionCounter;
            }
            totalAttempted = 60;
            totalCorrecteted = totalCorrecteted + remainingCorrected;
            break;
          }
        }
        if (calculationForTerminate > 60) {
          break;
        }
      }

      weekWiseProgress.correctAnswers = totalCorrecteted;
      weekWiseProgress.attemptQuestions = totalAttempted;
      totalCorrecteted = 0;
      totalAttempted = 0;
      calculationForTerminate = 0;
      weeklyProgressArr.push({ ...weekWiseProgress, name: week });
      return { ...weekWiseProgress, name: week };
    });

  return weeklyProgressArr;
};

export const calculateWeekWiseNormingForHomePageGraph = (
  weekWiseData,
  testTypes
) => {
  const weeklyProgressArr = [];
  const mapData = new Map(weekWiseData);
  let keys = [];
  let values = [];
  for (let [key, value] of mapData.entries()) {
    keys.push(key);
    values.push(value);
  }
  // console.log("has ash ajsh", keys, values)

  let weekWiseProgress = {};
  let totalCorrecteted = 0;
  let totalAttempted = 0;
  let calculationForTerminate = 0;

  // calculate percentage of week wise data and got normring from table
  mapData &&
    keys.map((weekKeyName, index) => {
      // const week = "V." + weekKeyName;

      //reverse loop to add privious weeks data to achive privious hundred questions progress
      for (let iterations = index; iterations >= 0; iterations--) {
        const weekData = values[iterations];
        for (
          let indexQuizResolved = 0;
          indexQuizResolved < weekData.length;
          indexQuizResolved++
        ) {
          const solvedQuizOfWeek = weekData[indexQuizResolved];
          calculationForTerminate++;
          if (calculationForTerminate <= 60) {
            totalCorrecteted += solvedQuizOfWeek.questionCounter;
            totalAttempted++;
            if (calculationForTerminate === 60) {
              break;
            }
          }
          //  else {
          //   let attempts = solvedQuizOfWeek.attempts;
          //   const loopterminater = 60 - totalAttempted;
          //   let remainingCorrected = 0;
          //   for (
          //     let answersIndex = 0;
          //     answersIndex <= loopterminater;
          //     answersIndex++
          //   ) {
          //     const attempt = attempts[answersIndex];
          //     if (!attempt) {
          //       continue;
          //     }
          //     remainingCorrected += attempt.questionCounter;
          //   }
          //   totalAttempted = 60;
          //   totalCorrecteted = totalCorrecteted + remainingCorrected;
          //   break;
          // }
        }
        if (calculationForTerminate > 60) {
          break;
        }
      }

      weekWiseProgress.correctAnswers = totalCorrecteted;
      weekWiseProgress.attemptQuestions = totalAttempted;
      totalCorrecteted = 0;
      totalAttempted = 0;
      calculationForTerminate = 0;
      weeklyProgressArr.push({ ...weekWiseProgress, name: weekKeyName });
      return { ...weekWiseProgress, name: weekKeyName };
    });

  return weeklyProgressArr;
};

export const getWeekNumbers = () => {
  const weeksArray = [];
  const todayDate = new Date();
  const currentWeek = getCurrentWeekNumber(todayDate);
  for (
    let index = currentWeek;
    index > (currentWeek > 7 ? currentWeek - 7 : 0);
    index--
  ) {
    weeksArray.push("V." + todayDate.getYear() + "." + index);
  }

  if (weeksArray.length < 7) {
    const currentYear = new Date().getFullYear();
    const previousYear = new Date(currentYear - 1 + "/12/31");
    const lastYearWeekNumber = getCurrentWeekNumber(previousYear);
    const whenTerminateLoop = lastYearWeekNumber - (7 - weeksArray.length);

    for (let index = lastYearWeekNumber; index > whenTerminateLoop; index--) {
      weeksArray.push("V." + previousYear.getYear() + "." + index);
    }
  }
  return weeksArray;
};

export const getCurrentWeekNumber = (date) => {
  const tempDate = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );

  // Set to nearest Thursday: current date + 4 - current day number
  tempDate.setUTCDate(tempDate.getUTCDate() + 4 - (tempDate.getUTCDay() || 7));

  // Calculate first day of the year
  const yearStart = new Date(Date.UTC(tempDate.getUTCFullYear(), 0, 1));

  // Calculate the week number
  return Math.ceil(((tempDate - yearStart) / 86400000 + 1) / 7);
};

export const graphMetrics = getWeekNumbers()
  .reverse()
  ?.map((week) => ({
    name: `V.${week.split(".")[2]}`,
    Prognos: null,
  }));

// not used yet
export const calculateWeekWiseNormingForCategorynewlessthen7weekNumber = (
  sevenWeekWiseData,
  isDesplayProgress,
  setIsDesplayProgress,
  categoryname
) => {
  const weeklyProgressArr = [];
  let a;
  if (Object.keys(sevenWeekWiseData).length < 7) {
    let keys = Object.keys(sevenWeekWiseData); //35,36
    let first = keys[0]; //35
    a = 7 - keys.length; //5
    let b = first - a; //30 //first = 35
    for (let index = b; index < first; index++) {
      weeklyProgressArr.push({
        correctAnswers: 0,
        attemptQuestions: 0,
        eachCategoryPrognos: null,
        weekWiseCorrected: 0,
        name: "V." + index,
      });
    }
  }

  let correctAnswers = 0;
  let attemptQuestions = 0;
  let eachCategoryPrognos = null;
  let calculationForTerminate = 0;

  sevenWeekWiseData &&
    Object.keys(sevenWeekWiseData).forEach((weekKey, index) => {
      const weekKeyName = "V." + weekKey;
      let weekWiseCorrected = 0;

      for (let iterations = index; iterations >= 0; iterations--) {
        let weekWiseData = Object.values(sevenWeekWiseData)[iterations];

        if (iterations === index) {
          for (const solvedQuiz of weekWiseData) {
            weekWiseCorrected = weekWiseCorrected + solvedQuiz.correctAnswer;
          }
        }

        weekWiseData = weekWiseData.sort((a, b) =>
          b.createdAt.localeCompare(a.createdAt)
        );
        for (
          let indexQuizResolved = 0;
          indexQuizResolved < weekWiseData.length;
          indexQuizResolved++
        ) {
          const solvedQuizOfWeek = weekWiseData[indexQuizResolved];

          if (solvedQuizOfWeek.quiz.isTimeRestricted) {
            calculationForTerminate += solvedQuizOfWeek.attemptedQuestion;
            if (calculationForTerminate <= 60) {
              correctAnswers += solvedQuizOfWeek.correctAnswer;
              attemptQuestions += solvedQuizOfWeek.attemptedQuestion;
            } else {
              let answers = solvedQuizOfWeek.answer;
              // answers = answers.reverse()
              const loopterminater = 60 - attemptQuestions;
              let remainingCorrected = 0;
              for (
                let answersIndex = 0;
                answersIndex <= loopterminater;
                answersIndex++
              ) {
                const answer = answers[answersIndex];
                remainingCorrected += answer.questionCounter;
              }
              attemptQuestions = 60;
              correctAnswers = correctAnswers + remainingCorrected;
              break;
            }
          }
        }
        if (calculationForTerminate > 60) {
          break;
        }
      }
      if (attemptQuestions >= 20) {
        if (!isDesplayProgress) {
          setIsDesplayProgress(true);
        }
        let progress = (correctAnswers / attemptQuestions) * 100;

        eachCategoryPrognos = percentageCalculation(
          progress < 0 ? 0 : progress.toFixed(2),
          categoryname
        );
        weeklyProgressArr.push({
          correctAnswers,
          attemptQuestions,
          eachCategoryPrognos,
          weekWiseCorrected,
          name: weekKeyName,
        });
      } else {
        weeklyProgressArr.push({
          eachCategoryPrognos: null,
          weekWiseCorrected,
          correctAnswers: correctAnswers,
          attemptQuestions: attemptQuestions,
          name: weekKeyName,
        });
      }
      correctAnswers = 0;
      attemptQuestions = 0;
      eachCategoryPrognos = null;
      calculationForTerminate = 0;
    });

  return weeklyProgressArr;
};

export const calculateWeekWiseNormingForCategory = (
  sevenWeekWiseData,
  isDesplayProgress,
  setIsDesplayProgress,
  categoryname
) => {
  const weeklyProgressArr = [];
  let sortable = [];
  for (var key in sevenWeekWiseData) {
    sortable.push([key, sevenWeekWiseData[key]]);
  }

  sortable.sort(function (a, b) {
    return new Date(a[1][0].createdAt) - new Date(b[1][0].createdAt);
  });

  let obj = new Map();
  sortable.forEach((innerArray) => {
    obj.set(innerArray[0], innerArray[1]);
  });

  let keys = [];
  let values = [];
  for (let [key, value] of obj.entries()) {
    keys.push(key);
    values.push(value);
  }

  let a;
  if (keys.length < 7) {
    let first = keys[0]; //35
    a = 7 - keys.length; //5
    let b = first - a; //30 //first = 35
    for (let index = b; index < first; index++) {
      weeklyProgressArr.push({
        correctAnswers: 0,
        attemptQuestions: 0,
        eachCategoryPrognos: null,
        weekWiseCorrected: 0,
        name: "V." + index,
      });
    }
  }

  let correctAnswers = 0;
  let attemptQuestions = 0;
  let eachCategoryPrognos = null;
  let calculationForTerminate = 0;

  sevenWeekWiseData &&
    keys.forEach((weekKey, index) => {
      let weekWiseCorrected = 0;

      for (let iterations = index; iterations >= 0; iterations--) {
        let weekWiseData = values[iterations];

        if (iterations === index) {
          for (const solvedQuiz of weekWiseData) {
            weekWiseCorrected = weekWiseCorrected + solvedQuiz.questionCounter;
          }
        }

        weekWiseData = weekWiseData.sort((a, b) =>
          b.createdAt.localeCompare(a.createdAt)
        );
        for (
          let indexQuizResolved = 0;
          indexQuizResolved < weekWiseData.length;
          indexQuizResolved++
        ) {
          const solvedQuizOfWeek = weekWiseData[indexQuizResolved];

          if (solvedQuizOfWeek.is_time_on) {
            calculationForTerminate++;
            if (calculationForTerminate <= 60) {
              correctAnswers += solvedQuizOfWeek.questionCounter;
              attemptQuestions++;
              if (calculationForTerminate === 60) {
                break;
              }
            }
            // else {
            //   let answers = solvedQuizOfWeek.attempts;
            //   const loopterminater = 60 - attemptQuestions;
            //   let remainingCorrected = 0;
            //   for (
            //     let answersIndex = 0;
            //     answersIndex <= loopterminater;
            //     answersIndex++
            //   ) {
            //     const answer = answers[answersIndex];
            //     remainingCorrected += answer.questionCounter;
            //   }

            //   attemptQuestions = 60;
            //   correctAnswers = correctAnswers + remainingCorrected;
            //   break;
            // }
          }
        }
        if (calculationForTerminate > 60) {
          break;
        }
      }
      if (attemptQuestions >= 20) {
        if (!isDesplayProgress) {
          setIsDesplayProgress(true);
        }
        let progress = (correctAnswers / attemptQuestions) * 100;
        eachCategoryPrognos = percentageCalculation(
          progress < 0 ? 0 : progress.toFixed(1),
          categoryname
        );
        weeklyProgressArr.push({
          correctAnswers,
          attemptQuestions,
          eachCategoryPrognos,
          weekWiseCorrected,
          name: weekKey,
        });
      } else {
        weeklyProgressArr.push({
          eachCategoryPrognos: null,
          weekWiseCorrected,
          correctAnswers: correctAnswers,
          attemptQuestions: attemptQuestions,
          name: weekKey,
        });
      }
      correctAnswers = 0;
      attemptQuestions = 0;
      eachCategoryPrognos = null;
      calculationForTerminate = 0;
    });

  return weeklyProgressArr;
};

export const percentageCalculation = (prognos, categoryname) => {
  // console.log(prognos, categoryname);
  switch (categoryname) {
    case "XYZ":
      return XYZNormeringValueFor(prognos);
    case "KVA":
      return KVANormeringValueFor(prognos);
    case "NOG":
      return NOGNormeringValueFor(prognos);
    case "DTK":
      return DTKNormeringValueFor(prognos);
    case "ELF":
      return ELFNormeringValueFor(prognos);
    case "ORD":
      return ORDNormeringValueFor(prognos);
    case "MEK":
      return MEKNormeringValueFor(prognos);
    case "LÄS":
      return LASNormeringValueFor(prognos);
    default:
      break;
  }
};
export const historyOfUser = (response) => {
  const newArray = [];
  const provPassArray = [];
  response.data &&
    response.data.map((item) => {
      let obj = item ?? {};
      let totalQuestions = 0;
      let totalAnswer = 0;
      let date;

      item.simuleraQuizResult.map((result) => {
        totalQuestions = totalQuestions + result.totalQuestions;
        totalAnswer = totalAnswer + result.correctAnswerCounter;
      });
      obj["totalQuestions"] = totalQuestions;
      obj["totalAnswer"] = totalAnswer;

      newArray.push(obj);
    });

  newArray?.map((item, index) => {
    let exist;
    let date1;
    let date2;
    let indexToInsertObj;
    let simuleraQ = {};
    if (item.simuleraSeason) {
      exist = provPassArray.some(
        (elem) => item.simuleraSeason?._id == elem.simuleraSeason?._id
      );
      if (!exist) {
        provPassArray.push(item);
      } else {
        simuleraQ = provPassArray.find(
          (ques) => item.simuleraSeason?._id == ques.simuleraSeason?._id
        );
        date1 = new Date(simuleraQ.createdAt);
        date2 = new Date(item.createdAt);
        if (date1.getTime() < date2.getTime()) {
          indexToInsertObj = provPassArray.findIndex(
            (obj) => item.simuleraSeason?._id == obj.simuleraSeason?._id
          );
          provPassArray.splice(indexToInsertObj, 1, item);
        }
      }
    } else {
      exist = provPassArray.some(
        (elem) => item.customizedExam?._id == elem.customizedExam?._id
      );
      if (!exist) {
        provPassArray.push(item);
      } else {
        simuleraQ = provPassArray.find(
          (ques) => item.customizedExam._id == ques.customizedExam?._id
        );
        date1 = new Date(simuleraQ.createdAt);
        date2 = new Date(item.createdAt);
        if (date1.getTime() < date2.getTime()) {
          indexToInsertObj = provPassArray.findIndex(
            (obj) => item.customizedExam._id == obj.customizedExam?._id
          );
          provPassArray.splice(indexToInsertObj, 1, item);
        }
      }
    }
  });
  return { newArray, provPassArray };
};

export const homePageGraphNormringCalculator = (
  weekNames,
  verbalQuantitativesevenWeeksProgress,
  allCategoriesSolvedQuizes
) => {
  // week wise groupping all categories data and devide add key for verbal and quantitative
  let weekWiseAllCategoryData = allCategoriesSolvedQuizes.map(
    (categorySolvedQuiz) => {
      const weekWisePerCategoryData = datesGroupByComponent(
        categorySolvedQuiz.perCategoryFeedBacks,
        "W"
      );
      const isQuantitative = categorySolvedQuiz.isQuantitative;
      return { weekWisePerCategoryData, isQuantitative };
    }
  );

  let weekWiseAllCategoryDataFormater = weekWiseAllCategoryData;
  weekWiseAllCategoryDataFormater = weekWiseAllCategoryDataFormater.map(
    ({ weekWisePerCategoryData, isQuantitative }) => {
      let sortable = [];
      for (var key in weekWisePerCategoryData) {
        sortable.push([key, weekWisePerCategoryData[key]]);
      }

      sortable.sort(function (a, b) {
        return new Date(a[1][0].createdAt) - new Date(b[1][0].createdAt);
      });
      let obj = new Map();
      sortable.forEach((innerArray) => {
        obj.set(innerArray[0], innerArray[1]);
      });
      return { weekWisePerCategoryData: obj, isQuantitative };
    }
  );

  // pick last hundred questions for week for every category
  const hundredQuestionsPerWeekData = [];
  for (let index = 0; index < weekWiseAllCategoryDataFormater.length; index++) {
    const { weekWisePerCategoryData, isQuantitative } =
      weekWiseAllCategoryDataFormater[index];
    const hundredQuestionsPerWeek = calculateWeekWiseNormingForHomePageGraph(
      weekWisePerCategoryData
    );
    hundredQuestionsPerWeekData.push({
      hundredQuestionsPerWeek,
      isQuantitative,
    });
  }
  // add same weeks data of verbal and quantitative categorgries
  // get normring for quantitative and calculate average of both categories.
  let perWeekVerbalCorrected = 0;
  let perWeekVerbalAttempted = 0;
  let perWeekQuantitativeCorrected = 0;
  let perWeekQuantitativeAttempted = 0;
  let previousVerbalQuantitativePerWeekNormringAverage = null;

  for (
    let weekNameIndex = 0;
    weekNameIndex < weekNames.length;
    weekNameIndex++
  ) {
    const weekNumber = weekNames[weekNameIndex];
    // for pickup same weeks data from all categories
    for (let index = 0; index < hundredQuestionsPerWeekData.length; index++) {
      const categoryWeekWiseData = hundredQuestionsPerWeekData[index];
      const categoryWeeksData =
        categoryWeekWiseData.hundredQuestionsPerWeek.find(
          (weekData) => weekData.name === weekNumber
        );
      if (!categoryWeeksData) {
        const latestAttemptedWeekData =
          categoryWeekWiseData.hundredQuestionsPerWeek?.at(-1);
        if (
          latestAttemptedWeekData &&
          !compareVersions(
            latestAttemptedWeekData.name.split(".").join(""),
            weekNumber.split(".").join("")
          )
        ) {
          if (categoryWeekWiseData.isQuantitative) {
            perWeekQuantitativeCorrected +=
              latestAttemptedWeekData.correctAnswers;
            perWeekQuantitativeAttempted +=
              latestAttemptedWeekData.attemptQuestions;
          } else {
            perWeekVerbalCorrected += latestAttemptedWeekData.correctAnswers;
            perWeekVerbalAttempted += latestAttemptedWeekData.attemptQuestions;
          }
        } else {
          for (let index = weekNameIndex; index < weekNames.length; index++) {
            // const element = categoryWeekWiseData.hundredQuestionsPerWeek[index];
            let nextValueIndex =
              categoryWeekWiseData.hundredQuestionsPerWeek.findIndex(
                (weekData) => weekData.name === weekNumber
              );
            if (nextValueIndex > -1) {
              const currentWeekPreviousData =
                categoryWeekWiseData.hundredQuestionsPerWeek[
                  nextValueIndex - 1
                ];
              if (categoryWeekWiseData.isQuantitative) {
                perWeekQuantitativeCorrected +=
                  currentWeekPreviousData.correctAnswers;
                perWeekQuantitativeAttempted +=
                  currentWeekPreviousData.attemptQuestions;
              } else {
                perWeekVerbalCorrected +=
                  currentWeekPreviousData.correctAnswers;
                perWeekVerbalAttempted +=
                  currentWeekPreviousData.attemptQuestions;
              }
            }
          }
        }
      } else {
        // add check to skip week's progress when not have done enough progress.

        if (
          categoryWeeksData.attemptQuestions > 0 &&
          categoryWeeksData.attemptQuestions < 20
        ) {
          perWeekQuantitativeAttempted = 0;
          perWeekVerbalAttempted = 0;
          break;
        }
        if (categoryWeekWiseData.isQuantitative) {
          perWeekQuantitativeCorrected += categoryWeeksData.correctAnswers;
          perWeekQuantitativeAttempted += categoryWeeksData.attemptQuestions;
        } else {
          perWeekVerbalCorrected += categoryWeeksData.correctAnswers;
          perWeekVerbalAttempted += categoryWeeksData.attemptQuestions;
        }
      }
    }

    // calculate percentage for retrive normring from the table
    let quantitativePercentageForNormring =
      perWeekQuantitativeAttempted < 1
        ? null
        : (perWeekQuantitativeCorrected / perWeekQuantitativeAttempted) * 100;
    let verbalPercentageForNormring =
      perWeekVerbalAttempted < 1
        ? null
        : (perWeekVerbalCorrected / perWeekVerbalAttempted) * 100;

    // getting normring values from verbal normring tables
    const verbalNormring = verbalPercentageForNormring
      ? verbalPercentageCalculator(verbalPercentageForNormring.toFixed(2))
      : verbalPercentageForNormring;

    // getting normring values from quantitative normring tables
    const quantitativeNormring = quantitativePercentageForNormring
      ? quantitativePercentageCalculator(
          quantitativePercentageForNormring.toFixed(2)
        )
      : quantitativePercentageForNormring;

    // Average of quantitative and verbal
    let verbalQuantitativePerWeekNormringAverage = null;
    if (verbalNormring || quantitativeNormring) {
      if (!verbalNormring) {
        verbalQuantitativePerWeekNormringAverage = quantitativeNormring / 2;
      } else if (!quantitativeNormring) {
        verbalQuantitativePerWeekNormringAverage = verbalNormring / 2;
      } else {
        verbalQuantitativePerWeekNormringAverage =
          (verbalNormring + quantitativeNormring) / 2;
      }
    } else if (verbalNormring === 0 && quantitativeNormring === 0) {
      verbalQuantitativePerWeekNormringAverage = 0;
    } else if (verbalQuantitativesevenWeeksProgress.length > 0) {
      verbalQuantitativePerWeekNormringAverage =
        previousVerbalQuantitativePerWeekNormringAverage;
    }

    previousVerbalQuantitativePerWeekNormringAverage =
      verbalQuantitativePerWeekNormringAverage;
    verbalQuantitativesevenWeeksProgress.push({
      quantitativeNormring,
      verbalNormring,
      verbalPercentageForNormring,
      quantitativePercentageForNormring,
      Prognos: verbalQuantitativePerWeekNormringAverage
        ? verbalQuantitativePerWeekNormringAverage.toFixed(2)
        : verbalQuantitativePerWeekNormringAverage,
      name: weekNumber,
    });

    perWeekQuantitativeCorrected = 0;
    perWeekQuantitativeAttempted = 0;
    perWeekVerbalCorrected = 0;
    perWeekVerbalAttempted = 0;
  }
};

export const correctedPerCategory = (weeklyCorrectedPerCategory, response) => {
  const categoriesInfo = response;
  const weeklyCorrectedOfCategory = weeklyCorrectedPerCategory;
  return categoriesInfo.map((categoryObj) => {
    let categoriesData = weeklyCorrectedOfCategory.find(
      (category) => category._id.toString() === categoryObj._id.toString()
    );

    if (!categoriesData) {
      categoriesData = {
        correctedQuestionPerCategory: 0,
        correctedUnderSixtyQuestions: 0,
        attemptedUnderSixtyQuestions: 0,
      };
    }
    const { _id, ...rest } = categoriesData;
    return { ...categoryObj, ...rest };
  });
};

export const testNormeringData = () => {
  const URL = EndPoints.simuleraQuizHistorytest + "633c29b5cc08e607fe3dcd23";
  return instance2
    .get(URL, {
      headers: {
        Authorization:
          "bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImZhaXpAZ21haWwuY29tIiwiX2lkIjoiNjIwMjc5NGI3MDU5MDU0NzQ0M2FmNDYyIiwicm9sZSI6InN0dWRlbnQiLCJpYXQiOjE2NTM4OTA0NzgsImV4cCI6NTI1Mzg5MDQ3OH0.4nBHZvHuCANYGgnOz_4AnBgajnflTQX7UDr_Sx1zTFU",
      },
    })
    .then((response) => {
      let newArray = [];
      let provPassArray = [];
      historyOfUser(response, newArray, provPassArray);
      return response.data.map((response, index) => ({
        createdAt: response.createdAt,
        kvantNormering: response.kvantNormering,
        normering: response.normering,
        updatedAt: response.updatedAt,
        user: response.user,
        verbNormering: response.verbNormering,
        totalAnswer: newArray[index].totalAnswer,
        totalQuestions: newArray[index].totalQuestions,
        __v: response.__v,
        _id: response._id,
      }));
    })
    .catch(handleErrors);
};

function checkAllExists(dbArray, idsArray) {
  return dbArray.every((categoryData) => idsArray.includes(categoryData._id));
}

export const verbalQuantitativePerWeekNormring = (
  sevenWeeksMetricsOfCategoryPerWeek
) => {
  const sevenWeeksMetricsOfCategoryPerWeekSort =
    sevenWeeksMetricsOfCategoryPerWeek.reverse();
  let verbalQuantitativePerWeekNormringCategories = [];
  for (
    let index = 0;
    index < sevenWeeksMetricsOfCategoryPerWeekSort.length;
    index++
  ) {
    const { weeklyCorrectedPerCategory, weekName } =
      sevenWeeksMetricsOfCategoryPerWeekSort[index];

    let verbalCorrected = 0;
    let verbalAttempted = 0;
    let quantCorrected = 0;
    let quantAttempted = 0;
    let isAllAttemptedLessThenTwenty =
      weeklyCorrectedPerCategory.length > 0
        ? weeklyCorrectedPerCategory.every(
            (categoryObj) => categoryObj.attemptedUnderSixtyQuestions >= 20
          )
        : false;

    for (
      let indexPW = 0;
      indexPW < weeklyCorrectedPerCategory.length;
      indexPW++
    ) {
      const categoryData = weeklyCorrectedPerCategory[indexPW];

      if (
        [
          "62627d917a4f7b3068eaa811",
          "62627db77a4f7b3068eaa81b",
          "62627da87a4f7b3068eaa816",
          "62627dc17a4f7b3068eaa820",
        ].includes(categoryData._id)
      ) {
        quantAttempted += categoryData.attemptedUnderSixtyQuestions;
        quantCorrected += categoryData.correctedUnderSixtyQuestions;
      } else {
        verbalAttempted += categoryData.attemptedUnderSixtyQuestions;
        verbalCorrected += categoryData.correctedUnderSixtyQuestions;
      }
    }

    let quantitativePercentageForNormring =
      (quantCorrected / quantAttempted) * 100;
    let verbalPercentageForNormring = (verbalCorrected / verbalAttempted) * 100;

    // getting normring values from verbal normring tables
    const verbalNormring = verbalPercentageCalculator(
      verbalPercentageForNormring.toFixed(2)
    );

    // getting normring values from quantitative normring tables
    const quantitativeNormring = quantitativePercentageCalculator(
      quantitativePercentageForNormring.toFixed(2)
    );

    verbalQuantitativePerWeekNormringCategories.push({
      name: weekName,
      Prognos: isAllAttemptedLessThenTwenty
        ? (verbalNormring + quantitativeNormring) / 2
        : null,
      verbalNormring,
      quantitativeNormring,
      quantitativePercentageForNormring,
      verbalPercentageForNormring,
    });
  }

  return verbalQuantitativePerWeekNormringCategories;
};

export const verbalQuantitativeLastWeekNormring = (
  weeklyCorrectedPerCategory
) => {
  let verbalCorrected = 0;
  let verbalAttempted = 0;
  let quantCorrected = 0;
  let quantAttempted = 0;
  let isAllAttemptedLessThenTwenty =
    weeklyCorrectedPerCategory.length > 0
      ? weeklyCorrectedPerCategory.every(
          (categoryObj) => categoryObj.attemptedUnderSixtyQuestions >= 20
        )
      : false;

  for (
    let indexPW = 0;
    indexPW < weeklyCorrectedPerCategory.length;
    indexPW++
  ) {
    const categoryData = weeklyCorrectedPerCategory[indexPW];

    if (
      [
        "62627d917a4f7b3068eaa811",
        "62627db77a4f7b3068eaa81b",
        "62627da87a4f7b3068eaa816",
        "62627dc17a4f7b3068eaa820",
      ].includes(categoryData._id.toString())
    ) {
      quantAttempted += categoryData.attemptedUnderSixtyQuestions;
      quantCorrected += categoryData.correctedUnderSixtyQuestions;
    } else {
      verbalAttempted += categoryData.attemptedUnderSixtyQuestions;
      verbalCorrected += categoryData.correctedUnderSixtyQuestions;
    }
  }

  let quantitativePercentageForNormring =
    (quantCorrected / quantAttempted) * 100;
  let verbalPercentageForNormring = (verbalCorrected / verbalAttempted) * 100;

  // getting normring values from verbal normring tables
  const verbalNormring = verbalPercentageCalculator(
    verbalPercentageForNormring.toFixed(2)
  );

  // getting normring values from quantitative normring tables
  const quantitativeNormring = quantitativePercentageCalculator(
    quantitativePercentageForNormring.toFixed(2)
  );

  return {
    Prognos: isAllAttemptedLessThenTwenty
      ? (verbalNormring + quantitativeNormring) / 2
      : null,
    verbalNormring,
    quantitativeNormring,
  };
};
