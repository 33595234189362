/** Libraries */
import { Box } from "@material-ui/core";
import { styled } from "@mui/material/styles";

/** Styles */
export const MainContainer = styled("div")(({ theme }) => ({
  width: "100%",
}));

export const ImageOptionsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  minHeight: "352px",
  flexWrap: "wrap",
  alignItems: "center",
  flexShrink: 0,
  boxSizing: "border-box",
  [theme.breakpoints.down("md")]: {
    padding: 0,
  },
  [theme.breakpoints.down(600)]: {
    flexWrap: "no-wrap",
    flexDirection: "column",
    width: "100%",
    heigth: "176px",
    flexShrink: 0,
  },
}));

export const RowOptionsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width: "100%",
  flexShrink: 0,
  boxSizing: "border-box",
}));
