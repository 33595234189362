/** Libraries */
import React, { useRef, useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Box, Container } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { setOrderId } from "../../../redux/slices/time/timeSlice";
import PricingPlan from "./PricingPlans";

/** Organisms */
import DiscountForm from "../Discount/DiscountForm";

/** Molecules */
import MobileMenu from "../../molecule/MobileMenu/MobileMenu";

/** Atoms */
import { PricingReviewCards } from "../../atom/PricingReviewCards/PricingReviewCards";
import { CustomDialog } from "../HomeOrg/HomeRightBar/HomeRightBar.styles";
import { StyledCloseIconRight } from "../../atom/CustomIcons/StyledCloseIcon";
import { ErrorPopup } from "../../atom/ErrorPopup/ErrorPopup";
import { GuaranteePopup } from "./GuaranteePopup";

/** Components */
import { EndPoints, instance2 } from "../../service/Route";

/** Utils */
import { appColors } from "../../../utils/commonService";
import config from "../../../config/config";
import { update } from "../../../redux/slices";
import { SendSentryError } from "../../../tools";

/** Hooks */
import { useDiscountStore, useMixpanel, useUiStore } from "../../../hooks";
import { useMemo } from "react";

require("dotenv").config();

/** Material UI - Custom elements */
const MainContainer = styled(Container)(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: "60px 30px",
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "1rem 0rem",
}));

const TitleText = styled("h1")(({ theme }) => ({
  fontSize: "28px",
  fontWeight: "600",
  textAlign: "center",
  [theme.breakpoints.down(600)]: {
    fontSize: "20px",
  },
}));

const SubtitleText = styled("p")(({ theme }) => ({
  fontSize: "16px",
  maxWidth: "572px",
  textAlign: "center",
}));

const SubtitleText2 = styled("p")(({ theme }) => ({
  fontSize: "14px",
  textAlign: "center",
  fontFamily: "Figtree",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
  color: appColors.grey35,
  [theme.breakpoints.down("md")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
}));

const KlarnaLabel = styled("h1")(({ theme }) => ({
  paddingTop: "20px",
  paddingBottom: "20px",
  fontSize: "32px",
  fontWeight: "700",
}));

const BodyContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: "2rem",
}));

const Pricing = () => {
  const [selectedPlanDetails, setSelectedPlanDetails] = useState(null);
  //set price according to plan and pass to Klarna
  const [htmlSnippet, setHtmlSnippet] = useState();
  const [initiateKlarna, setInitiate] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const { startErrorPopup, startGuaranteePopup, guaranteePopup } = useUiStore();
  const checkoutContainer = useRef(null);
  const { user } = useSelector((state) => state.user);
  const isPartialPlan = useMemo(() => {
    if (user?.areVerbalFeaturesEnabled && !user?.areKvantiativFeaturesEnabled) {
      return true;
    }
    if (!user?.areVerbalFeaturesEnabled && user?.areKvantiativFeaturesEnabled) {
      return true;
    }
    return false;
  }, [user?._id]);
  const isPremium =
    user?.areVerbalFeaturesEnabled && user?.areKvantiativFeaturesEnabled;
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const theme = useTheme();
  const { discount } = useDiscountStore();

  const isDiscountEmpty = Object.keys(discount).length === 0;

  const { code, discountAmount } = isDiscountEmpty ? {} : discount;

  useEffect(() => {
    mixpanel.pageVisited("Checkout");
  }, []);

  const goPayment = (planDetails, sectionToBuy) => {
    setSelectedPlanDetails(planDetails);
    setInitiate(true);
    setDisableButton(true);
    //This data goes to Klarna API
    let quantity = 1;
    let unit_price = planDetails.price * 100;
    let total_amount = quantity * unit_price;
    let tax_rate = 2500;
    let total_tax_amount =
      total_amount - (total_amount * 10000) / (10000 + tax_rate);

    const orderData = JSON.stringify({
      purchase_country: "SE",
      purchase_currency: "SEK",
      locale: "sv-se",
      order_amount: total_amount,
      order_tax_amount: total_tax_amount,
      order_lines: [
        {
          type: "digital",
          name: "Premium medlemskap: " + planDetails.premiumLength + " månader",
          quantity: quantity,
          quantity_unit: "pcs",
          unit_price: unit_price,
          tax_rate: tax_rate,
          total_amount: total_amount,
          total_discount_amount: 0,
          total_tax_amount: total_tax_amount,
        },
      ],
      merchant_urls: {
        terms: "https://www.hpappen.se/villkoren",
        checkout: config.REACT_APP_BASE_URL + "/checkout",
        confirmation: config.REACT_APP_BASE_URL + "/payment-confirmation",
        push:
          config.REACT_APP_API_BASE_URL +
          `/api/payments/notifications/${localStorage.getItem("token")}`, //We need to respond to Klarna with a 200 status.
      },
      merchant_reference1: `UTM: ${user.utm} - ${code || ""}`,
      merchant_reference2: `App email: ${user.email}`,
    });

    const url = EndPoints.createOrder;

    instance2
      .post(url, {
        orderData,
        isGroupOrder:
          planDetails?.isGroupOrder ||
          planDetails?.id === "planTwo" ||
          planDetails?.id === "payingFullFromPartial",
        isDiscountEnabled: !isDiscountEmpty,
        sectionsToBuy:
          planDetails?.id === "planThree"
            ? [sectionToBuy]
            : ["kvantitativ", "verbal"],
      })
      .then((res) => {
        setHtmlSnippet(res.data.orderData.html_snippet);
        checkoutContainer.current.innerHTML = res.data.orderData.html_snippet;
        SendSentryError("PAYCARD", "Checkout order creating Success!", {
          details: `Checkout order creating Success! 
          User Detail: name${res?.data?.orderData?.order_lines[0]?.name}. OrderId: ${res.data.orderData.order_id}`,
        });
        console.log(res.data.orderData.order_id); // Pass this order_id to user - use localStorage or database?
        dispatch(setOrderId(res.data.orderData.order_id));
        dispatch(update({ order_id: res.data.orderData.order_id }));

        const scriptsTags =
          checkoutContainer.current.getElementsByTagName("script");
        // This is necessary otherwise the scripts tags are not going to be evaluated
        for (var i = 0; i < scriptsTags.length; i++) {
          const parentNode = scriptsTags[i].parentNode;
          const newScriptTag = document.createElement("script");
          newScriptTag.type = "text/javascript";
          newScriptTag.text = scriptsTags[i].text;
          parentNode.removeChild(scriptsTags[i]);
          parentNode.appendChild(newScriptTag);
        }
      })
      .catch((err) => {
        console.log("Checkout order creating failed");
        console.log(err);
        const message = err?.response?.data?.error?.message;
        SendSentryError("PAYCARD", "Checkout order creating failed!", {
          details: message,
        });
        startErrorPopup();
      })
      .finally(() => {
        setDisableButton(false);
      });
  };

  const scrollDown = () => {
    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    }, 1000);
  };

  return (
    <MainContainer>
      <MobileMenu />
      <CustomDialog
        open={guaranteePopup}
        onClose={() => startGuaranteePopup()}
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: theme.palette.appcolors.popupOverlay,
              backdropFilter: "blur(2px)",
            },
          },
        }}
        PaperProps={{
          sx: {
            borderRadius: "16px",
            maxWidth: "1230px !important",
            maxHeight: "908px",
            width: "100%",
            height: "100%",
            backgroundColor: theme.palette.appcolors.popupOverlay,
            scrollbarColor: `${theme.palette.appcolors.grey3} ${theme.palette.appcolors.secondaryColor2}`,
            scrollbarWidth: "thin",
          },
        }}
      >
        <StyledCloseIconRight onClick={() => startGuaranteePopup()} />
        <GuaranteePopup />
      </CustomDialog>
      <HeaderContainer>
        <TitleText>Nå din drömutbildning</TitleText>
        <SubtitleText>
          Premium tar dig igenom hela resan till högskoleprovet, från start till
          mål. Uppgradera och plugga med HP-appens unika resultatgaranti.
        </SubtitleText>
        <PricingReviewCards />
      </HeaderContainer>

      <BodyContainer>
        <PricingPlan
          defaultPlan="planTwo"
          initiatePayment={initiateKlarna}
          price={selectedPlanDetails?.price}
          setHtmlSnippet={setHtmlSnippet}
          checkoutRef={checkoutContainer}
          disablePaymentButton={disableButton}
          goPayment={(planDetails, sectionToBuy) => {
            // if (!initiateKlarna) {
            goPayment(planDetails, sectionToBuy);
            // }
          }}
        />

        {htmlSnippet && scrollDown()}
        {!initiateKlarna && !isPartialPlan && <DiscountForm />}

        <div
          style={{
            width: "100%",
            minWidth: "25rem",
            marginTop: "2rem",
          }}
          ref={checkoutContainer}
        />

        <KlarnaLabel>{initiateKlarna ? "" : "Klarna."}</KlarnaLabel>

        <>
          {!isPremium && (
            <SubtitleText2 style={{ paddingBottom: "10px" }}>
              Uppgradering ger tillgång till HP-appen Premium under en period på
              12 månader från köptillfället.
            </SubtitleText2>
          )}
          <SubtitleText2>
            Klarna delbetalning tillgänglig i nästa steg.
          </SubtitleText2>
          <SubtitleText2>
            *Din vän får ett eget premiumkonto. Vidare instruktioner skickas via
            mail efter uppgradering.
          </SubtitleText2>
        </>
      </BodyContainer>
    </MainContainer>
  );
};

export default Pricing;
