/** Libraries */
import { Box, Container } from "@mui/material";
import { styled } from "@mui/material/styles";

/** Material UI - Custom elements */
export const MainContainer = styled(Box)(({ theme }) => ({
  position: "sticky",
  top: 114,
  right: 0,
  width: "500px",
  height: "500px",
  [theme.breakpoints.down(1200)]: {
    width: "100%",
  },
}));

export const DescriptionQuestionContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "flex-start",
  alignSelf: "stretch",
  padding: "22px 16px 22px 25px",
  gap: "10px",

  [theme.breakpoints.down(1200)]: {
    minHeight: "104px",
  },
  [theme.breakpoints.down(600)]: {
    padding: "15px",
    gap: "0px",
    minHeight: "119px",
  },
}));

export const StyledOptionsContainer = styled(Container)(({ theme }) => ({
  width: "100%",
  display: "flex !important",
  flexWrap: "wrap",
}));
