/** Libraries */
import React from "react";

import { Box, FormControlLabel, Radio, Typography } from "@material-ui/core";

import { styled } from "@mui/material/styles";

/** Atoms */
import {
  StyledCorrectIcon,
  StyledWrongIcon,
  StyledRadiusUnCheckedIcon,
} from "../../atom/RadioIcons/RadioIcons";

/** Utils */
import { appColors, optionsCharacters } from "../../../utils/commonService";

/** Material UI - Custom elements */
const RowRadiusButtonFirstContainer = styled(Box)(({ theme }) => ({
  width: "30px",
  height: "30px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  gap: "22.5px",
}));

const RowRadiusButtonSecondContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
}));

const RowRadius = styled(FormControlLabel)(({ theme }) => ({
  width: "16px",
  height: "16px",
  margin: 0,
  padding: 0,
  "&.MuiFormControlLabel-root": {
    margin: 0,
  },
}));

const RowRadiusIndexContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  height: "32.5px",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "end",
}));

const RadiusIndexText = styled(Typography)(({ theme }) => ({
  "&.MuiTypography-root": {
    fontFamily: "Poppins",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    textTransform: "uppercase",
  },
}));

const Options = (option, question, optionIndex, isCorrect, isWrong) => {
  if (isWrong) {
    return <Radio disabled checked={false} icon={<StyledWrongIcon />} />;
  } else if (isCorrect) {
    return <Radio disabled checked={false} icon={<StyledCorrectIcon />} />;
  } else if (question.answer && option._id !== question?.optionId) {
    return (
      <Radio disabled checked={false} icon={<StyledRadiusUnCheckedIcon />} />
    );
  }
  if (optionIndex == question.selectedIndex) {
    return (
      <Radio color="primary" checked={true} style={{ marginBottom: ".5rem" }} />
    );
  } else {
    return <Radio checked={false} icon={<StyledRadiusUnCheckedIcon />} />;
  }
};

const handleTextColor = (isCorrect, isWrong) => {
  if (isCorrect) {
    return appColors.darkMintGreen45;
  }
  if (isWrong) {
    return appColors.valentine_red;
  }

  return appColors.grey10;
};

const RadioButtonOptions = ({ option, question, optionIndex }) => {
  const isCorrect = question.answer && option._id === question.answer.option;
  const isWrong =
    question.answer &&
    question.answer.option !== option._id &&
    question.optionId === option._id;

  return (
    <RowRadiusButtonFirstContainer>
      <RowRadiusButtonSecondContainer>
        <RowRadius
          value={option?._id}
          control={Options(option, question, optionIndex, isCorrect, isWrong)}
        />
      </RowRadiusButtonSecondContainer>
      <RowRadiusIndexContainer>
        <RadiusIndexText
          sx={{
            color: handleTextColor(isCorrect, isWrong),
          }}
        >
          {optionsCharacters(optionIndex)}
        </RadiusIndexText>
      </RowRadiusIndexContainer>
    </RowRadiusButtonFirstContainer>
  );
};

export default RadioButtonOptions;
