/** Libraries */
import { Container } from "@mui/material";
import { styled } from "@mui/material/styles";

/** Material UI - Custom elements */
export const MQTemplateContainer = styled("div")(({ theme }) => ({
  width: "100%",
  minHeight: "100vh",
  height: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  boxSizing: "border-box",
  backgroundColor: theme.palette.appcolors.secondaryColor3,
}));

export const TopbarContainer = styled("div")(({ theme }) => ({
  position: "sticky",
  top: 0,
  left: 0,
  width: "100%",
  height: "fit-content",
  zIndex: 1000,
}));

export const BannerContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "fit-content",
}));
export const BodyContainer = styled(Container)(({ theme }) => ({
  "&&": {
    maxWidth: "100%",
    width: "100%",
    height: "auto",
    marginBottom: "70px",
    padding: "0px 32px 0px 32px",
    display: "flex !important",
    flexDirection: "row",
    alignItems: "flex-start",

    [theme.breakpoints.down(1200)]: {
      flexDirection: "column",
      padding: "0px 16px 0px 16px",
    },
    [theme.breakpoints.up(1200)]: {
      justifyContent: "center",
      gap: "72px",
    },
    [theme.breakpoints.down(900)]: {
      justifyContent: "center",
      marginTop: "28px",
    },
    [theme.breakpoints.down(600)]: {
      marginTop: "5px",
      gap: "5px",
    },
  },
}));

export const DialogContainer = styled("div")(
  ({ theme, nonAnsweredQuestion }) => ({
    display: "flex",
    gap: "18px",
    height: "auto",
    justifyContent: "flex-start",
    flexDirection: "column",
    width: "900px",
    marginTop: nonAnsweredQuestion ? "1rem" : 0,
    [theme.breakpoints.down(1200)]: {
      width: "100%",
      fontFamily: "'Noto Sans', sans-serif",
      height: "fit-content",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
    },
  })
);

export const QuestionsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  gap: "18px",
  height: "auto",
  justifyContent: "center",
  flexDirection: "row",
  [theme.breakpoints.down(1200)]: {
    marginTop: "2.5rem",
    fontFamily: "'Noto Sans', sans-serif",
    height: "fit-content",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
}));
