/** Libraries */
import { Box, Button, Typography } from "@mui/material";

import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import { appColors } from "../../../../../utils/commonService";

const handleAnswerBorder = (isCorrect) => {
  if (isCorrect) {
    return `3px solid ${appColors.darkMintGreen45}`;
  } else {
    return `3px solid ${appColors.valentine_red}`;
  }
};

/** Material UI - Custom elements */
export const MainContainer = styled(Box)(({ theme }) => ({
  position: "sticky",
  top: 114,
  right: 0,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "466px",
  minWidth: "466px",
  height: "min-content",
  gap: "18px",
  [theme.breakpoints.up(1400)]: {
    maxWidth: "466px",
  },
  [theme.breakpoints.down(1200)]: {
    minWidth: "100%",
    marginTop: "30px",
  },
  [theme.breakpoints.down(600)]: {
    minWidth: "100%",
    width: "100%",
  },
}));

export const AnswerAndMultiQuestionsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
}));

export const StyledAnswersReviewContainer = styled(Box)(({ theme }) => ({
  "&&": {
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    gap: "18px",
  },
}));

export const AnswerCard = styled(Box)(({ theme, iscorrect }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-end",
  padding: "20px 20px 0px 20px",
  alignSelf: "strech",
  backgroundColor: "#fff",
  borderRadius: "5px",
  cursor: "pointer",
  border: handleAnswerBorder(iscorrect),
  zIndex: 3000,
}));

export const AnswerAndImageContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  minHeight: "80px",
  display: "flex",
  paddingBottom: "0px",
  alignItems: "center",
  gap: "16px",
  flex: "1 0 0",
}));

export const TextAndButtonImageContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  paddingBottom: "0px",
}));

export const ResultImage = styled("div")(({ theme }) => ({
  [theme.breakpoints.down(600)]: {
    display: "none",
  },
}));

export const TextContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "6px",
  flex: "1 0 0",
}));

export const UppgiftText = styled(Typography)(({ theme }) => ({
  alignSelf: "stretch",
  "&.MuiTypography-root": {
    color: appColors.blackColor,
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Noto Sans",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "14px",
    letterSpacing: "0.96px",
    textTransform: "capitalize",
  },
  [theme.breakpoints.down(600)]: {
    fontSize: "16px",
    letterSpacing: "1.28px",
  },
}));

export const ButtonImageContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
}));

/** Styles */
export const useStyles = makeStyles((theme) => ({
  AnswerCardText: {
    color: appColors.blackColor,
    fontFamily: "Noto Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "30px",
    "& img": {
      maxWidth: "100%",
      minWidth: "141px",
      minHeight: "127px",
      objectFit: "contain",
    },
    [theme.breakpoints.down(900)]: {
      lineHeight: "25px",
    },
    [theme.breakpoints.down(600)]: {
      fontSize: "18px",
      lineHeight: "25px",
    },
  },
}));

export const NavigationButtonsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "10px",
}));

export const NavigationButtonText = styled("p")(({ theme }) => ({
  textAlign: "center",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
}));

export const StyledExamButton = styled(Button)(({ theme, disabled, mq }) => ({
  "&.MuiButtonBase-root": {
    border: `1px solid ${
      disabled
        ? theme.palette.appcolors.grey8
        : theme.palette.appcolors.primaryColor
    }`,
    backgroundColor: disabled ? theme.palette.appcolors.grey3 : "transparent",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "none",
    height: "46px",
    width: "100%",
    color: disabled
      ? theme.palette.appcolors.grey7
      : theme.palette.appcolors.primaryColor,
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    marginBottom: mq && "20px",
    [theme.breakpoints.down(1200)]: {
      marginBottom: mq && "0rem",
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));
