import { useEffect } from "react";

/** Libraries */
import { useDispatch, useSelector } from "react-redux";

/** Slices */
import { setDiscount, validateDiscountCode } from "../../redux/slices";

/** Axios - endpoint */
import { EndPoints, instance2 } from "../../components/service/Route";

/** Utils */
import { handleErrors } from "../../utils/Errors";

export const useDiscountStore = () => {
  const dispatch = useDispatch();
  const { discount, isCodeValid } = useSelector((state) => state.discount);
  const { token: storedToken } = useSelector((state) => state.user);

  const validateCode = (code, token) => {
    const headers = {
      Authorization: `Bearer ${storedToken || token}`,
      "Content-Type": "application/json",
    };

    const validateCodeEndpoint = EndPoints.validateCode;

    const body = {
      code: code.toUpperCase(),
    };

    console.log("Validating...");

    instance2
      .post(validateCodeEndpoint, body, { headers })
      .then((response) => {
        const discountObject = response.data.discount;
        // validate
        console.log("Code validation:", response.data.success);
        dispatch(validateDiscountCode(response.data.success));
        // set Discount Code
        if (response.data.success) {
          dispatch(setDiscount(discountObject));
          console.log("Discount applied!");
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "test") {
          console.log("invalid discount code!");
          console.error("API Request Error", error);
        }
        // invalidate
        dispatch(validateDiscountCode(false));
        handleErrors(error);
      });
  };

  return {
    /* Values */
    discount,
    isCodeValid,
    /* Functions */
    validateCode,
  };
};
