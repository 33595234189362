/** Libraries */
import React, { useEffect, useRef, useState } from "react";

/** Molecules */
import NoneAnsweredAlert from "../../../components/molecule/Alerts/NoneAnsweredAlert/NoneAnsweredAlert";

/** Organisms */
import PublicTestTopBar from "../../../components/organism/TopBars/ExerciseTopBar/PublicTestTopBar";
import { MQDescription, MQQuestion } from "../../../components/organism/Quiz";
import MQQuestionReview from "../../../components/organism/Quiz/MQQuestion/MQQuestionReview/MQQuestionReview";
import CustomExamsQuestionMQ from "./CustomExamsQuestionMQ";
/** Templates */
import { MQTemplate } from "../../../components/template";

/** Custom hooks */
import { useCustomExamsSQStore } from "../../../hooks/customExams/SingleQuestions/useCustomExamsSQStore";

// import { useCustomExamsMQStore } from "../../../hooks/customExams/MultiQuestions/useCustomExamsMQStore";
import { ConnectingAirportsOutlined } from "@mui/icons-material";
import ExamTopBar from "../../../components/organism/TopBars/ExamTopBar/ExamTopBar";
import CEMQTemplate from "../../../components/template/QuizTemplates/MQTemplate/CEMQTemplate";

import SubmitButton from "../../PublicTest/PublicTestSQ/ui/SubmitButton/SubmitButton";
import { appColors } from "../../../utils/commonService";
import { Box } from "@mui/material";
import { StyledExamButton } from "../../../components/atom/Button/Button.styles";
import { NavigationButtonText } from "../CustomExamsSQ/CustomExamsSQ.styles";
import { PressEnterIcon } from "../../../components/atom/PressEnter/PressEnter";
import CommonPopup from "../../../components/molecule/CommonPopup/CommonPopup";
import { useNavigate } from "react-router-dom";
import AppLoader from "../../../components/molecule/AppLoader";
import { useExamStore } from "../../../hooks";
import CuExOverview from "../../../components/organism/CustomizedExam/CuExOverview/CuExOverview";
import { useSelector } from "react-redux";

const useEnterKey = (callback, shouldActivate) => {
  useEffect(() => {
    if (!shouldActivate) return;

    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        callback();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [callback, shouldActivate]);
};

const CustomExamsMQ = ({
  saveExamSession,
  handleLamnaIn,
  lamnaInhandIn,
  setBackPressPopup,
  backPressPopup,
  time,
  isCustomExam,
  examNumber,
  setOverview,
  overview,
  setIsLoading,
  loading,
  checkLastAttempted,
  timeOverPopUp,
  setTimeOverPopUp,
}) => {
  const {
    quiz,
    currentQuestion,
    selectedIndex,
    selectedOption,
    totalQuestions,
    isAnswerVisible,
    isReviewSection,
    topBar,
    categoryTitle,
    startSettingSelectedAnswerCustomExamSQ,
    startSettingAnswerVisibleCustomExamSQ,
    startNextQuestionCustomExamSQ,
    startPreviousQuestionCustomExamSQ,
    startSpecificQuestionCustomExamSQ,
    startSettingCustomExamSQ,
    startNavigatingResultPageInReview,
    startClosingQuizCustomExamSQ,
    startSettingNextQuestionInReviewCustomExamSQ,
    startSettingPreviusQuestionInReviewCustomExamSQ,
    handleLastQuestion,
  } = useCustomExamsSQStore();

  const navigate = useNavigate();
  const sectionCategory = currentQuestion?.sectionCategories?.title;
  const { startLoadingExamsData } = useExamStore();

  const handleNavigation = () => {
    navigate("/courses");
    startLoadingExamsData();
  };

  const { disabledTimer } = useSelector((state) => state.timeCorrection);

  useEnterKey(
    startNextQuestionCustomExamSQ,
    selectedIndex + 1 < totalQuestions
  );
  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     // Your custom function to execute

  //     // Prompt the user with a confirmation dialog
  //     event.preventDefault();
  //     // Chrome requires returnValue to be set
  //     event.returnValue = "";
  //   };

  //   // Add the event listener
  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   // Cleanup the event listener on component unmount
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  if (currentQuestion === null) return null;

  return (
    <CEMQTemplate
      loader={<AppLoader isLoading={loading} />}
      submitPopup={
        <CommonPopup
          status={lamnaInhandIn}
          closePopup={() => handleLamnaIn(false)}
          redirect={saveExamSession}
          title="Vill du lämna in?"
          description={`${
            checkLastAttempted
              ? "Efter att du har lämnat in detta provpass provet klart."
              : "Efter att du har lämnat in kan du ta en paus innan du  påbörjar nästa provpass! Ditt resultat kommer sparas."
          }`}
          oneButtonPopup
          agreeBtnName="Lämna in provpasset"
        />
      }
      timeOverPopup={
        <CommonPopup
          status={timeOverPopUp}
          closePopup={() => setTimeOverPopUp(false)}
          redirect={saveExamSession}
          title="Provpasset är klart."
          description={`${
            checkLastAttempted
              ? "Efter att du har lämnat in detta provpass är provet klart."
              : "Efter att du har lämnat in kan du ta en paus innan du  påbörjar nästa provpass! Ditt resultat kommer sparas."
          }`}
          oneButtonPopup
          agreeBtnName="Lämna in provpasset"
        />
      }
      quitPopup={
        <CommonPopup
          status={backPressPopup}
          closePopup={() => setBackPressPopup(false)}
          title="Vill du avsluta provpasset?"
          description="Du måste göra klart provpasset för att få din poäng. Om du trycker
        på avsluta, sparas inte dina svar."
          cancelBtnName="Gör klart provpass"
          agreeBtnName="Avsluta prov"
          redirect={handleNavigation}
        />
      }
      overViewPopup={
        <CuExOverview
          quiz={quiz}
          setCurrentIndex={startSpecificQuestionCustomExamSQ}
          // timeLeft={currTime}
          // params={params}
          open={overview}
          handleStatus={setOverview}
          handleLamnaIn={() => handleLamnaIn(true)}
          // isResultSection={
          //   quiz?.question[currentIndex]?.questionAnswer ? true : false
          // }
          handleNavigationResultPage={handleNavigation}
        />
      }
      topBar={
        <ExamTopBar
          progress={!isReviewSection ? topBar.progress : 100}
          isCustomExam={isCustomExam}
          // quiz={quiz}
          currentIndex={selectedIndex}
          categoryTitle={categoryTitle}
          totalQuestions={totalQuestions}
          handleOpenOversikt={() => setOverview(true)}
          // goBack={startClosingQuizCustomExamSQ}
          setBackPressPopup={setBackPressPopup}
          isReviewSection={isReviewSection}
          handleLamnaIn={() => handleLamnaIn(true)}
          time={disabledTimer ? undefined : time}
          examNumber={examNumber}
          sectionCategory={sectionCategory}
        />
      }
      nonAnsweredAlert={
        currentQuestion[0]?.optionId._id === null &&
        isReviewSection && <NoneAnsweredAlert />
      }
      dialogBox={
        <MQDescription
          description={currentQuestion?.multipartQuestion?.description}
          sectionTitle={categoryTitle}
          currNumberOfQuestions={1}
        />
      }
      questions={
        <>
          {/* {!isAnswerVisible ? ( */}
          <CustomExamsQuestionMQ
            question={currentQuestion}
            sectionCategory={categoryTitle || "No category"}
            selectedCurrMQIndex={0}
            handleSelectOption={startSettingSelectedAnswerCustomExamSQ}
            // handlePagination={startSettingSelectedCurrCustomExamMQIndex}
            // handleSubmitQuestions={startSettingAnswerVisibleCustomExamMQ}
            // enableSubmitButton={currentQuestion.optionId !== undefined} // || false
            isAnswerVisible={isAnswerVisible || false}
            primaryButton={
              <StyledExamButton
                mq
                disabled={selectedIndex === 0}
                onClick={startPreviousQuestionCustomExamSQ}
              >
                Föregående
              </StyledExamButton>
            }
            secondaryButton={
              selectedIndex + 1 === totalQuestions ? (
                <StyledExamButton
                  onClick={() => {
                    handleLamnaIn(true);
                    handleLastQuestion();
                  }}
                >
                  <NavigationButtonText>Lämna in</NavigationButtonText>
                </StyledExamButton>
              ) : (
                <Box
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    flexDirection: "column",
                  }}
                >
                  <StyledExamButton onClick={startNextQuestionCustomExamSQ}>
                    <NavigationButtonText>Nästa</NavigationButtonText>
                  </StyledExamButton>
                  <PressEnterIcon />
                </Box>
              )
            }
          />
          {/* ) : (
            <MQQuestionReview
              isResultPage={isReviewSection}
              sectionTitle={categoryTitle}
              questions={currentQuestion}
              selectedIndex={selectedIndex}
              totalQuestions={quiz.length}
              handleResultButton={startNavigatingResultPageInReview}
              handleLeftButton={startSettingPreviusQuestionInReviewCustomExamMQ}
              handleRightButton={startSettingNextQuestionInReviewCustomExamMQ}
              handleShowAnswer={startShowingCustomExamMQ}
              handleOnClickNasta={startNextQuestionCustomExamMQ}
            />
          )} */}
        </>
      }
    />
  );
};

export default CustomExamsMQ;
