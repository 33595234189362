/** Libraries */
import React, { useEffect, useMemo, useState } from "react";
import { Box, Radio } from "@material-ui/core";

/** Atoms */
import {
  StyledCorrectIcon,
  StyledRadiusCheckedIcon,
  StyledRadiusUnCheckedIcon,
  StyledRadiusOnHoverIcon,
  StyledWrongIcon,
} from "../../../../../atom/RadioIcons/RadioIcons";
import { StyledExamButton } from "../../../../../atom/Button/Button.styles";
import { PressEnterIcon } from "../../../../../atom/PressEnter/PressEnter";

/** Molecules */
import OptionsSQ from "../../../../../molecule/OptionsComponents/OptionsSQ/OptionsSQ";
import useWindowDimensions from "../../../../../molecule/WindowDimensions/dimension";

/** Organisms */
import { SQDescription } from "../../../../Quiz/descriptions/SQDescription/SQDescription";

/** Templates */
import { SQTemplate } from "../../../../../template";

/** Assets */
import { NavigationButtonText } from "./ExamBodySQ.styles";

const ExamBodySQ = (props) => {
  const [onHover, setOnhover] = useState();
  const { width } = useWindowDimensions();

  const sectionTitle = useMemo(
    () => props?.currentQuestion?.sectionCategories?.title,
    [props?.currentQuestion]
  );

  useEffect(() => {
    const handleEnterClick = (e) => {
      e.preventDefault();
      if (e.keyCode === 13) {
        props?.currentIndex + 1 < props?.quiz.question.length &&
          props?.setCurrentIndex(props?.currentIndex + 1);
      }
    };
    document.addEventListener("keydown", handleEnterClick);

    return () => {
      document.removeEventListener("keydown", handleEnterClick);
    };
  }, [props?.quiz, props?.currentIndex]);

  /** Returns the options radius buttons for SQ*/
  const showOptionsNewStyles = (question, curentOption, optionIndex) => {
    if (question.answer && question.answer.option === curentOption._id) {
      return <Radio disabled checked={false} icon={<StyledCorrectIcon />} />;
    } else if (question.answer && curentOption._id === question?.optionId) {
      return <Radio disabled checked={false} icon={<StyledWrongIcon />} />;
    } else if (question.answer && curentOption._id !== question?.optionId) {
      return (
        <Radio disabled checked={false} icon={<StyledRadiusUnCheckedIcon />} />
      );
    }

    if (
      // optionIndex === question.selectedIndex ||
      optionIndex === question.selectedOptionIndex
    ) {
      return (
        <Radio
          color="primary"
          checked={true}
          icon={<StyledRadiusUnCheckedIcon />}
          checkedIcon={<StyledRadiusCheckedIcon />}
        />
      );
    } else {
      return (
        <Radio
          color="primary"
          checked={false}
          icon={
            onHover && curentOption._id === onHover ? (
              <StyledRadiusOnHoverIcon />
            ) : (
              <StyledRadiusUnCheckedIcon />
            )
          }
        />
      );
    }
  };

  return (
    <SQTemplate
      description={
        <SQDescription
          doesSparaButtonExists={true}
          isQuestionFlagged={props?.quiz.question[props?.currentIndex].isFlaged}
          flagQuestion={props?.flagQuestion}
          sparaSaveButtonRef={props?.saveButtonRef}
          sectionTitle={sectionTitle}
          questionDescription={props?.currentQuestion?.questionStatement}
          questionIndications={[
            props?.currentQuestion?.information1,
            props?.currentQuestion?.information2,
          ]}
          questionImage={props?.currentQuestion?.questionStatement?.includes(
            "hp-appen.s3.eu-north-1.amazonaws.com"
          )}
        />
      }
      options={
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <OptionsSQ
            questionTypeTitle={props?.currentQuestion.sectionCategories.title}
            onHover={onHover}
            onHoverLeave={() => setOnhover(null)}
            onhover={(optionId) => setOnhover(optionId)}
            question={props?.questionNewStructure}
            SelectOption={(item, index) => props?.SelectFunc(item._id, index)}
            showOptions={showOptionsNewStyles}
          />
        </Box>
      }
      primaryButton={
        <StyledExamButton
          mq
          disabled={props?.currentIndex === 0}
          onClick={() =>
            props?.currentIndex > 0 &&
            props?.setCurrentIndex(props?.currentIndex - 1)
          }
        >
          Föregående
        </StyledExamButton>
      }
      secondaryButton={
        props?.isLastQuestion ? (
          <StyledExamButton onClick={props?.handleLamnaIn}>
            <NavigationButtonText>Lämna in</NavigationButtonText>
          </StyledExamButton>
        ) : (
          <Box
            style={{
              display: "flex",
              alignItems: "flex-end",
              flexDirection: "column",
            }}
          >
            <StyledExamButton
              onClick={() => props?.setCurrentIndex(props?.currentIndex + 1)}
            >
              <NavigationButtonText>Nästa</NavigationButtonText>
            </StyledExamButton>
            <PressEnterIcon />
          </Box>
        )
      }
    ></SQTemplate>
  );
};

export default ExamBodySQ;
