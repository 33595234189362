/** Libraries */
import React from "react";
import PropTypes from "prop-types";

/** Styles */
import {
  MQTemplateContainer,
  TopbarContainer,
  BannerContainer,
  BodyContainer,
  DialogContainer,
  QuestionsContainer,
} from "./MQTemplate.styles";

const CEMQTemplate = ({
  topBar,
  banner,
  nonAnsweredAlert,
  dialogBox,
  questions,
  secondaryButton,
  primaryButton,
  submitPopup,
  quitPopup,
  loader,
  overViewPopup,
  timeOverPopup,
  ...props
}) => {
  return (
    <MQTemplateContainer {...props}>
      {loader && loader}
      {submitPopup && submitPopup}
      {timeOverPopup && timeOverPopup}
      <TopbarContainer>{topBar}</TopbarContainer>
      {quitPopup && quitPopup}
      {banner && <BannerContainer>{banner}</BannerContainer>}
      {overViewPopup && overViewPopup}
      <BodyContainer>
        <DialogContainer nonAnsweredQuestion={!!nonAnsweredAlert}>
          {nonAnsweredAlert && nonAnsweredAlert}
          {dialogBox}
        </DialogContainer>
        <QuestionsContainer>{questions}</QuestionsContainer>
      </BodyContainer>
    </MQTemplateContainer>
  );
};

CEMQTemplate.propTypes = {
  topBar: PropTypes.element.isRequired,
  banner: PropTypes.element,
  nonAnsweredAlert: PropTypes.element,
  dialogBox: PropTypes.element.isRequired,
  questions: PropTypes.element.isRequired,
};

export default CEMQTemplate;
