/** Libraries */
import { createSlice } from "@reduxjs/toolkit";

export const uiSlice = createSlice({
  name: "ui",
  initialState: {
    progressBackdrop: false,
    examsProgressBackdrop: false,
    feedbackPopup: false,
    commonPopup: {
      open: false,
      title: "",
      description: "",
      cancelBtnName: "",
      agreeBtnName: "",
      redirect: () => {},
    },
    errorPopup: false,
    errorRedirection: false,
    guaranteePopup: false,
    cuExamPopup: {
      status: false,
      index: null,
    },
    cuExamSessionPopup: {
      status: false,
      index: null,
    },
  },
  reducers: {
    /** Guarantee popup */
    uiSetGuaranteePopup: (state) => {
      state.guaranteePopup = !state.guaranteePopup;
    },

    /** Error popup */
    uiSetErrorPopup: (state) => {
      state.errorPopup = !state.errorPopup;
    },

    uiSetErrorRedirectionTrue: (state) => {
      state.errorRedirection = true;
    },

    uiSetErrorRedirectionFalse: (state) => {
      state.errorRedirection = false;
    },

    /** Feedback popup */
    uiFeedbackPopupOpen: (state) => {
      state.feedbackPopup = true;
    },
    uiFeedbackPopupClose: (state) => {
      state.feedbackPopup = false;
    },
    /** Loader with progress backdrop */
    uiOpenProgressBackdrop: (state) => {
      state.progressBackdrop = true;
    },
    uiCloseProgressBackdrop: (state) => {
      state.progressBackdrop = false;
    },
    /** Exams loader with progress backdrop */
    uiOpenExamsProgressBackdrop: (state) => {
      state.examsProgressBackdrop = true;
    },
    uiCloseExamsProgressBackdrop: (state) => {
      state.examsProgressBackdrop = false;
    },
    /** Common popup */
    uiCloseCommonPopup: (state) => {
      state.commonPopup = {
        open: false,
        title: "",
        description: "",
        cancelBtnName: "",
        agreeBtnName: "",
        redirect: () => {},
      };
    },
    uiOpenCommonPopup: (state, action) => {
      state.commonPopup = {
        open: true,
        title: action.payload?.title || "",
        description: action.payload?.description || "",
        cancelBtnName: action.payload?.cancelBtnName || "",
        agreeBtnName: action.payload?.agreeBtnName || "",
        redirect:
          action.payload?.redirect ||
          (() => {
            console.log("Redirect function not provided.");
          }),
      };
    },
    uiLogout: (state, action) => {
      state.progressBackdrop = false;
    },
    setcuExamPopup: (state, action) => {
      state.cuExamPopup.index = action.payload?.index;
      state.cuExamPopup.status = action.payload?.status;
    },
    setcuExamSessionPopup: (state, action) => {
      state.cuExamSessionPopup.index = action.payload?.index;
      state.cuExamSessionPopup.status = action.payload?.status;
    },
  },
});

export const {
  uiLogout,
  uiOpenProgressBackdrop,
  uiCloseProgressBackdrop,
  uiOpenExamsProgressBackdrop,
  uiCloseExamsProgressBackdrop,
  uiFeedbackPopupOpen,
  uiFeedbackPopupClose,
  uiOpenCommonPopup,
  uiCloseCommonPopup,
  uiSetErrorPopup,
  uiSetErrorRedirectionTrue,
  uiSetErrorRedirectionFalse,
  uiSetGuaranteePopup,
  setcuExamPopup,
  setcuExamSessionPopup,
} = uiSlice.actions;
