/** Libraries */
import React from "react";
import PropTypes from "prop-types";

/** Styles */
import {
  TopbarContainer,
  BannerContainer,
  BodyContainer,
  ButtonContainer,
  NavigationButtonsContainer,
  SQTemplateContainer,
} from "./SQTemplate.styles";

/**
 * @docs https://www.notion.so/hpappen/Atomic-design-c511ecc580614e919d052ecee7b6a8e1?pvs=4#081940fe494a4ad99384e201350346ad
 */
const SQTemplate = ({
  submitPopup,
  topBar,
  banner,
  nonAnsweredAlert,
  description,
  options,
  primaryButton,
  secondaryButton,
  answer,
  quitPopup,
  loader,
  overViewPopup,
  timeOverPopup,
  ...props
}) => {
  return (
    <SQTemplateContainer {...props}>
      {loader && loader}
      {submitPopup && submitPopup}
      {timeOverPopup && timeOverPopup}
      <TopbarContainer>{topBar}</TopbarContainer>
      {quitPopup && quitPopup}
      {banner && <BannerContainer>{banner}</BannerContainer>}
      {overViewPopup && overViewPopup}
      <BodyContainer>
        {nonAnsweredAlert && nonAnsweredAlert}
        {description}
        {options}
        {answer}
        <NavigationButtonsContainer>
          <ButtonContainer secondarybuttonexist={!!secondaryButton}>
            {primaryButton}
          </ButtonContainer>
          {!!secondaryButton && (
            <ButtonContainer secondarybuttonexist={!!secondaryButton}>
              {secondaryButton}
            </ButtonContainer>
          )}
        </NavigationButtonsContainer>
      </BodyContainer>
    </SQTemplateContainer>
  );
};

SQTemplate.propTypes = {
  topBar: PropTypes.element.isRequired,
  banner: PropTypes.element,
  nonAnsweredAlert: PropTypes.element,
  description: PropTypes.element.isRequired,
  options: PropTypes.element.isRequired,
  primaryButton: PropTypes.element.isRequired,
  secondaryButton: PropTypes.element,
  answer: PropTypes.element,
};

export default SQTemplate;
