/** Libraries */
import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { styled, useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { Button, Dialog } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";

/** Assets */
import UnAttempted from "../../../../../assets/Imgs/UnAttemptedCircle.png";

/** Tools */
import { SendSentryError } from "../../../../../tools";

/** Axios instances */
import { EndPoints, instance2 } from "../../../../service/Route";
import { useUiStore } from "../../../../../hooks";

/** Atoms */
import { StyledCloseIconRight } from "../../../../atom/CustomIcons/StyledCloseIcon";
import {
  StyledCorrectIcon,
  StyledWrongIcon,
} from "../../../../atom/RadioIcons/RadioIcons";

/** Material UI - Custom elements */
const MainContainer = styled(Box)(({ theme }) => ({
  padding: "3rem",
  background: theme.palette.appcolors.secondaryColor2,
  display: "flex",
  justifyContent: "flex-start",
  flexDirection: "column",
  [theme.breakpoints.down(1280)]: {
    alignItems: "flex-start",
    padding: "2rem",
    paddingTop: "3rem",
  },
  [theme.breakpoints.down(600)]: {
    padding: "1rem",
    paddingTop: "2rem",
  },
}));

const HeaderTitle = styled("h3")(({ theme }) => ({
  fontSize: "28px",
  marginBottom: "1rem",
  [theme.breakpoints.down("md")]: {
    fontSize: "20px",
    marginBottom: "0rem",
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: "1rem",
  },
}));

const InfoText = styled("p")(({ theme }) => ({
  fontFamily: "Figtree",
  fontSize: "14px",
  [theme.breakpoints.down("sm")]: {
    paddingBottom: "0.5rem",
    fontSize: "13px",
  },
}));

const BodyContainer = styled(Grid)(({ theme }) => ({
  marginTop: "1rem",
  paddingBottom: "1rem",
  maxHeight: "1570px",
  overflowY: "hidden",
}));

const CardContainer = styled(Box)(({ theme }) => ({
  width: "99%",
  border: `1px solid ${theme.palette.appcolors.grey3}`,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "row",
  height: "60px",
  borderRadius: "5px",
  padding: "1rem",
  cursor: "pointer",
  ":hover": {
    transform: "scale(1.01)",
    transition: "all 0.3s ease-in-out",
  },
}));

const CardContent = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
}));

const CardCorrectionIcon = styled("div")(({ theme }) => ({
  height: "1.5rem",
  marginRight: "0.75rem",
  "& img": {
    height: "1.5rem",
  },
}));

const CardArrowIcon = styled(ArrowForwardIosRoundedIcon)(({ theme }) => ({
  fontSize: "20px",
}));

const CardText = styled("p")(({ theme }) => ({
  fontSize: "0.75rem",
  fontWeight: "500",
  display: "flex",
}));

const CustomDialog = styled(Dialog)(({ theme }) => ({
  zIndex: 1401,
  margin: "auto",
  maxWidth: "845px",
}));

const RattedOverblick = (props) => {
  const navigate = useNavigate();
  const { startErrorPopup, startErrorRedirectionTrue } = useUiStore();
  const [result, setResult] = useState(null);
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (!props?.open) {
      setModalOpen(false);
    }
  }, [props?.open]);

  const dispSecondsAsMins = (seconds) => {
    // 25:00
    const mins = Math.floor(seconds / 60);
    const seconds_ = Math.floor(seconds % 60);
    return (
      (mins.toString().length === 1 ? "0" + mins.toString() : mins.toString()) +
      ":" +
      (seconds_ == 0
        ? "00"
        : seconds_.toString().length == 1
        ? "0" + seconds_.toString()
        : seconds_.toString())
    );
  };

  const handleClose = (e) => {
    props?.handleClose();
  };

  useEffect(() => {
    if (props.quizId && props.open) {
      setOpen(true);
      const URL = EndPoints.getSimuleraQuizResult + props?.quizId;
      instance2
        .get(URL)
        .then((response) => {
          console.log("response2", response);
          setOpen(false);
          setModalOpen(true);
          setResult(response.data);
        })
        .catch((error) => {
          console.error("Error getting simulera quiz result:", error);
          SendSentryError(
            "SIMULERA-QUIZ",
            "Error getting simulera quiz result",
            {
              details: "Failed to getting simulera quiz result",
              error,
            }
          );
          setModalOpen(false);
          startErrorPopup();
          startErrorRedirectionTrue();
        });
    }

    return () => {
      setResult(null);
    };
  }, [props?.quizId, props?.open]);

  const RenderResultIcon = (item) => {
    if (item.optionId === null) {
      return (
        <CardCorrectionIcon>
          <img src={UnAttempted} />
        </CardCorrectionIcon>
      );
    } else if (item.questionAnswer.option === item.optionId) {
      return (
        <CardCorrectionIcon>
          <StyledCorrectIcon />
        </CardCorrectionIcon>
      );
    } else {
      return (
        <CardCorrectionIcon>
          <StyledWrongIcon />
        </CardCorrectionIcon>
      );
    }
  };

  const tillProvpassHandler = (index) => {
    if (props?.isCuExResult) {
      navigate("/simuleraprov", {
        state: {
          quiz: result?.simuleraQuiz,
          questionIndex: index,
          seasonId: props?.seasonId,
          quizId: props?.quizId,
          examResultData: props?.examResultData,
          examNumber: props?.examResultData?.examNumber,
          isCustomExam: true,
          isReview: true,
          provpassOrder: [
            "2024-VT-TEST1",
            "2024-VT-TEST2",
            "2024-VT-TEST3",
            "2024-VT-TEST4",
          ],
        },
      });
    } else {
      navigate("/simuleraprov", {
        state: {
          quiz: result?.simuleraQuiz,
          questionIndex: index,
          seasonId: props?.seasonId,
          quizId: props?.quizId,
          examResultData: props?.examResultData,
          provpassOrder: props?.provpassOrder,
          provpass: props?.provpass,
          isCustomExam: false,
        },
      });
    }
  };

  return (
    <>
      <Box>
        <Backdrop
          sx={{
            color: theme.palette.appcolors.popupOverlay,
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={open}
        >
          <CircularProgress color="inherit" size="5rem" />
        </Backdrop>
      </Box>

      {modalOpen && (
        <CustomDialog
          open={modalOpen}
          onClose={handleClose}
          slotProps={{
            backdrop: {
              style: {
                backgroundColor: theme.palette.appcolors.popupOverlay,
                backdropFilter: "blur(2px)",
              },
            },
          }}
          PaperProps={{
            sx: {
              borderRadius: "5px",
              maxWidth: "845px !important",
              maxHeight: "90vh",
              margin: "auto",
              width: "100%",
              scrollbarWidth: "thin",
              scrollbarColor: `${theme.palette.appcolors.grey3} ${theme.palette.appcolors.secondaryColor2}`,
            },
          }}
        >
          <StyledCloseIconRight onClick={() => handleClose()} />
          <MainContainer>
            <HeaderTitle>
              Rättningsöversikt för Provpass{" "}
              {props.isReview
                ? props?.examResultData?.examNumber
                : props?.provpassNumber}{" "}
              | {props?.provpassType} del
            </HeaderTitle>
            <InfoText>
              <b>Tid:</b> Tiden som du spenderade på att besvara frågan.
            </InfoText>

            <BodyContainer container spacing={2}>
              {result &&
                result.simuleraQuiz.question.map((item, index) => {
                  return (
                    <Grid item md={6} sm={6} xs={12} key={item._id}>
                      <CardContainer onClick={() => tillProvpassHandler(index)}>
                        <CardContent>
                          {RenderResultIcon(item)}
                          <CardText>Uppgift {index + 1}</CardText>
                          <CardText>
                            Tid: {dispSecondsAsMins(item.spendtime)}
                          </CardText>
                          <CardArrowIcon />
                        </CardContent>
                      </CardContainer>
                    </Grid>
                  );
                })}
            </BodyContainer>
            <Button
              variant="contained"
              sx={{
                width: "100%",
                background: theme.palette.appcolors.primaryColor,
                color: theme.palette.appcolors.secondaryColor10,
                marginTop: "2rem",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: theme.palette.appcolors.primaryColor,
                  transform: "scale(1.01)",
                  transition: "all 0.3s ease-in-out",
                },
              }}
              onClick={() => handleClose()}
            >
              Tillbaka till provresultat
            </Button>
          </MainContainer>
        </CustomDialog>
      )}
    </>
  );
};

export default RattedOverblick;
