/** Libraries */
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

/** Material UI - Custom elements */
export const SQTemplateContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.appcolors.secondaryColor3,
  paddingBottom: "5rem",
  paddingTop: "2rem",
}));

export const TopbarContainer = styled("div")(({ theme }) => ({
  position: "sticky",
  top: 0,
  left: 0,
  width: "100%",
  height: "fit-content",
  zIndex: 1000,
}));

export const BannerContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "fit-content",
}));

export const BodyContainer = styled("div")(({ theme }) => ({
  display: "flex",
  gap: "18px",
  height: "auto",
  justifyContent: "center",
  flexDirection: "column",
  width: "700px",
  [theme.breakpoints.down(900)]: {
    width: "95%",
    fontFamily: "'Noto Sans', sans-serif",
    height: "fit-content",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
}));

export const NavigationButtonsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "auto",
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "space-between",
  gap: "10px",
  [theme.breakpoints.down(415)]: {
    justifyContent: "center",
  },
}));

export const NavigationButtonsContainerMQ = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "auto",
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "space-between",
  gap: "10px",
  marginTop: "15px",
  [theme.breakpoints.down(415)]: {
    justifyContent: "center",
  },
}));

export const ButtonContainer = styled("div")(
  ({ theme, secondarybuttonexist }) => ({
    width: secondarybuttonexist ? "50%" : "100%",
  })
);
