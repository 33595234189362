/** Libraries */
import { styled } from "@mui/material/styles";

/** Hooks */
import { useMixpanel } from "../../../hooks";

import { Button } from "@mui/material";

const StyledPayButton = styled(Button)(({ theme, disabled }) => ({
  color: `${theme.palette.appcolors.secondaryColor10} !important`,
  backgroundColor: `${
    disabled
      ? theme.palette.appcolors.grey3
      : theme.palette.appcolors.primaryColor
  } !important`,
  borderBottom: `3px solid ${
    disabled ? theme.palette.appcolors.grey3 : theme.palette.appcolors.black3
  } !important`,
  borderRadius: "5px !important",
  padding: "8px 16px !important",
  minWidth: "200px !important",
  transition: "all 0s ease-in-out",
  fontSize: "18px !important",
  fontWeight: "600 !important",
  textTransform: "none !important",
  "&:hover": {
    backgroundColor: disabled
      ? theme.palette.appcolors.grey3
      : theme.palette.appcolors.primaryColor,
    transform: "scale(1.05)",
    transition: "all 0.3s ease-in-out",
  },
  [theme.breakpoints.down(1280)]: {
    width: "90% !important",
  },
}));

const PayButton = ({
  goPayment,
  disablePaymentButton,
  planDetails,
  sectionToBuy,
  paymentButtonRef,
  setSelectedPlan,
}) => {
  const mixpanel = useMixpanel();

  const handleGoPayment = async () => {
    if (!disablePaymentButton) {
      const plan = await planDetails();
      setSelectedPlan(plan?.id);
      goPayment(plan, sectionToBuy);
      mixpanel.buttonClicked("Upgrade");
    }
  };
  return (
    <StyledPayButton
      ref={paymentButtonRef}
      disabled={disablePaymentButton}
      onClick={handleGoPayment}
    >
      Uppgradera
    </StyledPayButton>
  );
};

export default PayButton;
