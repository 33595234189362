/** Libraries */
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Typography, Box, Radio, Fade } from "@material-ui/core";
import { DialogTitle } from "@mui/material";
import { styled } from "@mui/material/styles";

/** Atoms */
import RulerButton from "../../../../../atom/RulerButton/RulerButton";
import { StyledQuestionInfo } from "../../../../../atom/SharedExamExerciseStyledComponents/MQDoubleColumnText";
import { StyledQuestionContainer } from "../../../../../atom/SharedExamExerciseStyledComponents/SharedExamExerciseStyledComponents";
import { StyledExamButton } from "../../../../../atom/Button/Button.styles";
import { PressEnterIcon } from "../../../../../atom/PressEnter/PressEnter";
import { StyledContainer } from "../../../../HomeOrg/HomePages/QuestionPages/QuestionViewDtkOrg/SharedDtkOrg.styles";

/** Molecules */
import QuestionStatement from "../../../../../molecule/QuestionStatement/QuestionStatement";
import useWindowDimensions from "../../../../../molecule/WindowDimensions/dimension";
import OptionsMQ from "../../../../../molecule/OptionsComponents/OptionsMQ/OptionsMQ";

/** Utils */
import { appColors } from "../../../../../../utils/commonService";

/** Assets */
import Correct from "../../../../../../assets/Imgs/correct.png";
import Wrong from "../../../../../../assets/Imgs/wrong.png";
import {
  StyledRadiusCheckedIcon,
  StyledRadiusOnHoverIcon,
  StyledRadiusUnCheckedIcon,
} from "../../../../../atom/RadioIcons/RadioIcons";
import { SparaButton } from "../../../../../molecule/Buttons/SparaButton/SparaButton";

/** MUI styled elements */
const DescriptionContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  flexDirection: "column",
  width: "100%",
  maxWidth: "900px",
  [theme.breakpoints.down(1200)]: {
    maxWidth: "100%",
  },
}));

const DescriptionQuestionContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "flex-start",
  alignSelf: "stretch",
  padding: "44px 16px 22px 25px",
  gap: "10px",

  [theme.breakpoints.down(1200)]: {
    minHeight: "104px",
  },
  [theme.breakpoints.down(600)]: {
    padding: "15px",
    paddingTop: "44px",
    gap: "0px",
    minHeight: "119px",
  },
}));

const ImageRadiusIcon = styled("img")(() => ({
  width: "28px",
  height: "28px",
}));

const NavigationButtonsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "10px",
}));

const NavigationButtonText = styled("p")(({ theme }) => ({
  textAlign: "center",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
}));

const ExamBodyMQ = (props) => {
  const navigate = useNavigate();
  const params = useLocation();
  const [onHover, setOnhover] = useState();
  const { width } = useWindowDimensions();

  const options = useMemo(
    () => props?.currentQuestion?.options.options,
    [props?.currentQuestion]
  );

  const isELFgaps = ["gap 31", "gap 32", "gap 33", "gap 34", "gap 35"].some(
    (substring) => props?.currentQuestion?.questionStatement.includes(substring)
  );
  const sectionTitle = useMemo(
    () => props?.currentQuestion?.multipartQuestion.title,
    [props?.currentQuestion]
  );

  const description = useMemo(
    () => props?.currentQuestion?.multipartQuestion.description,
    [props?.currentQuestion]
  );

  const handleNavigateBack = (e) => {
    e.preventDefault();

    if (props?.currentIndex > 0) {
      props?.setCurrentIndex(props?.currentIndex - 1);
    }
  };

  useEffect(() => {
    const handleEnterClick = (e) => {
      e.preventDefault();
      if (e.keyCode === 13) {
        props?.currentIndex + 1 < props?.quiz.question.length &&
          props?.setCurrentIndex(props?.currentIndex + 1);
      }
    };
    document.addEventListener("keydown", handleEnterClick);

    return () => {
      document.removeEventListener("keydown", handleEnterClick);
    };
  }, [props?.quiz, props?.currentIndex]);

  // Options and showOptionsNewStyles functions are pretty similar, we should use just one of them
  const Options = (question, curentOption, optionIndex) => {
    if (question.answer && question.answer.option === curentOption._id) {
      return (
        <Radio
          disabled
          checked={false}
          icon={<ImageRadiusIcon src={Correct} alt="" />}
        />
      );
    } else if (question.answer && curentOption._id === question?.optionId) {
      return (
        <Radio
          disabled
          checked={false}
          icon={<ImageRadiusIcon src={Wrong} alt="" />}
        />
      );
    } else if (question.answer && curentOption._id !== question?.optionId) {
      return (
        <Radio disabled checked={false} icon={<StyledRadiusUnCheckedIcon />} />
      );
    }

    if (
      // optionIndex === question.selectedIndex ||
      optionIndex === question.selectedOptionIndex
    ) {
      return (
        <Radio
          checked={true}
          icon={<StyledRadiusUnCheckedIcon />}
          checkedIcon={<StyledRadiusCheckedIcon />}
        />
      );
    } else {
      return (
        <Radio
          checked={false}
          icon={
            onHover && curentOption._id === onHover ? (
              <StyledRadiusOnHoverIcon />
            ) : (
              <StyledRadiusUnCheckedIcon />
            )
          }
        />
      );
    }
  };

  return (
    <>
      <StyledContainer>
        {/** Description info section - START */}
        <Fade in={true} style={{ transitionDelay: "50ms" }}>
          <DescriptionContainer>
            <StyledQuestionInfo
              description={description}
              width={width}
              dtk={sectionTitle === "DTK"}
            >
              {description && (
                <QuestionStatement
                  key={description}
                  description={description}
                  title={sectionTitle !== "DTK" && sectionTitle}
                  width={width}
                  isMQ={true}
                />
              )}
              {props?.currentQuestion?.image && (
                <>
                  <DialogTitle
                    style={{
                      padding: "0 5rem 2rem",
                      flex: "1",
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    {props?.currentQuestion && (
                      <QuestionStatement
                        numberOfQuestions={quiz?.props?.currentQuestion.length}
                        title={quiz?.title === "DTK" && quiz?.title}
                        description={quiz?.description}
                      />
                    )}
                    <Box>
                      <RulerButton />
                    </Box>
                  </DialogTitle>
                  <div
                    style={{
                      padding: "0 5rem 2rem",
                      display: "flex",
                      border: `1px solid ${appColors.valentine_red}`,
                    }}
                  >
                    <Box style={{ width: "90%" }}>
                      <img src={quiz?.image} style={{ width: "100%" }} alt="" />
                    </Box>
                  </div>
                </>
              )}
            </StyledQuestionInfo>
          </DescriptionContainer>
        </Fade>
        {/** Description info section - END */}

        {/** Options section - START */}
        <StyledQuestionContainer>
          {props?.quiz &&
            !props?.quiz.question[props?.currentIndex].questionAnswer && (
              <SparaButton
                isExam={true}
                isQuestionFlagged={
                  props?.quiz.question[props?.currentIndex].isFlaged
                }
                sparaSaveButtonRef={props?.saveButtonRef}
                onClick={props?.flagQuestion}
              />
            )}
          <DescriptionQuestionContainer>
            <QuestionStatement
              description={props?.currentQuestion?.questionStatement}
              iself={isELFgaps}
              indications={[
                props?.currentQuestion?.information1,
                props?.currentQuestion?.information2,
              ]}
              type={sectionTitle}
              isMQQuestion={true}
            />
            {props?.currentQuestion.image && (
              <img
                src={props?.currentQuestion.image[0]}
                style={{ height: "10rem", marginBottom: ".4rem" }}
                alt=""
              />
            )}
          </DescriptionQuestionContainer>
          <OptionsMQ
            options={options}
            selectedopindex={props?.currentQuestion?.selectedOptionIndex}
            SelectFunc={(item, index) =>
              !props?.currentQuestion.answerSubmitted &&
              props?.SelectFunc(item, index)
            }
            Options={(item, index) =>
              Options(props?.currentQuestion, item, index)
            }
          />

          {!props?.open && (
            <NavigationButtonsContainer>
              <StyledExamButton
                mq
                disabled={props?.currentIndex === 0}
                onClick={handleNavigateBack}
              >
                <NavigationButtonText>Föregående</NavigationButtonText>
              </StyledExamButton>
              {props?.ifAnswerExist ? (
                <Box
                  onClick={() =>
                    navigate("/rattadoverblick", {
                      state: {
                        quizId: params?.state?.quizId,
                        seasonId: params?.state?.seasonId,
                        examResultData: params?.state?.examResultData,
                      },
                    })
                  }
                >
                  <Typography
                    variant="h6"
                    style={{
                      fontSize: "0.75rem",
                      textTransform: "uppercase",
                      cursor: "pointer",
                    }}
                  >
                    Rättad Överblick
                  </Typography>
                </Box>
              ) : (
                <Box
                  onClick={() => {
                    props?.setShouldNavigate(true);
                  }}
                >
                  {/* <div>Do not delete this part!!!</div> */}
                  {/* <Typography
                      variant="h6"
                      style={{
                        fontSize: "0.75rem",
                        textTransform: "uppercase",
                        cursor: "pointer",
                      }}
                    >
                      {props?.currentIndex + 1 === props?.quiz?.question.length
                        ? ""
                        : "överblick"}
                    </Typography> */}
                </Box>
              )}

              {props?.isLastQuestion ? (
                <StyledExamButton mq onClick={props?.handleLamnaIn}>
                  <NavigationButtonText>Lämna in</NavigationButtonText>
                </StyledExamButton>
              ) : (
                <Box
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <StyledExamButton
                    onClick={() =>
                      props?.setCurrentIndex(props?.currentIndex + 1)
                    }
                  >
                    <NavigationButtonText>Nästa</NavigationButtonText>
                  </StyledExamButton>
                  <PressEnterIcon />
                </Box>
              )}
            </NavigationButtonsContainer>
          )}
        </StyledQuestionContainer>
        {/** Options section - END */}
      </StyledContainer>
    </>
  );
};

export default ExamBodyMQ;
