import { Box, Tabs } from "@material-ui/core";
import { styled } from "@mui/material/styles";

export const MainContainer = styled("div")(({ theme }) => ({
  height: "100%",
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(6),
  display: "flex",
  flexDirection: "row",
  alignContent: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.appcolors.secondaryColor,
  [theme.breakpoints.down(1280)]: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    alignContent: "left",
    justifyContent: "left",
  },
  [theme.breakpoints.down(600)]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

export const HeaderContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  maxWidth: "1200px",
  [theme.breakpoints.down(1280)]: {
    flexDirection: "row",
  },
  [theme.breakpoints.down(800)]: {
    flexDirection: "column",
  },
}));

export const MiddleContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.down(2400)]: {
    maxWidth: "1000px",
    width: "100%",
  },
  [theme.breakpoints.up(2400)]: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    alignItems: "start",
  },
  [theme.breakpoints.up(1280)]: {
    paddingRight: theme.spacing(3),
  },
}));

export const WelcomeMessageBox = styled("div")(({ theme, anpassatprov }) => ({
  minHeight: "175px",
  width: !anpassatprov && "100%", //When AnpassatProv is implemented, delete prop!
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  margin: "0rem 0.5rem 1rem 0.5rem",
  [theme.breakpoints.down(2400)]: {
    maxWidth: "680px",
  },
  [theme.breakpoints.down(1280)]: {
    width: "55%",
    margin: "0rem 1rem 1rem 0rem",
  },
  [theme.breakpoints.down(800)]: {
    width: "100%",
    maxWidth: "680px",
  },
  [theme.breakpoints.down("sm")]: {
    minHeight: "132px",
  },
}));

export const WhiteBoxContainer = styled("div")(({ theme, prognos }) => ({
  display: "grid",
  gridTemplateRows: "repeat(2, 1fr)",
  gridTemplateColumns: "repeat(2, 1fr)",
  gap: "10px",
  columnGap: "40px",
  rowGap: "20px",
  padding: "10px",
  height: "100%",
  [theme.breakpoints.down(2400)]: {
    columnGap: "17px",
    marginBottom: "17px",
  },
  [theme.breakpoints.down(1400)]: {
    gridTemplateRows: "repeat(1, 1fr)",
    gridTemplateColumns: "repeat(1, 1fr)",
  },
  [theme.breakpoints.down(1280)]: {
    gridTemplateRows: "repeat(2, 1fr)",
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  [theme.breakpoints.down(800)]: {
    gridTemplateRows: "auto",
    gridTemplateColumns: "auto",
  },
  [theme.breakpoints.up(2400)]: {
    gridTemplateRows: "repeat(3, auto)",
    gridTemplateColumns: "repeat(3, 1fr)",
  },
}));

export const WhiteBox = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.appcolors.secondaryColor2,
  display: "flex",
  flexDirection: "column",
  alignContent: "center",
  alignItems: "center",
  padding: "10px",
  borderRadius: "20px",
  border: `1px solid ${theme.palette.appcolors.grey5}`,
  height: "100%",
}));

export const MainCardContainer = styled("div")(({ theme }) => ({
  position: "relative",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  border: `1px solid ${theme.palette.appcolors.grey4}`,
  borderBottom: `6px solid ${theme.palette.appcolors.grey4}`,
  borderRadius: "10px",
  background: theme.palette.appcolors.secondaryColor4,
  overflow: "hidden",
  "&:hover": {
    cursor: "pointer",
    borderBottom: `6px solid ${theme.palette.appcolors.primaryColor6}`,
    transform: "scale(1.03)",
    transition: "all 0.3s ease",
  },
  [theme.breakpoints.up(2400)]: {
    minWidth: "350px",
    maxWidth: "600px",
    minHeight: "300px",
  },
}));

export const TitleOfTheExeciseBox = styled("h1")(({ theme }) => ({
  color: theme.palette.appcolors.black,
  fontSize: "22px",
  fontWeight: "bold",
  padding: "10px 0px 0px 20px",
}));

export const TitleOfTheExeciseBox2 = styled("h1")(({ theme }) => ({
  color: theme.palette.appcolors.black,
  fontSize: "23px",
  fontWeight: "600",
  marginTop: "1rem",
  marginBottom: "5px",
  padding: "10px 0px 0px 10px",
}));

export const RightBarDisplay = styled("div")(({ theme }) => ({
  [theme.breakpoints.down(1280)]: {
    display: "none",
  },
}));

export const PremiumCardContainer = styled("div")(({ theme }) => ({
  width: "65%",
  [theme.breakpoints.down(800)]: {
    display: "none",
  },
  [theme.breakpoints.up(1280)]: {
    display: "none",
  },
}));

export const MobileScreenDisplay = styled("div")(({ theme }) => ({
  [theme.breakpoints.up(600)]: {
    display: "none",
  },
}));

export const TabletScreenDisplay = styled("div")(({ theme }) => ({
  display: "initial",
  [theme.breakpoints.up(1280)]: {
    display: "none",
  },
  [theme.breakpoints.down(600)]: {
    display: "none",
  },
}));

export const TabletScreenDisplay2 = styled("div")(({ theme }) => ({
  display: "initial",
  [theme.breakpoints.up(1280)]: {
    display: "none",
  },
  [theme.breakpoints.down(800)]: {
    display: "none",
  },
}));

export const MobilePremiumCardContainer = styled("div")(({ theme }) => ({
  marginBottom: "5rem",
  padding: "0rem 0.625rem 0rem 0.625rem",
  maxWidth: "610px",
  [theme.breakpoints.up(800)]: {
    display: "none",
  },
}));

export const MobileDashboardBannerContainer = styled("div")(({ theme }) => ({
  marginBottom: "41px",
  marginTop: "24px",
  padding: "0rem 0.625rem 0rem 0.625rem",
  maxWidth: "610px",
  [theme.breakpoints.up(800)]: {
    display: "none",
  },
}));

export const TitleOfHeader = styled("h2")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  fontSize: "28px",
  fontWeight: "600",
  marginTop: "auto",
  paddingBottom: 0,
  paddingLeft: "8px",
  color: theme.palette.appcolors.error6,
  [theme.breakpoints.down(600)]: {
    fontSize: "20px",
  },
}));

export const SubtitleOfHeader = styled("h3")(({ theme }) => ({
  fontSize: "16px",
  padding: "8px",
  fontWeight: "400",
  marginBottom: "auto",
  color: theme.palette.appcolors.black,
  [theme.breakpoints.down(600)]: {
    fontSize: "12px",
  },
}));

export const WideScreenDisplay = styled("div")(({ theme }) => ({
  [theme.breakpoints.up(4200)]: {
    gap: "5rem",
  },
  [theme.breakpoints.up(2400)]: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    width: "100%",
  },
  [theme.breakpoints.down(2400)]: {
    display: "none",
  },
}));

export const WideScreenDisplay2 = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "2rem",
  marginTop: "-3rem",
  marginBottom: "10rem",
  alignContent: "center",
  width: "100%",
  [theme.breakpoints.down(2400)]: {
    display: "none",
    flexDirection: "row",
  },
}));

export const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{
      children: <span className="MuiTabs-indicatorSpan" />,
    }}
  />
))(({ theme }) => ({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    height: "5px",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: "84%",
    width: "100%",
    backgroundColor: theme.palette.appcolors.primaryColor,
  },
}));

export const TabBox = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  maxWidth: "680px",
}));

export const StyledQuestionContainer = styled(Box)(({ theme, mt }) => ({
  position: "sticky",
  top: 114,
  right: 0,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "466px",
  minWidth: "466px",
  height: "min-content",
  flex: "1 0 0",
  marginBottom: "6rem",
  [theme.breakpoints.up(1400)]: {
    maxWidth: "466px",
  },
  [theme.breakpoints.down(1200)]: {
    maxWidth: "900px",
    width: "100%",
    marginTop: mt && "28px",
  },
  [theme.breakpoints.down(600)]: {
    minWidth: "100%",
  },
}));
