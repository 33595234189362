/** Libraries */
import { Box } from "@material-ui/core";
import { styled } from "@mui/material/styles";

/** Styles */

export const ImageOptionItem = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: "24px 12px",
  alignItems: "flex-start",
  gap: "32px",
  flex: "1 0 0",
  alignSelf: "stretch",
  borderRadius: "5px",
  background: theme.palette.appcolors.secondaryColor4,
  maxWidth: "50%",
  [theme.breakpoints.down(600)]: {
    maxWidth: "100%",
  },
}));

export const ImageContainer = styled(Box)(({ theme }) => ({
  margin: "auto",
  paddingLeft: "1rem",
  width: "305px",
  height: "128px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down(600)]: {
    flexShrink: 0,
    margin: "auto",
    "& img": {
      width: "144px !important",
    },
  },
}));
