/** Libraries */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";

/* Images */
import HeltProv from "../../../assets/Imgs/HeltProv.png";

/** Molecules */
import MainCard from "../MainCard/MainCard";

/** Organism */
import useWindowDimensions from "../WindowDimensions/dimension";
import { StyledCloseIconRight } from "../../atom/CustomIcons/StyledCloseIcon";

/** Slices */
import { setCustomExam, setCustomExamSessions } from "../../../redux/slices";

/** Material UI - Custom elements */
import {
  CustomProgressInnerStack,
  CustomProgressStack,
  PercentageText,
  StartExamDialog,
  StyledBannerBackgroundContainer,
  StyledChipCE,
} from "./CustomExamCard.styles";
import CuExTestInfo from "../../organism/CustomizedExam/CuExTestInfo/CuExTestInfo";
import CuExTestsInfo from "../../organism/CustomizedExam/CuExTestsInfo/CuExTestsInfo";
import { Transition } from "../../atom/SlideTransition/SlideTransition";
import { useAllCustomExamStore } from "../../../hooks/customExams/AllCustomExams/useAllCustomExamsStore";
import AppLoader from "../AppLoader";
import {
  setcuExamPopup,
  setcuExamSessionPopup,
  uiOpenProgressBackdrop,
  uiCloseProgressBackdrop,
  uiOpenExamsProgressBackdrop,
  uiCloseExamsProgressBackdrop,
} from "../../../redux/slices";
import { memo } from "react";

// eslint-disable-next-line react/display-name
const CustomExamCard = memo((props) => {
  const { paymentPopupSetter } = props;
  const {
    name,
    description,
    _id: customExamId,
    examSession,
    totalAttempted,
    sessionExams,
    isAllAttempted,
  } = props.customExamData;
  const { user } = useSelector((state) => state.user);
  const { isTimeExtended } = useSelector((state) => state.timer);
  const { cuExamPopup, cuExamSessionPopup, progressBackdrop } = useSelector(
    (state) => state.ui
  );
  const { customizedExamSessions } = useAllCustomExamStore();
  const isPremium = user.isPremium;
  const { width } = useWindowDimensions();
  const theme = useTheme();
  const dispatch = useDispatch();

  const percentage = () => {
    switch (totalAttempted) {
      case 1:
        return isAllAttempted ? 100 : 25;
      case 2:
        return 50;
      case 3:
        return 75;
      case 4:
        return 100;
      default:
        return 0;
    }
  };

  // #region CustomExam

  const customExamsData = async () => {
    if (!isPremium) {
      paymentPopupSetter(true);
      return;
    }
    dispatch(setCustomExam(props.customExamData));

    const allSubmitted = sessionExams.every((item) => item.isAttempted);
    const allUnAttempted = sessionExams.every((item) => !item.isAttempted);

    const shouldOpenProgressBackdrop =
      isAllAttempted || allSubmitted || allUnAttempted;

    if (shouldOpenProgressBackdrop) {
      if (isAllAttempted) {
        dispatch(uiOpenExamsProgressBackdrop());

        try {
          await customizedExamSessions(customExamId);
          dispatch(setcuExamPopup({ status: true, index: props?.index }));
        } finally {
          dispatch(uiCloseExamsProgressBackdrop());
        }
      } else {
        dispatch(setCustomExamSessions(sessionExams));
        dispatch(setcuExamPopup({ status: true, index: props?.index }));
      }
    } else {
      const popupType =
        sessionExams.length === 1 ? setcuExamPopup : setcuExamSessionPopup;
      dispatch(setCustomExamSessions(sessionExams));
      dispatch(popupType({ status: true, index: props?.index }));
    }
  };

  return (
    <>
      <AppLoader isLoading={progressBackdrop} />
      <StartExamDialog
        open={cuExamPopup.status && cuExamPopup.index === props?.index}
        onClose={() =>
          dispatch(setcuExamPopup({ status: false, index: props?.index }))
        }
        TransitionComponent={Transition}
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: theme.palette.appcolors.popupOverlay,
              backdropFilter: "blur(2px)",
            },
          },
        }}
        PaperProps={{
          sx: {
            borderRadius: "5px",
            maxWidth: "862px !important",
            maxHeight: "837px",
            margin: width < 600 ? "0px" : "32px",
            width: "100%",
            height: "100%",
            backgroundColor: "theme.palette.appcolors.secondaryColor2",
          },
        }}
        aria-modal="true"
        role="dialog"
      >
        <StyledCloseIconRight
          onClick={() =>
            dispatch(setcuExamPopup({ status: false, index: props?.index }))
          }
        />
        <CuExTestsInfo
          setOpenExamDialog={() =>
            dispatch(setcuExamPopup({ status: false, index: props?.index }))
          }
          setOpenExamSessionDialog={() =>
            dispatch(
              setcuExamSessionPopup({ status: true, index: props?.index })
            )
          }
          isTimeExtended={isTimeExtended}
        />
      </StartExamDialog>
      <StartExamDialog
        open={
          cuExamSessionPopup.status && cuExamSessionPopup.index === props?.index
        }
        onClose={() =>
          dispatch(
            setcuExamSessionPopup({ status: false, index: props?.index })
          )
        }
        TransitionComponent={Transition}
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: theme.palette.appcolors.popupOverlay,
              backdropFilter: "blur(2px)",
            },
          },
        }}
        PaperProps={{
          sx: {
            borderRadius: "5px",
            minWidth: width < 900 ? "0px" : "862px",
            maxHeight: "837px",
            margin: width < 600 ? "0px" : "32px",
            width: "100%",
            height: "100%",
          },
        }}
      >
        <StyledCloseIconRight
          onClick={() =>
            dispatch(
              setcuExamSessionPopup({ status: false, index: props?.index })
            )
          }
        />
        <CuExTestInfo
          setOpenExamSessionDialog={() =>
            dispatch(
              setcuExamSessionPopup({ status: false, index: props?.index })
            )
          }
          isTimeExtended={isTimeExtended}
        />
      </StartExamDialog>

      <MainCard
        /* If quiz is in progress show dual dialog, else start the quiz again */
        onClick={customExamsData}
        data-testid="exam-card"
        isPremiumSticker={!isPremium}
        text={name}
        cardSubtitleText={description}
        styledBannerBackground={
          <StyledBannerBackgroundContainer>
            {props.image || <img src={HeltProv} width={50} />}
          </StyledBannerBackgroundContainer>
        }
      >
        <CustomProgressStack direction="row" alignItems="center">
          <CustomProgressInnerStack>
            {" "}
            {examSession?.map((item, index) => {
              return (
                <StyledChipCE
                  key={item}
                  props={props}
                  index={index}
                  label={item}
                  size="small"
                />
              );
            })}
          </CustomProgressInnerStack>
          <PercentageText>{percentage()}%</PercentageText>
        </CustomProgressStack>
      </MainCard>
    </>
  );
});

export default CustomExamCard;
